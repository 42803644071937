import { Message } from '@grammyjs/types';
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  refEqual,
  Timestamp,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';

import { type TelegramChatDoc } from './TelegramChats';

export type TelegramMessageDoc = {
  _v: 1;
  createdAt: Timestamp;
  photo?: {
    fileId: string;
    fileSize?: number;
    fileUniqueId: string;
    height: number;
    storageUri: string;
    width: number;
  }[];
  sticker?: {
    fileId: string;
    fileSize?: number;
    fileUniqueId: string;
    height: number;
    storageUri: string;
    width: number;
  };
  telegramMessage: Message;
  updatedAt: Timestamp;
  voice?: {
    fileId: string;
    fileSize?: number;
    fileUniqueId: string;
    storageUri: string;
  };
};

export const isTelegramMessageDoc = (
  doc?: DocumentData,
): doc is TelegramMessageDoc => true;

export const isTelegramMessageRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TelegramMessageDoc> => ref.parent.id === 'telegramMessages';

function isTelegramMessageSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TelegramMessageDoc>;
function isTelegramMessageSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TelegramMessageDoc>;
function isTelegramMessageSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TelegramMessageDoc> | QueryDocumentSnapshot<TelegramMessageDoc> {
  return isTelegramMessageRef(snap.ref);
}

export { isTelegramMessageSnap };

export const getTelegramMessagesCollectionRef = (telegramChatRef: DocumentReference<TelegramChatDoc>) => collection(telegramChatRef, 'telegramMessages') as CollectionReference<TelegramMessageDoc>;

export const useTelegramMessagesCollectionRef = (
  telegramChatRef: DocumentReference<TelegramChatDoc>,
) => {
  const [nextTelegramChatRef, setNextTelegramChatRef] = useState(telegramChatRef);

  useEffect(
    () => {
      if (!refEqual(telegramChatRef, nextTelegramChatRef)) {
        setNextTelegramChatRef(telegramChatRef);
      }
    },
    [telegramChatRef, nextTelegramChatRef],
  );

  return useMemo(
    () => getTelegramMessagesCollectionRef(nextTelegramChatRef),
    [nextTelegramChatRef],
  );
};
