import { Center } from '@chakra-ui/react';
import { signInWithCustomToken } from 'firebase/auth';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../components/AuthProvider';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import useTelegramCreateCustomToken from '../../functions/useTelegramCreateCustomToken';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function TelegramLoginScreenMain() {
  const telegramCreateCustomToken = useTelegramCreateCustomToken();
  const auth = useAuth();
  const navigate = useNavigate();
  const showError = useShowError();

  useEffect(
    () => {
      if (Telegram.WebApp.initData === '') {
        navigate('/login');
        return;
      }

      if (auth.currentUser) {
        navigate('/');
        return;
      }

      telegramCreateCustomToken({ initData: Telegram.WebApp.initData })
        .then(({ data: token }) => signInWithCustomToken(auth, token))
        .then(() => fbq('track', 'Lead'))
        .then(() => navigate('/'))
        .catch(showError);
    },
    [
      auth,
      navigate,
      showError,
      telegramCreateCustomToken,
    ],
  );

  return (
    <Center h="100%">
      <LogoIcon boxSize={16} />
    </Center>
  );
}

export default function TelegramLoginScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <TelegramLoginScreenMain />
      </Suspense>
    </Catch>
  );
}
