import { Box } from '@chakra-ui/react';
import { Message } from '@grammyjs/types';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { maxBy } from 'lodash';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MessageBox } from 'react-chat-elements';
import { useNavigate } from 'react-router-dom';

import { TelegramMessageDoc } from '../../../common/collections/TelegramMessages';
import Catch from '../../../components/Catch';
import { useStorage } from '../../../components/StorageProvider';
import GroupChatCreatedMessageRow from './GroupChatCreatedMessageRow';
import MessageAutoDeleteTimerChangedMessageRow from './MessageAutoDeleteTimerChangedMessageRow';
import MigrateFromChatIdMessageRow from './MigrateFromChatIdMessageRow';
import MigrateToChatIdMessageRow from './MigrateToChatIdMessageRow';
import NewChatMembersMessageRow from './NewChatMembersMessageRow';
import WriteAccessAllowedMessageRow from './WriteAccessAllowedMessageRow';

export type Props = {
  telegramMessageSnap: QueryDocumentSnapshot<TelegramMessageDoc>;
};

export function MessageRowMain({ telegramMessageSnap }: Props) {
  const telegramMessageDoc = useMemo(() => telegramMessageSnap.data(), [telegramMessageSnap]);

  const navigate = useNavigate();
  const handleTitleClick = useCallback(
    () => {
      if (telegramMessageDoc.telegramMessage.from) {
        navigate(`telegramUsers/${telegramMessageDoc.telegramMessage.from?.id}`);
      }
    },
    [navigate, telegramMessageDoc.telegramMessage.from],
  );

  const storage = useStorage();
  const [photoUrl, setPhotoUrl] = useState<string>();
  useEffect(
    () => {
      if (telegramMessageDoc.photo) {
        const max = maxBy(telegramMessageDoc.photo, ({ height, width }) => height * width);

        if (max) {
          getDownloadURL(ref(storage, max.storageUri))
            .then(setPhotoUrl)
            .catch(() => { });
        }
      } else if (telegramMessageDoc.sticker) {
        getDownloadURL(ref(storage, telegramMessageDoc.sticker.storageUri))
          .then(setPhotoUrl)
          .catch(() => { });
      }
    },
    [storage, telegramMessageDoc.photo, telegramMessageDoc.sticker],
  );

  const [audioUrl, setAudioUrl] = useState<string>();
  useEffect(
    () => {
      if (telegramMessageDoc.voice) {
        getDownloadURL(ref(storage, telegramMessageDoc.voice.storageUri))
          .then(setAudioUrl)
          .catch(() => { });
      }
    },
    [storage, telegramMessageDoc.voice],
  );

  const title = useMemo(
    () => telegramMessageDoc.telegramMessage.from?.first_name ?? '-',
    [telegramMessageDoc.telegramMessage.from?.first_name],
  );

  if (photoUrl) {
    return (
      <Box flex={0}>
        <MessageBox
          data={{
            status: {},
            uri: photoUrl,
          }}
          date={telegramMessageDoc.createdAt.toDate()}
          focus={false}
          forwarded={false}
          id={telegramMessageSnap.id}
          notch
          onDownload={() => window.open(photoUrl, '_blank')}
          onTitleClick={handleTitleClick}
          position={telegramMessageDoc.telegramMessage.from?.id === 7084305245 ? 'right' : 'left'}
          removeButton={false}
          replyButton={false}
          retracted={false}
          status="sent"
          text={telegramMessageDoc.telegramMessage.text ?? telegramMessageDoc.telegramMessage.caption ?? ''}
          title={title}
          titleColor={telegramMessageDoc.telegramMessage.from?.id === 7084305245 ? 'purple' : 'green'}
          type="photo"
        />
      </Box>
    );
  }

  if (audioUrl) {
    return (
      <Box flex={0}>
        <MessageBox
          data={{
            audioURL: audioUrl,
          }}
          date={telegramMessageDoc.createdAt.toDate()}
          focus={false}
          forwarded={false}
          id={telegramMessageSnap.id}
          notch
          onTitleClick={handleTitleClick}
          position={telegramMessageDoc.telegramMessage.from?.id === 7084305245 ? 'right' : 'left'}
          removeButton={false}
          replyButton={false}
          retracted={false}
          status="sent"
          text={telegramMessageDoc.telegramMessage.text ?? telegramMessageDoc.telegramMessage.caption ?? ''}
          title={title}
          titleColor={telegramMessageDoc.telegramMessage.from?.id === 7084305245 ? 'purple' : 'green'}
          type="audio"
        />
      </Box>
    );
  }

  if (telegramMessageDoc.telegramMessage.migrate_to_chat_id) {
    return (
      <MigrateToChatIdMessageRow
        telegramMessage={
          telegramMessageDoc.telegramMessage as Message.MigrateToChatIdMessage
        }
      />
    );
  }

  if (telegramMessageDoc.telegramMessage.migrate_from_chat_id) {
    return (
      <MigrateFromChatIdMessageRow
        telegramMessage={
          telegramMessageDoc.telegramMessage as Message.MigrateFromChatIdMessage
        }
      />
    );
  }

  if (telegramMessageDoc.telegramMessage.write_access_allowed) {
    return (
      <WriteAccessAllowedMessageRow
        telegramMessage={
          telegramMessageDoc.telegramMessage as Message.WriteAccessAllowedMessage
        }
      />
    );
  }

  if (telegramMessageDoc.telegramMessage.group_chat_created) {
    return (
      <GroupChatCreatedMessageRow
        telegramMessage={
          telegramMessageDoc.telegramMessage as Message.GroupChatCreatedMessage
        }
      />
    );
  }

  if (telegramMessageDoc.telegramMessage.message_auto_delete_timer_changed) {
    return (
      <MessageAutoDeleteTimerChangedMessageRow
        telegramMessage={
          telegramMessageDoc.telegramMessage as Message.MessageAutoDeleteTimerChangedMessage
        }
      />
    );
  }

  if (telegramMessageDoc.telegramMessage.new_chat_members) {
    return (
      <NewChatMembersMessageRow
        telegramMessage={
          telegramMessageDoc.telegramMessage as Message.NewChatMembersMessage
        }
      />
    );
  }

  return (
    <Box flex={0}>
      <MessageBox
        date={telegramMessageDoc.createdAt.toDate()}
        focus={false}
        forwarded={false}
        id={telegramMessageSnap.id}
        notch
        onTitleClick={handleTitleClick}
        position={telegramMessageDoc.telegramMessage.from?.id === 7084305245 ? 'right' : 'left'}
        removeButton={false}
        replyButton={false}
        retracted={false}
        status="sent"
        text={telegramMessageDoc.telegramMessage.text ?? telegramMessageDoc.telegramMessage.caption ?? ''}
        title={title}
        titleColor={telegramMessageDoc.telegramMessage.from?.id === 7084305245 ? 'purple' : 'green'}
        type="text"
      />
    </Box>
  );
}

export default function MessageRow(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <MessageRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
