import mixpanel from 'mixpanel-browser';
import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import base64URLdecode from '../../common/base64URLdecode';
import WebAppAction, { WebAppActionType } from '../../common/WebAppAction';

export default function ActionScreen() {
  const data = useMemo<undefined | WebAppAction>(
    () => {
      try {
        const params = new URLSearchParams(Telegram.WebApp.initData);
        const startParam = params.get('start_param');

        if (startParam) {
          const decoded = base64URLdecode(startParam);
          return JSON.parse(decoded) as WebAppAction;
        }

        return undefined;
      } catch (err) {
        return undefined;
      }
    },
    [],
  );

  switch (data?.action) {
    case WebAppActionType.LIST_JOBS: {
      mixpanel.track('Jobs List Opened');
      return (
        <Navigate to="/jobs" />
      );
    }
    case WebAppActionType.LIST_ALLOWANCES: {
      mixpanel.track('Allowances List Opened');
      return (
        <Navigate to="/allowances" />
      );
    }
    case WebAppActionType.LIST_TRIPS: {
      mixpanel.track('Trips List Opened');
      return (
        <Navigate to="/trips" />
      );
    }
    case WebAppActionType.LIST_VENTURES: {
      mixpanel.track('Ventures List Opened');
      return (
        <Navigate to="/ventures" />
      );
    }
    case WebAppActionType.LIST_KEYS: {
      mixpanel.track('Keys List Opened');
      return (
        <Navigate to="/keys" />
      );
    }
    case WebAppActionType.LIST_APPLICATIONS: {
      mixpanel.track('Applications List Opened');
      return (
        <Navigate to="/applications" />
      );
    }
    case WebAppActionType.VIEW_APPLICATION: {
      mixpanel.track('Application View Opened', { applicationId: data.applicationId });
      return (
        <Navigate to={`/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_PROFILE: {
      mixpanel.track('Profile View Opened', { profileId: data.profileId });
      return (
        <Navigate to={`/profiles/${data.profileId}`} />
      ); }
    case WebAppActionType.VIEW_TRIP: {
      mixpanel.track('Trip View Opened', { tripId: data.tripId });
      return (
        <Navigate to={`/trips/${data.tripId}`} />
      );
    }
    case WebAppActionType.VIEW_JOB: {
      mixpanel.track('Job View Opened', { jobId: data.jobId });
      return (
        <Navigate to={`/jobs/${data.jobId}`} />
      );
    }
    case WebAppActionType.VIEW_ALLOWANCE: {
      mixpanel.track('Allowance View Opened', { allowanceId: data.allowanceId });
      return (
        <Navigate to={`/allowances/${data.allowanceId}`} />
      );
    }
    case WebAppActionType.VIEW_TRIP_APPLICATION: {
      mixpanel.track('Trip Application View Opened', {
        applicationId: data.applicationId,
        tripId: data.tripId,
      });
      return (
        <Navigate to={`/trips/${data.tripId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_VENTURE_APPLICATION: {
      mixpanel.track('Venture Application View Opened', {
        applicationId: data.applicationId,
        ventureId: data.ventureId,
      });
      return (
        <Navigate to={`/ventures/${data.ventureId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_ALLOWANCE_APPLICATION: {
      mixpanel.track('Allowance Application View Opened', {
        allowanceId: data.allowanceId,
        applicationId: data.applicationId,
      });
      return (
        <Navigate to={`/allowances/${data.allowanceId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.VIEW_JOB_APPLICATION: {
      mixpanel.track('Job Application View Opened', {
        applicationId: data.applicationId,
        jobId: data.jobId,
      });
      return (
        <Navigate to={`/jobs/${data.jobId}/applications/${data.applicationId}`} />
      );
    }
    case WebAppActionType.EDIT_TRIP: {
      mixpanel.track('Trip Edit Opened', { tripId: data.tripId });
      return (
        <Navigate to={`/trips/${data.tripId}/update`} />
      );
    }
    case WebAppActionType.VIEW_VENTURE: {
      mixpanel.track('Venture View Opened', { ventureId: data.ventureId });
      return (
        <Navigate to={`/ventures/${data.ventureId}`} />
      );
    }
    case WebAppActionType.EDIT_VENTURE: {
      mixpanel.track('Venture Edit Opened', { ventureId: data.ventureId });
      return (
        <Navigate to={`/ventures/${data.ventureId}/update`} />
      );
    }
    case WebAppActionType.NEW_TRIP: {
      mixpanel.track('New Trip View Opened');
      return (
        <Navigate to="/trips/add" />
      );
    }
    case WebAppActionType.NEW_VENTURE: {
      mixpanel.track('New Venture View Opened');
      return (
        <Navigate to="/ventures/add" />
      );
    }
    case WebAppActionType.WELCOME: {
      mixpanel.track('Welcome View Opened');
      return (
        <Navigate to="/welcome" />
      );
    }
    default: {
      mixpanel.track('My Profile View Opened');
      return (
        <Navigate to="/trips" />
      );
    }
  }
}
