import { Icon, IconButton, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuUserPlus } from 'react-icons/lu';
import { useMatch, useNavigate } from 'react-router-dom';

export default function ProfileVersionsButton() {
  const { t } = useTranslation('AdminLayout');

  const match = useMatch('/admin/profileVersions/*');

  const navigate = useNavigate();
  const onClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid');
      navigate('/admin/profileVersions');
    },
    [navigate],
  );

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('profileVersionsButton.default')}
        className="profileVersionsButton"
        icon={<Icon as={LuUserPlus} boxSize={6} />}
        isActive={!!match}
        isRound
        onClick={onClick}
        size="lg"
        variant="ghost"
      />
    </VStack>
  );
}
