import { doc } from 'firebase/firestore';
import { useEffect, useMemo } from 'react';
import {
  Outlet,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useTelegramChatsCollectionRef } from '../../../common/collections/TelegramChats';
import TelegramChatFragment from '../../fragments/TelegramChat';

export default function AdminConversationScreen() {
  const telegramChatsCollectionRef = useTelegramChatsCollectionRef();

  const { telegramChatId } = useParams<{ telegramChatId: string }>();

  const telegramChatRef = useMemo(
    () => doc(telegramChatsCollectionRef, telegramChatId),
    [telegramChatId, telegramChatsCollectionRef],
  );

  const navigate = useNavigate();
  const matchRoot = useMatch('/admin/telegramChats/:telegramChatId');
  useEffect(
    () => {
      if (matchRoot && parseInt(telegramChatRef.id, 10) > 0) {
        navigate(`telegramUsers/${telegramChatRef.id}`);
      }
    },
    [matchRoot, navigate, telegramChatRef.id],
  );

  return (
    <>
      <TelegramChatFragment key={telegramChatRef.id} telegramChatRef={telegramChatRef} />

      <Outlet />
    </>
  );
}
