import { Button, VStack } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { compact } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { useCitiesCollectionRef } from '../../common/collections/Cities';
import { usePicturesCollectionRef } from '../../common/collections/Picture';
import {
  useVenturesCollectionRef,
  VentureDoc,
  VentureLabel,
  VentureStatus,
} from '../../common/collections/Ventures';
import CheckboxFormControl from '../../components/CheckboxFormControl';
import CityFormControl from '../../components/CityFormControl';
import InstagramFormControl from '../../components/InstagramFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';

export type Props = {
  onComplete: (ventureRef: DocumentReference<VentureDoc>) => void;
};

export default function VentureForm({ onComplete }: Props) {
  const { t } = useTranslation('VentureCreateScreen', { keyPrefix: 'VentureForm' });

  const schema = useMemo(
    () => yup.object().shape({
      cityId: yup
        .string()
        .label(t('cityId.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      endsAt: yup
        .string()
        .label(t('endsAt.label'))
        .required(),
      femaleParticipantPlaces: yup
        .number()
        .min(0)
        .max(100)
        .label(t('femaleParticipantPlaces.label'))
        .required(),
      femaleParticipantPrice: yup
        .object()
        .shape({
          amount: yup
            .number()
            .min(0)
            .max(100000)
            .label(t('femaleParticipantPrice.amount.label'))
            .required(),
          currency: yup
            .string()
            .label(t('femaleParticipantPrice.currency.label'))
            .oneOf(['EUR', 'PLN', 'USD', 'UAH'])
            .required(),
        })
        .label(t('femaleParticipantPlaces.label'))
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label')),
      labels: yup
        .array()
        .label(t('labels.label'))
        .min(1)
        .max(20)
        .of(
          yup
            .string()
            .label(t('labels.item.label'))
            .oneOf(Object.values(VentureLabel))
            .required(),
        )
        .required(),
      maleParticipantPlaces: yup
        .number()
        .min(0)
        .max(100)
        .label(t('maleParticipantPlaces.label'))
        .required(),
      maleParticipantPrice: yup
        .object()
        .shape({
          amount: yup
            .number()
            .min(0)
            .max(100000)
            .label(t('maleParticipantPrice.amount.label'))
            .required(),
          currency: yup
            .string()
            .label(t('maleParticipantPrice.currency.label'))
            .oneOf(['EUR', 'PLN', 'USD', 'UAH'])
            .required(),
        })
        .label(t('maleParticipantPlaces.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      pictureIds: yup
        .array()
        .label(t('pictureIds.label'))
        .compact()
        .min(3)
        .max(9)
        .of(
          yup
            .string()
            .label(t('pictureIds.item.label'))
            .required(),
        )
        .required(),
      startsAt: yup
        .string()
        .label(t('startsAt.label'))
        .required(),
    }),
    [t],
  );

  const myProfileSnap = useMyProfileSnap();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      cityId: 'WAW',
      description: '',
      endsAt: moment
        .utc()
        .add(8, 'days')
        .set({
          hour: 3,
          millisecond: 0,
          minute: 0,
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss'),
      femaleParticipantPlaces: 6,
      femaleParticipantPrice: {
        amount: 0,
        currency: 'EUR',
      },
      instagramTag: '',
      labels: [],
      maleParticipantPlaces: 3,
      maleParticipantPrice: {
        amount: 1000,
        currency: 'EUR',
      },
      name: '',
      pictureIds: [],
      startsAt: moment
        .utc()
        .add(7, 'days')
        .set({
          hour: 19,
          millisecond: 0,
          minute: 0,
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss'),
    }),
    [],
  );

  const venturesCollectionRef = useVenturesCollectionRef();
  const citiesCollectionRef = useCitiesCollectionRef();
  const picturesCollectionRef = usePicturesCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const ventureRef = doc(venturesCollectionRef);
      const cityRef = doc(citiesCollectionRef, values.cityId);

      const now = Date.now();

      await setDoc(
        ventureRef,
        {
          _v: 1,
          cityRef,
          createdAt: Timestamp.fromMillis(now),
          description: values.description,
          endsAt: Timestamp.fromDate(new Date(values.endsAt)),
          femaleParticipantPlaces: values.femaleParticipantPlaces,
          femaleParticipantPrice: values.femaleParticipantPrice,
          femaleParticipants: [],
          instagramTag: values.instagramTag,
          labels: values.labels,
          maleParticipantPlaces: values.maleParticipantPlaces,
          maleParticipantPrice: values.maleParticipantPrice,
          maleParticipants: [],
          name: values.name,
          organizerRef: myProfileSnap.ref,
          pictureRefs: compact(values.pictureIds).map((id) => doc(picturesCollectionRef, id)),
          publishedAt: Timestamp.fromMillis(now),
          startsAt: Timestamp.fromDate(new Date(values.startsAt)),
          status: VentureStatus.PUBLISHED,
          updatedAt: Timestamp.fromMillis(now),
        },
      );

      onComplete(ventureRef);
    },
    [
      citiesCollectionRef,
      myProfileSnap.ref,
      onComplete,
      picturesCollectionRef,
      venturesCollectionRef,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={4}
          h="100%"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <PicturesFormControl
              label={t('pictureIds.label')}
              name="pictureIds"
            />

            <TextFormControl
              label={t('name.label')}
              name="name"
            />

            <TextareaFormControl
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
              rows={10}
            />

            <InstagramFormControl
              label={t('instagramTag.label')}
              name="instagramTag"
              type="text"
            />

            <CityFormControl
              aroundLatLngViaIP
              label={t('cityId.label')}
              name="cityId"
              placeholder={t('cityId.placeholder')}
            />

            <CheckboxFormControl
              label={t('labels.label')}
              name="labels"
              options={{
                /* eslint-disable perfectionist/sort-objects */
                [VentureLabel.DINNER]: t(`labels.option.${VentureLabel.DINNER}`),
                [VentureLabel.NIGHTLIFE]: t(`labels.option.${VentureLabel.NIGHTLIFE}`),
                /* eslint-enable perfectionist/sort-objects */
              }}
            />

            <TextFormControl
              label={t('startsAt.label')}
              name="startsAt"
              type="datetime-local"
            />

            <TextFormControl
              label={t('endsAt.label')}
              name="endsAt"
              type="datetime-local"
            />

            <NumberFormControl
              label={t('femaleParticipantPlaces.label')}
              max={100}
              min={1}
              name="femaleParticipantPlaces"
              step={1}
            />

            <NumberFormControl
              label={t('femaleParticipantPrice.amount.label')}
              max={1000}
              min={0}
              name="femaleParticipantPrice.amount"
              step={100}
            />

            <NumberFormControl
              label={t('maleParticipantPlaces.label')}
              max={100}
              min={1}
              name="maleParticipantPlaces"
              step={1}
            />

            <NumberFormControl
              label={t('maleParticipantPrice.amount.label')}
              max={1000}
              min={0}
              name="maleParticipantPrice.amount"
              step={100}
            />
          </VStack>

          <Button
            colorScheme={isValid ? undefined : 'red'}
            isLoading={isValidating || isSubmitting}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
