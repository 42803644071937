import {
  Box,
  BoxProps,
  Container,
  Heading,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import AppLanguage from '../../common/AppLanguage';
import { JobDoc } from '../../common/collections/Jobs';
import Catch from '../../components/Catch';
import PictureCell from '../../components/PictureCell';
import useDimensions from '../../hooks/useDimensions';
import i18n from '../../i18n';

export type Props = {
  jobSnap: QueryDocumentSnapshot<JobDoc>;
} & BoxProps;

export function JobMain({
  jobSnap, ...boxProps
}: Props) {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Job Clicked');

      window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

      navigate(`/jobs/${jobSnap.id}`);
    },
    [navigate, jobSnap.id],
  );

  const jobDoc = useMemo(() => jobSnap.data(), [jobSnap]);

  const [ref, { height: h, width: w }] = useDimensions();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      className="jobItem"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <PictureCell
        bottom={0}
        height={h ?? 1600}
        left={0}
        pictureRef={jobDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        top={0}
        width={w ?? 900}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {/* eslint-disable-next-line max-len */}
              {(jobDoc.translations && jobDoc.translations[i18n.language as AppLanguage]?.name) ?? jobDoc.name}
            </Heading>
          </Container>
        </VStack>
      </VStack>
    </Box>
  );
}

export function JobLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export default function Job({ jobSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <JobLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <JobMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          jobSnap={jobSnap}
        />
      </Suspense>
    </Catch>
  );
}
