import {
  Box,
  BoxProps,
  Container,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { t } from 'i18next';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';

import { TripDoc } from '../../../common/collections/Trips';
import Catch from '../../../components/Catch';
import PictureImage from '../../../components/PictureImage';

export type Props = {
  onClick: (tripSnap: QueryDocumentSnapshot<TripDoc>) => void;
  tripSnap: QueryDocumentSnapshot<TripDoc>;
} & Omit<BoxProps, 'onClick'>;

export function TripCardMain({
  onClick,
  tripSnap,
  ...boxProps
}: Props) {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick(tripSnap);
    },
    [onClick, tripSnap],
  );

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
    >
      <PictureImage
        bottom={0}
        left={0}
        objectFit="cover"
        pictureRef={tripDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        scrollSnapAlign="start"
        scrollSnapStop="always"
        top={0}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="sm"
              fontWeight="bold"
              textAlign="center"
            >
              {/* eslint-disable-next-line max-len */}
              {tripDoc.name}
            </Heading>
          </Container>

          <Text
            fontSize="xs"
            opacity={0.75}
          >
            {t('dates.value', {
              endsAt: tripDoc.endsAt.toDate(),
              formatParams: {
                endsAt: { day: 'numeric', month: 'short', year: 'numeric' },
                startsAt: { day: 'numeric', month: 'short' },
              },
              startsAt: tripDoc.startsAt.toDate(),
            })}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}

export function TripCardLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export default function TripCard({ onClick, tripSnap: profileSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <TripCardLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <TripCardMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          onClick={onClick}
          tripSnap={profileSnap}
        />
      </Suspense>
    </Catch>
  );
}
