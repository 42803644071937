import {
  Center,
  Container,
  Heading,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useVenturesCollectionRef } from '../../common/collections/Ventures';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VentureForm from './VentureForm';

export function VentureCreateScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const insets = useInsets();

  const { t } = useTranslation('VentureCreateScreen');

  const { ventureId } = useParams<{ ventureId: string }>();

  const venturesCollectionRef = useVenturesCollectionRef();
  const ventureRef = useMemo(
    () => doc(venturesCollectionRef, ventureId),
    [ventureId, venturesCollectionRef],
  );

  const onComplete = useCallback(
    () => {
      navigate('/ventures');
    },
    [navigate],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Heading
            flex={1}
            fontSize="4xl"
            lineHeight="40px"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {t('title')}
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <VentureForm
          onComplete={onComplete}
          ventureRef={ventureRef}
        />
      </Container>
    </VStack>
  );
}

export default function VentureCreateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <VentureCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
