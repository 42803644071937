import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

import { useFunctions } from '../components/FunctionsProvider';

export type Data = {
  telegramChatId: string;
  text: string;
};

export type Result = void;

export default function useTelegramSendMessage() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-sendMessage'), [functions]);
}
