import { Image, ImageProps, Skeleton } from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useMemo, useState } from 'react';

import { type PictureDoc } from '../common/collections/Picture';
import useBlur from '../hooks/useBlur';
import useDocumentSnapshot from '../hooks/useDocumentSnapshot';
import { useStorage } from './StorageProvider';

export type PropsMain = {
  pictureSnap: QueryDocumentSnapshot<PictureDoc>;
} & ImageProps;

export function PictureImageMain({
  pictureSnap, ...imageProps
}: PropsMain) {
  const pictureDoc = useMemo(() => pictureSnap.data(), [pictureSnap]);

  const storage = useStorage();

  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      getDownloadURL(ref(storage, pictureDoc.phoneUri))
        .then((nextSrc) => {
          setSrc(nextSrc);
        })
        .catch(() => {
          setSrc(undefined);
        });
    },
    [pictureDoc.phoneUri, storage],
  );

  const fallbackSrc = useBlur({
    blurHash: pictureDoc.blurHash,
    height: 32,
    width: 18,
  });

  return (
    <Image
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
      fallbackSrc={fallbackSrc}
      src={src}
    />
  );
}

export type Props = {
  pictureRef: DocumentReference<PictureDoc>;
} & ImageProps;

export default function PictureImage({
  pictureRef, ...imageProps
}: Props) {
  const { snap: pictureSnap } = useDocumentSnapshot(pictureRef);

  if (!pictureSnap?.exists()) {
    return (
      <Skeleton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...imageProps}
      />
    );
  }

  return (
    <PictureImageMain
    // eslint-disable-next-line react/jsx-props-no-spreading
      {...imageProps}
      pictureSnap={pictureSnap}
    />
  );
}
