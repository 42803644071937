import {
  Container,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Catch from '../../../components/Catch';
import { useInsets } from '../../../components/InsetsProvider';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import ProfileVersionGrid, { ProfileVersionGridLoading } from './ProfileVersionGrid';

export function AdminProfileVersionsScreenMain() {
  return (
    <Grid columnGap={0} h="100%" templateColumns="repeat(3, 1fr)">
      <GridItem minH={0} overflow="auto">
        <ProfileVersionGrid />
      </GridItem>

      <Outlet />
    </Grid>
  );
}

export function AdminProfileVersionsScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <ProfileVersionGridLoading />
      </Container>
    </VStack>
  );
}

export default function AdminProfileVersionsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<AdminProfileVersionsScreenLoading />}>
        <AdminProfileVersionsScreenMain />
      </Suspense>
    </Catch>
  );
}
