import {
  AspectRatio,
  Box,
  Grid,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import {
  and,
  limit,
  or,
  orderBy,
  query,
  Timestamp,
  where,
} from 'firebase/firestore';
import moment from 'moment';
import { Suspense } from 'react';

import { useTripsCollectionRef } from '../../common/collections/Trips';
import Catch from '../../components/Catch';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Trip, { TripLoading } from '../TripsScreen/Trip';

export function TripGridMain() {
  const myProfileSnap = useMyProfileSnap();

  const tripsCollectionRef = useTripsCollectionRef();
  const { snap: tripsSnap } = useQuerySnapshot(
    query(
      tripsCollectionRef,
      and(
        where('endsAt', '>', Timestamp.fromDate(moment().startOf('day').subtract(1, 'week').toDate())),
        or(
          where('femaleParticipants', 'array-contains', myProfileSnap.ref),
          where('maleParticipants', 'array-contains', myProfileSnap.ref),
        ),
      ),
      orderBy('startsAt', 'asc'),
      limit(100),
    ),
  );

  return (
    <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
      {(tripsSnap?.docs ?? []).map((tripSnap) => (
        <AspectRatio
          key={tripSnap.id}
          ratio={9 / 16}
        >
          <Trip
            scrollSnapAlign="center"
            tripSnap={tripSnap}
          />
        </AspectRatio>
      ))}

      {new Array(Math.ceil((tripsSnap?.docs ?? []).length / 2) * 2 - (tripsSnap?.docs ?? []).length)
        .fill(null)
        .map((_, i) => (
          <Box
            _dark={{
              backgroundColor: 'rgba(255, 255, 255, 0.05)',
            }}
            _light={{
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }}
            borderRadius="md"
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          />
        ))}
    </Grid>
  );
}

export function TripGridLoading() {
  return (
    <VStack alignItems="stretch" className="myTripsList" gap={8}>
      <VStack alignItems="stretch" gap={2}>
        <Skeleton h={5} />

        <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          <TripLoading />
          <TripLoading />
          <TripLoading />
          <TripLoading />
        </Grid>
      </VStack>
    </VStack>
  );
}

export default function TripGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<TripGridLoading />}>
        <TripGridMain />
      </Suspense>
    </Catch>
  );
}
