import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

import { useFunctions } from '../components/FunctionsProvider';

export type Data = {
  storageUri: string;
  telegramChatId: string;
};

export type Result = void;

export default function useTelegramSendPhoto() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-sendPhoto'), [functions]);
}
