import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { useFirestore } from '../../components/FirestoreProvider';
import { type ProfileDoc } from './Profiles';

export type InterviewDoc = {
  _v: 1;
  calendlyUri?: string;
  cancelUrl?: string;
  createdAt: Timestamp;
  duration: number;
  meetingUrl: string;
  profileRef: DocumentReference<ProfileDoc>;
  rescheduleUrl?: string;
  scheduledAt: Timestamp;
  stripeChargeId?: string;
  stripeRefundId?: string;
  timezone?: string;
  updatedAt: Timestamp;
};

export const isInterviewDoc = (
  doc?: DocumentData,
): doc is InterviewDoc => true;

export const isInterviewRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<InterviewDoc> => ref.parent.id === 'interviews';

function isInterviewSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<InterviewDoc>;
function isInterviewSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<InterviewDoc>;
function isInterviewSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<InterviewDoc> | QueryDocumentSnapshot<InterviewDoc> {
  return isInterviewRef(snap.ref);
}

export { isInterviewSnap };

export const getInterviewsCollectionRef = (firestore: Firestore) => collection(firestore, 'interviews') as CollectionReference<InterviewDoc>;

export const useInterviewsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getInterviewsCollectionRef(firestore), [firestore]);
};
