import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { compact } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AppLanguage from '../../../common/AppLanguage';
import {
  AllowanceDoc,
  AllowanceStatus,
  useAllowancesCollectionRef,
} from '../../../common/collections/Allowances';
import { useCitiesCollectionRef } from '../../../common/collections/Cities';
import { usePicturesCollectionRef } from '../../../common/collections/Picture';
import CityFormControl from '../../../components/CityFormControl';
import PicturesFormControl from '../../../components/PicturesFormControl';
import { useMyProfileRef } from '../../../components/refProviders/MyProfileRefProvider';
import TextareaFormControl from '../../../components/TextareaFormControl';
import TextFormControl from '../../../components/TextFormControl';

export type Props = {
  onComplete: (allowanceRef: DocumentReference<AllowanceDoc>) => void;
};

export default function AllowanceForm({ onComplete }: Props) {
  const { t } = useTranslation('AllowanceCreateScreen', { keyPrefix: 'AllowanceForm' });

  const translationSchema = useMemo(
    () => yup
      .object()
      .optional()
      .shape({
        description: yup
          .string()
          .label(t('description.label'))
          .optional(),
        name: yup
          .string()
          .label(t('name.label'))
          .optional(),
      }),
    [t],
  );

  const schema = useMemo(
    () => yup.object().shape({
      cityId: yup
        .string()
        .label(t('cityId.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      pictureIds: yup
        .array()
        .label(t('pictureIds.label'))
        .compact()
        .min(3)
        .max(9)
        .of(
          yup
            .string()
            .label(t('pictureIds.item.label'))
            .required(),
        )
        .required(),
      translations: yup
        .object<Record<AppLanguage, Pick<AllowanceDoc, 'description' | 'name'>>>()
        .label(t('translations.label'))
        .required()
        .shape({
          ru: translationSchema,
          uk: translationSchema,
        }),
    }),
    [t, translationSchema],
  );

  const myProfileRef = useMyProfileRef();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      cityId: 'DPS',
      description: '',
      name: '',
      pictureIds: [],
      translations: {
        ru: {
          description: '',
          name: '',
        },
        uk: {
          description: '',
          name: '',
        },
      },
    }),
    [],
  );

  const allowancesCollectionRef = useAllowancesCollectionRef();
  const citiesCollectionRef = useCitiesCollectionRef();
  const picturesCollectionRef = usePicturesCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const allowanceRef = doc(allowancesCollectionRef);
      const cityRef = doc(citiesCollectionRef, values.cityId);

      await setDoc(
        allowanceRef,
        {
          _v: 1,
          cityRef,
          createdAt: Timestamp.now(),
          description: values.description,
          name: values.name,
          organizerRef: myProfileRef,
          pictureRefs: compact(values.pictureIds).map((id) => doc(picturesCollectionRef, id)),
          status: AllowanceStatus.PUBLISHED,
          translations: values.translations,
          updatedAt: Timestamp.now(),
        },
      );

      onComplete(allowanceRef);
    },
    [
      citiesCollectionRef,
      myProfileRef,
      onComplete,
      picturesCollectionRef,
      allowancesCollectionRef,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={4}
          h="100%"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <PicturesFormControl
              label={t('pictureIds.label')}
              name="pictureIds"
            />

            <TextFormControl
              label={t('name.label')}
              name="name"
            />

            <TextareaFormControl
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
              rows={10}
            />

            <Tabs>
              <TabList overflowX="auto">
                <Tab>Russian</Tab>
                <Tab>Ukrainian</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <TextFormControl
                    label={t('name.label')}
                    name="translations.ru.name"
                  />

                  <TextareaFormControl
                    label={t('description.label')}
                    name="translations.ru.description"
                    placeholder={t('description.placeholder')}
                    rows={10}
                  />
                </TabPanel>

                <TabPanel>
                  <TextFormControl
                    label={t('name.label')}
                    name="translations.uk.name"
                  />

                  <TextareaFormControl
                    label={t('description.label')}
                    name="translations.uk.description"
                    placeholder={t('description.placeholder')}
                    rows={10}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>

            <CityFormControl
              label={t('cityId.label')}
              name="cityId"
              placeholder={t('cityId.placeholder')}
            />
          </VStack>

          <Button
            colorScheme={isValid ? undefined : 'red'}
            isLoading={isValidating || isSubmitting}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
