import { Card, CardBody, Text } from '@chakra-ui/react';
import { Message } from '@grammyjs/types';
import moment from 'moment';

export type Props = {
  telegramMessage: Message.MessageAutoDeleteTimerChangedMessage;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function MessageAutoDeleteTimerChangedMessageRow({ telegramMessage }: Props) {
  return (
    <Card alignSelf="center" fontSize="sm" maxW="sm" variant="elevated">
      <CardBody p={2}>
        <Text>
          Message auto delete timer changed:
          {' '}
          {moment.duration(telegramMessage.message_auto_delete_timer_changed.message_auto_delete_time, 'seconds').humanize()}
        </Text>
      </CardBody>
    </Card>
  );
}
