import {
  Avatar,
  AvatarBadge,
  AvatarGroup,
  Badge,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  limit,
  limitToLast,
  orderBy,
  query,
  QueryDocumentSnapshot,
} from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import _, { compact, last } from 'lodash';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuBadgeCheck, LuUser } from 'react-icons/lu';
import { useMatch, useNavigate } from 'react-router-dom';

import {
  getProfileVersionsCollectionRef,
  useProfilesCollectionRef,
} from '../../../common/collections/Profiles';
import { TelegramChatDoc } from '../../../common/collections/TelegramChats';
import { useTelegramMessagesCollectionRef } from '../../../common/collections/TelegramMessages';
import { useTelegramUsersCollectionRef } from '../../../common/collections/TelegramUsers';
import countryCodes from '../../../common/countryCodes';
import Catch from '../../../components/Catch';
import PictureAvatar from '../../../components/PictureAvatar';
import { useStorage } from '../../../components/StorageProvider';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';

export type Props = {
  telegramChatSnap: QueryDocumentSnapshot<TelegramChatDoc>;
};

export function TelegramChatRowMain({ telegramChatSnap }: Props) {
  const telegramChatDoc = useMemo(() => telegramChatSnap.data(), [telegramChatSnap]);

  const telegramMessagesCollectionRef = useTelegramMessagesCollectionRef(telegramChatSnap.ref);

  const { snap: telegramMessagesSnap } = useQuerySnapshot(
    query(
      telegramMessagesCollectionRef,
      orderBy('createdAt', 'asc'),
      limitToLast(1),
    ),
  );

  const lastMessageSnap = useMemo(
    () => last(telegramMessagesSnap?.docs ?? []),
    [telegramMessagesSnap?.docs],
  );

  const lastMessageDoc = useMemo(
    () => lastMessageSnap?.data(),
    [lastMessageSnap],
  );

  const navigate = useNavigate();
  const handleClick = useCallback(
    () => { navigate(telegramChatSnap.id); },
    [telegramChatSnap.id, navigate],
  );

  const currentTime = useCurrentTime(1000 * 60);
  const dateText = useMemo(
    () => (
      telegramChatDoc.lastMessageAt
        ? moment(telegramChatDoc.lastMessageAt.toDate()).calendar(currentTime)
        : undefined
    ),
    [telegramChatDoc.lastMessageAt, currentTime],
  );

  const match = useMatch(`/admin/telegramChats/${telegramChatSnap.id}/*`);

  const storage = useStorage();

  const [pictureUrl, setPictureUrl] = useState<string>();
  useEffect(
    () => {
      if (telegramChatDoc.pictureStorageUri) {
        getDownloadURL(ref(storage, telegramChatDoc.pictureStorageUri))
          .then(setPictureUrl)
          .catch(() => { });
      }
    },
    [storage, telegramChatDoc.pictureStorageUri],
  );

  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();
  const telegramUserRef = useMemo(
    () => (
      telegramChatDoc.telegramChat?.type === 'private'
        ? doc(telegramUsersCollectionRef, telegramChatSnap.id)
        : undefined
    ),
    [telegramChatDoc.telegramChat?.type, telegramChatSnap.id, telegramUsersCollectionRef],
  );

  const { snap: telegramUserSnap } = useDocumentSnapshot(telegramUserRef);

  const telegramUserDoc = useMemo(() => telegramUserSnap?.data(), [telegramUserSnap]);

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => (telegramUserDoc?.uid ? doc(profilesCollectionRef, telegramUserDoc.uid) : undefined),
    [profilesCollectionRef, telegramUserDoc?.uid],
  );

  const { snap: profileVersionsSnap } = useQuerySnapshot(
    profileRef ? query(
      getProfileVersionsCollectionRef(profileRef),
      limit(1),
    ) : undefined,
  );

  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  const profileDoc = useMemo(() => profileSnap?.data(), [profileSnap]);

  const { snap: originSnap } = useDocumentSnapshot(profileDoc?.originRef);

  const originDoc = useMemo(() => originSnap?.data(), [originSnap]);

  const getEmoji = useCallback(
    (code?: string) => {
      if (!code) {
        return code;
      }
      return _.find(countryCodes, (data) => data.code === code)?.emoji ?? code;
    },
    [],
  );

  return (
    <HStack
      _hover={{ bg: 'rgba(0, 0, 0, 0.05)' }}
      as="button"
      bg={match ? 'rgba(0, 0, 0, 0.1)' : undefined}
      borderRadius="md"
      gap={2}
      onClick={handleClick}
      padding={2}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
    >
      <AvatarGroup flexGrow={0} flexShrink={0} size="md">
        {profileDoc ? (
          <PictureAvatar
            icon={<Icon as={LuUser} />}
            loading="lazy"
            pictureRef={profileDoc.pictureRefs[0]}
          >
            {telegramChatDoc.myChatMember && ['kicked', 'left', 'restricted'].includes(telegramChatDoc.myChatMember.status) ? (
              <AvatarBadge bg="red.500" boxSize="1em" />
            ) : (
              <AvatarBadge bg="green.500" boxSize="1em" />
            )}
          </PictureAvatar>
        ) : (
          <Avatar
            icon={<Icon as={LuUser} />}
            src={pictureUrl}
          >
            {/* eslint-disable-next-line no-nested-ternary */}
            {telegramChatDoc.myChatMember && ['kicked', 'left', 'restricted'].includes(telegramChatDoc.myChatMember.status) ? (
              <AvatarBadge bg="red.500" boxSize="1em" />
            ) : (profileVersionsSnap?.docs.length ? (
              <AvatarBadge bg="blue.500" boxSize="1em" />
            ) : (
              <AvatarBadge bg="yellow.500" boxSize="1em" />
            ))}
          </Avatar>
        )}

      </AvatarGroup>

      <VStack alignItems="stretch" flexGrow={1} flexShrink={1} gap={0} minW={0}>
        <HStack gap={1}>
          {profileDoc?.isVerified ? (
            <Icon as={LuBadgeCheck} color="blue.500" />
          ) : false}

          {profileDoc ? (
            <Text
              flexGrow={1}
              flexShrink={1}
              overflow="hidden"
              textAlign="left"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {profileDoc.name}
              {', '}
              {profileDoc.age}
              {' '}
              {compact([getEmoji(originDoc?.countryRef?.id), originDoc?.name]).join(' ')}
            </Text>
          ) : (
            <Text
              flexGrow={1}
              flexShrink={1}
              overflow="hidden"
              textAlign="left"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {telegramChatDoc.telegramChat?.first_name ?? telegramChatDoc.telegramChat?.title ?? 'Unknown'}
            </Text>
          )}

          <Text
            flexGrow={0}
            flexShrink={0}
            fontSize="xs"
            textAlign="right"
          >
            {dateText}
          </Text>
        </HStack>

        <HStack>
          <Text
            flexGrow={1}
            flexShrink={1}
            fontSize="sm"
            overflow="hidden"
            textAlign="left"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {lastMessageDoc?.telegramMessage.text ?? lastMessageDoc?.telegramMessage.caption}
          </Text>

          {(telegramChatDoc.unreadMessagesCount ?? 0) > 0 ? (
            <Badge
              colorScheme="red"
              flexGrow={0}
              flexShrink={0}
            >
              {telegramChatDoc.unreadMessagesCount}
            </Badge>
          ) : null}
        </HStack>
      </VStack>
    </HStack>
  );
}

export default function TelegramChatRow(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TelegramChatRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
