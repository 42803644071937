import { Grid, useCheckboxGroup, UseCheckboxGroupProps } from '@chakra-ui/react';

import Goal from '../../common/Goal';
import FriendshipCard from './FriendshipCard';
import FunCard from './FunCard';
import LoveCard from './LoveCard';
import MarriageCard from './MarriageCard';
import ModellingCard from './ModellingCard';
import PartyingCard from './PartyingCard';
import SexCard from './SexCard';
import TravelCard from './TravelCard';

export interface Props {
  label: string;
  name: string;
}

export default function GoalCheckboxGroup(props: UseCheckboxGroupProps) {
  const { getCheckboxProps } = useCheckboxGroup(props);

  const radioFriendship = getCheckboxProps({ value: Goal.FRIENDSHIP });
  const radioFun = getCheckboxProps({ value: Goal.FUN });
  const radioLove = getCheckboxProps({ value: Goal.LOVE });
  const radioMarriage = getCheckboxProps({ value: Goal.MARRIAGE });
  const radioModelling = getCheckboxProps({ value: Goal.MODELLING });
  const radioPartying = getCheckboxProps({ value: Goal.PARTYING });
  const radioSex = getCheckboxProps({ value: Goal.SEX });
  const radioTravel = getCheckboxProps({ value: Goal.TRAVEL });

  return (
    <Grid
      gap={4}
      templateColumns="repeat(2, 1fr)"
      templateRows="repeat(2, 1fr)"
    >
      <FriendshipCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioFriendship}
      />

      <LoveCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioLove}
      />

      <SexCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioSex}
      />

      <MarriageCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioMarriage}
      />

      <ModellingCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioModelling}
      />

      <PartyingCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioPartying}
      />

      <TravelCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioTravel}
      />

      <FunCard
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...radioFun}
      />
    </Grid>
  );
}
