import { Functions } from 'firebase/functions';
import { createContext, PropsWithChildren, useContext } from 'react';

type Props = {
  sdk: Functions;
};

const Context = createContext<Functions | null>(null);

export const useFunctions = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error();
  }

  return ctx;
};

export default function FunctionsProvider({
  children,
  sdk,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={sdk}>
      {children}
    </Context.Provider>
  );
}
