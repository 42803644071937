import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import AdminInterviewsScreen from './admin/screens/AdminInterviewsScreen';
import AdminLayout from './admin/screens/AdminLayout';
import AdminProfileScreen from './admin/screens/AdminProfileScreen';
import AdminProfilesScreen from './admin/screens/AdminProfilesScreen';
import AdminProfileVersionScreen from './admin/screens/AdminProfileVersionScreen';
import AdminProfileVersionsScreen from './admin/screens/AdminProfileVersionsScreen';
import AdminProfileVersionTelegramChatScreen from './admin/screens/AdminProfileVersionTelegramChatScreen';
import AdminTelegramChatScreen from './admin/screens/AdminTelegramChatScreen';
import AdminTelegramChatsScreen from './admin/screens/AdminTelegramChatsScreen';
import AdminTelegramChatTelegramUserScreen from './admin/screens/AdminTelegramChatTelegramUserScreen';
import AdminTripsScreen from './admin/screens/AdminTripsScreen';
import AllowanceCreateScreen from './admin/screens/AllowanceCreateScreen';
import JobCreateScreen from './admin/screens/JobCreateScreen';
import ActionScreen from './screens/ActionScreen';
import AllowanceScreen from './screens/AllowanceScreen';
import AllowancesScreen from './screens/AllowancesScreen';
import ApplicationScreen from './screens/ApplicationScreen';
import CheckMyProfileRefLayout from './screens/CheckMyProfileRefLayout';
import CheckMyProfileSnapLayout from './screens/CheckMyProfileSnapLayout';
import JobScreen from './screens/JobScreen';
import JobsScreen from './screens/JobsScreen';
import KeysScreen from './screens/KeysScreen';
import LoginScreen from './screens/LoginScreen';
import MainLayout from './screens/MainLayout';
import MyProfileScreen from './screens/MyProfileScreen';
import MyProfileUpdateScreen from './screens/MyProfileUpdateScreen';
import ProfileScreen from './screens/ProfileScreen';
import TelegramLoginScreen from './screens/TelegramLoginScreen';
import TripCreateScreen from './screens/TripCreateScreen';
import TripScreen from './screens/TripScreen';
import TripsScreen from './screens/TripsScreen';
import TripUpdateScreen from './screens/TripUpdateScreen';
import VentureCreateScreen from './screens/VentureCreateScreen';
import VentureScreen from './screens/VentureScreen';
import VenturesScreen from './screens/VenturesScreen';
import VentureUpdateScreen from './screens/VentureUpdateScreen';
import WelcomeScreen from './screens/WelcomeScreen';

export default function Navigation() {
  return (
    <>
      <Helmet defaultTitle="Qupidu - Exclusive Travel Community" titleTemplate="%s | Qupidu" />

      <BrowserRouter>
        <Routes>
          <Route element={<ActionScreen />} index />
          <Route element={<LoginScreen />} path="login" />
          <Route element={<TelegramLoginScreen />} path="telegram/login" />

          <Route element={<CheckMyProfileRefLayout fallback={<Navigate replace to="/login" />} telegramFallback={<Navigate replace to="/telegram/login" />} />}>
            <Route element={<WelcomeScreen />} path="welcome" />

            <Route element={<MainLayout />}>
              <Route path="trips">
                <Route element={<TripsScreen />} index />
                <Route path=":tripId">
                  <Route element={<TripScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="ventures">
                <Route element={<VenturesScreen />} index />

                <Route path=":ventureId">
                  <Route element={<VentureScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="allowances">
                <Route element={<AllowancesScreen />} index />

                <Route path=":allowanceId">
                  <Route element={<AllowanceScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="jobs">
                <Route element={<JobsScreen />} index />

                <Route path=":jobId">
                  <Route element={<JobScreen />} index />

                  <Route path="applications">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<ApplicationScreen />} path=":applicationId" />
                  </Route>
                </Route>
              </Route>

              <Route path="keys">
                <Route element={<KeysScreen />} index />
              </Route>

              <Route element={<ProfileScreen />} path="profiles/:profileId" />

              <Route element={<ApplicationScreen />} path="applications/:applicationId" />

              <Route element={<CheckMyProfileSnapLayout fallback={<Navigate replace to="/welcome" />} />}>
                <Route path="profile">
                  <Route element={<MyProfileScreen />} index />
                  <Route element={<MyProfileUpdateScreen />} path="update" />
                </Route>
              </Route>
            </Route>

            <Route element={<AdminLayout />} path="admin">
              <Route element={<Navigate replace to="telegramChats" />} index />

              <Route element={<AdminTelegramChatsScreen />} path="telegramChats">
                <Route index />

                <Route element={<AdminTelegramChatScreen />} path=":telegramChatId">
                  <Route index />
                  <Route path="telegramUsers">
                    <Route element={<Navigate replace to=".." />} index />
                    <Route element={<AdminTelegramChatTelegramUserScreen />} path=":telegramUserId/*" />
                  </Route>
                </Route>
              </Route>

              <Route element={<AdminProfilesScreen />} path="profiles">
                <Route index />
                <Route element={<AdminProfileScreen />} path=":profileId/*" />
              </Route>

              <Route path="ventures">
                <Route element={<VentureCreateScreen />} path="add" />
                <Route path=":ventureId">
                  <Route element={<VentureUpdateScreen />} path="update" />
                </Route>
              </Route>

              <Route path="trips">
                <Route element={<AdminTripsScreen />} index />
                <Route element={<TripCreateScreen />} path="add" />
                <Route path=":tripId">
                  <Route element={<TripUpdateScreen />} path="update" />
                </Route>
              </Route>

              <Route path="allowances">
                <Route element={<AllowanceCreateScreen />} path="add" />
              </Route>

              <Route path="jobs">
                <Route element={<JobCreateScreen />} path="add" />
              </Route>

              <Route element={<AdminInterviewsScreen />} path="interviews">
                <Route index />
              </Route>

              <Route element={<AdminProfileVersionsScreen />} path="profileVersions">
                <Route index />

                <Route element={<AdminProfileVersionScreen />} path=":profileId/:profileVersionId">
                  <Route element={<Navigate replace to="telegramChat" />} index />
                  <Route element={<AdminProfileVersionTelegramChatScreen />} path="telegramChat" />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}
