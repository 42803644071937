import { User } from '@grammyjs/types';
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { useFirestore } from '../../components/FirestoreProvider';

export type TelegramUserDoc = {
  _v: 1;
  createdAt: Timestamp;
  pictureStorageUri?: string;
  telegramUser?: User;
  uid?: string;
  updatedAt: Timestamp;
};

export const isTelegramUserDoc = (
  doc?: DocumentData,
): doc is TelegramUserDoc => true;

export const isTelegramUserRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TelegramUserDoc> => ref.parent.id === 'telegramUsers';

function isTelegramUserSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TelegramUserDoc>;
function isTelegramUserSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TelegramUserDoc>;
function isTelegramUserSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TelegramUserDoc> | QueryDocumentSnapshot<TelegramUserDoc> {
  return isTelegramUserRef(snap.ref);
}

export { isTelegramUserSnap };

export const getTelegramUsersCollectionRef = (firestore: Firestore) => collection(firestore, 'telegramUsers') as CollectionReference<TelegramUserDoc>;

export const useTelegramUsersCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getTelegramUsersCollectionRef(firestore), [firestore]);
};
