import {
  AspectRatio,
  Button,
  Center,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import {
  ChangeEvent,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  LuCalendar,
  LuFile,
  LuFilm,
  LuImage,
  LuPlane,
  LuSend,
  LuVenetianMask,
} from 'react-icons/lu';
import { v4 as uuid } from 'uuid';

import AppLanguage from '../../../common/AppLanguage';
import { TelegramChatDoc, TelegramChatStatus } from '../../../common/collections/TelegramChats';
import { useTelegramMessagesCollectionRef } from '../../../common/collections/TelegramMessages';
import { useTelegramUsersCollectionRef } from '../../../common/collections/TelegramUsers';
import { TripDoc, TripStatus, useTripsCollectionRef } from '../../../common/collections/Trips';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import { useStorage } from '../../../components/StorageProvider';
import useTelegramSendConsultationInvitation from '../../../functions/useTelegramSendConsultationInvitation';
import useTelegramSendInterviewInvitation from '../../../functions/useTelegramSendInterviewInvitation';
import useTelegramSendMessage from '../../../functions/useTelegramSendMessage';
import useTelegramSendPhoto from '../../../functions/useTelegramSendPhoto';
import useTelegramSendTrip from '../../../functions/useTelegramSendTrip';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import useShowError from '../../../hooks/useShowError';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import ChatMembers from './ChatMembers';
import MessageRow from './MessageRow';
import Profile from './Profile';
import Trip from './Trip';

export type Props = {
  telegramChatRef: DocumentReference<TelegramChatDoc>;
};

export function ConversationFragmentMain({ telegramChatRef }: Props) {
  const { snap: telegramChatSnap } = useDocumentSnapshot(telegramChatRef);
  const telegramChatDoc = useMemo(() => telegramChatSnap?.data(), [telegramChatSnap]);

  const telegramMessagesCollectionRef = useTelegramMessagesCollectionRef(telegramChatRef);

  const { snap: telegramMessagesSnap } = useQuerySnapshot(
    query(
      telegramMessagesCollectionRef,
      orderBy('createdAt', 'desc'),
      limit(200),
    ),
  );

  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();

  const [value, setValue] = useState<string>('');
  const [isSending, setSending] = useState<boolean>(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(e.currentTarget.value);
    },
    [],
  );

  const inputPhoto = useRef<HTMLInputElement>(null);
  const telegramSendPhoto = useTelegramSendPhoto();
  const [sendingImage, setSendingImage] = useState(false);
  const storage = useStorage();
  const showError = useShowError();
  const handlePhotoChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        return;
      }

      const file: File = e.target.files[0];

      setSendingImage(true);

      const dest = ref(storage, `tmp/${uuid()}/${file.name}`);

      uploadBytes(dest, file)
        .then(() => telegramSendPhoto({
          storageUri: dest.toString(),
          telegramChatId: telegramChatRef.id,
        }))
        .finally(() => setSendingImage(false))
        .catch(showError);
    },
    [showError, storage, telegramChatRef.id, telegramSendPhoto],
  );

  const telegramSendMessage = useTelegramSendMessage();

  const handleSubmit = useCallback(
    () => {
      setSending(true);
      telegramSendMessage({
        telegramChatId: telegramChatRef.id,
        text: value,
      })
        .finally(() => {
          setSending(false);
        })
        .then(() => {
          setValue('');
        })
        .catch(showError);
    },
    [showError, telegramChatRef.id, telegramSendMessage, value],
  );

  const onOpen = useCallback(
    () => {
      setDoc(telegramChatRef, {
        status: TelegramChatStatus.OPEN,
        updatedAt: Timestamp.now(),
      }, { merge: true }).catch(() => { });
    },
    [telegramChatRef],
  );

  const onClose = useCallback(
    () => {
      setDoc(telegramChatRef, {
        status: TelegramChatStatus.CLOSED,
        updatedAt: Timestamp.now(),
      }, { merge: true }).catch(() => { });
    },
    [telegramChatRef],
  );

  const onMarkAsRead = useCallback(
    () => {
      setDoc(telegramChatRef, {
        unreadMessagesCount: 0,
        updatedAt: Timestamp.now(),
      }, { merge: true }).catch(() => { });
    },
    [telegramChatRef],
  );

  const isDisabled = useMemo(
    () => telegramChatDoc?.myChatMember?.status && ['kicked', 'left'].includes(telegramChatDoc.myChatMember.status),
    [telegramChatDoc?.myChatMember?.status],
  );

  const { isOpen: isTripsOpen, onClose: onTripsClose, onOpen: onTripsOpen } = useDisclosure();

  const tripsCollectionRef = useTripsCollectionRef();
  const { snap: tripsSnap } = useQuerySnapshot(
    query(
      tripsCollectionRef,
      where('status', '==', TripStatus.PUBLISHED),
    ),
  );

  const [language, setLanguage] = useState<AppLanguage>('en');

  const telegramSendTrip = useTelegramSendTrip();
  const handleTripClick = useCallback(
    async (tripSnap: QueryDocumentSnapshot<TripDoc>) => {
      await telegramSendTrip({
        language,
        telegramChatId: telegramChatRef.id,
        tripId: tripSnap.id,
      });
    },
    [language, telegramChatRef.id, telegramSendTrip],
  );

  const [isSendingInterviewInvitation, setSendingInterviewInvitation] = useState<boolean>(false);

  const telegramSendInterviewInvitation = useTelegramSendInterviewInvitation();
  const handlePremiumUserVerificationClick = useCallback(
    () => {
      setSendingInterviewInvitation(true);
      telegramSendInterviewInvitation({
        telegramChatId: telegramChatRef.id,
      })
        .finally(() => setSendingInterviewInvitation(false))
        .catch(showError);
    },
    [showError, telegramChatRef.id, telegramSendInterviewInvitation],
  );

  const telegramSendConsultationInvitation = useTelegramSendConsultationInvitation();
  const handleSponsorshipClick = useCallback(
    () => {
      setSendingInterviewInvitation(true);
      telegramSendConsultationInvitation({
        telegramChatId: telegramChatRef.id,
      })
        .finally(() => setSendingInterviewInvitation(false))
        .catch(showError);
    },
    [showError, telegramChatRef.id, telegramSendConsultationInvitation],
  );

  return (
    <VStack
      alignItems="stretch"
      flex={1}
      gap={0}
      minH={0}
    >
      <HStack flexGrow={0} flexShrink={0} h={12} p={2}>
        {telegramChatDoc?.status !== TelegramChatStatus.CLOSED ? (
          <Button
            colorScheme="red"
            onClick={onClose}
            size="sm"
          >
            Close
          </Button>
        ) : (
          <Button
            colorScheme="green"
            onClick={onOpen}
            size="sm"
          >
            Reopen
          </Button>
        )}

        <Button
          isDisabled={!telegramChatDoc?.unreadMessagesCount}
          onClick={onMarkAsRead}
          size="sm"
        >
          Mark as read
        </Button>

        <Spacer />

        {telegramChatDoc?.telegramChat?.type === 'private' ? (
          <Profile
            size="sm"
            telegramUserRef={doc(telegramUsersCollectionRef, String(telegramChatRef.id))}
          />
        ) : null}

        {
          (
            telegramChatDoc?.telegramChat?.type === 'group'
            || telegramChatDoc?.telegramChat?.type === 'supergroup'
          )
            ? (
              <ChatMembers
                size="sm"
                telegramChatRef={telegramChatRef}
              />
            )
            : null
        }
      </HStack>

      <VStack
        alignItems="stretch"
        flex={1}
        flexDirection="column-reverse"
        gap={1}
        minH={0}
        overflow="auto"
        p={2}
      >
        {telegramMessagesSnap?.docs.map(
          (messageSnap) => (
            <MessageRow
              key={messageSnap.id}
              telegramMessageSnap={messageSnap}
            />
          ),
        )}
      </VStack>

      <HStack p={2}>
        <Textarea
          flex={1}
          height="100%"
          isDisabled={isDisabled}
          onChange={handleChange}
          resize="none"
          value={value}
        />

        <Input
          accept="image/*"
          id="photo"
          onChange={handlePhotoChange}
          ref={inputPhoto}
          style={{ display: 'none' }}
          type="file"
        />

        <Modal isOpen={isTripsOpen} onClose={onTripsClose} scrollBehavior="inside">
          <ModalOverlay />

          <ModalContent
            mx={4}
          >
            <ModalCloseButton />

            <ModalHeader>
              Pick Trip
            </ModalHeader>

            <ModalBody>
              <Select onChange={(e) => setLanguage(e.target.value as AppLanguage)} value={language}>
                <option value="en">English</option>
                <option value="uk">Ukrainian</option>
                <option value="ru">Russian</option>
              </Select>

              <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
                {(tripsSnap?.docs ?? []).map((tripSnap) => (
                  <AspectRatio
                    key={tripSnap.id}
                    ratio={9 / 16}
                  >
                    <Trip
                      onClick={handleTripClick}
                      tripSnap={tripSnap}
                    />
                  </AspectRatio>
                ))}
              </Grid>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onTripsClose} variant="ghost">
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Grid gap={2} templateColumns="repeat(3, 1fr)" templateRows="repeat(3, 1fr)">
          <GridItem>
            <IconButton
              aria-label="Upload"
              icon={<Icon as={LuImage} />}
              isDisabled={isDisabled}
              isLoading={sendingImage}
              onClick={() => inputPhoto.current?.click()}
            />
          </GridItem>

          <GridItem>
            <IconButton
              aria-label="Upload"
              icon={<Icon as={LuFilm} />}
              isDisabled
            />
          </GridItem>

          <GridItem>
            <IconButton
              aria-label="Upload"
              icon={<Icon as={LuFile} />}
              isDisabled
            />
          </GridItem>

          <GridItem>
            <IconButton
              aria-label="Send Trip"
              icon={<Icon as={LuPlane} />}
              isDisabled={isDisabled}
              onClick={onTripsOpen}
            />
          </GridItem>

          <GridItem>
            <IconButton
              aria-label="Send Venture"
              icon={<Icon as={LuVenetianMask} />}
              isDisabled
            />
          </GridItem>

          <GridItem>
            <Menu>
              <MenuButton
                aria-label="Send Interview Invitation"
                as={IconButton}
                icon={<Icon as={LuCalendar} />}
                isDisabled={isDisabled}
                isLoading={isSendingInterviewInvitation}
              />

              <MenuList>
                <MenuItem onClick={handlePremiumUserVerificationClick}>
                  Interview
                </MenuItem>

                <MenuItem onClick={handleSponsorshipClick}>
                  Sales
                </MenuItem>
              </MenuList>
            </Menu>
          </GridItem>

          <GridItem colSpan={3}>
            <Button
              colorScheme="blue"
              isDisabled={isDisabled}
              isLoading={isSending}
              leftIcon={<Icon as={LuSend} />}
              loadingText="Sending"
              onClick={handleSubmit}
              width="100%"
            >
              Send
            </Button>
          </GridItem>
        </Grid>
      </HStack>
    </VStack>
  );
}

export default function ConversationFragment(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ConversationFragmentMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
