import {
  Button,
  ButtonGroup,
  Grid,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {
  orderBy,
  query,
  QueryDocumentSnapshot,
  Timestamp,
  where,
} from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { InterviewDoc, useInterviewsCollectionRef } from '../../../common/collections/Interviews';
import Catch from '../../../components/Catch';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import InterviewRow, { InterviewRowLoading } from './InterviewRow';

export function InterviewListLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" h="100%" templateColumns="repeat(2, 1fr)">
      <InterviewRowLoading />
      <InterviewRowLoading />
      <InterviewRowLoading />
      <InterviewRowLoading />
    </Grid>
  );
}

export function InterviewListMain() {
  const interviewsCollectionRef = useInterviewsCollectionRef();

  const navigate = useNavigate();
  const handleClick = useCallback(
    (interviewSnap: QueryDocumentSnapshot<InterviewDoc>) => {
      navigate(`/admin/interviews/${interviewSnap.id}`);
    },
    [navigate],
  );

  const [status, setStatus] = useState<string>('future');

  const onPastClick = useCallback(
    () => { setStatus('past'); },
    [],
  );

  const onFutureClick = useCallback(
    () => { setStatus('future'); },
    [],
  );

  const currentTime = useCurrentTime(1000 * 60);

  const q = useMemo(
    () => {
      switch (status) {
        case 'past': {
          return query(
            interviewsCollectionRef,
            where('scheduledAt', '<', Timestamp.fromDate(currentTime)),
            orderBy('scheduledAt', 'desc'),
          );
        }
        case 'future':
        default: {
          return query(
            interviewsCollectionRef,
            where('scheduledAt', '>', Timestamp.fromDate(currentTime)),
            orderBy('scheduledAt', 'asc'),
          );
        }
      }
    },
    [currentTime, interviewsCollectionRef, status],
  );

  const { snap: interviewsSnap } = useQuerySnapshot(q);

  if (interviewsSnap?.docs.length) {
    return (
      <VStack alignItems="stretch" gap={0} h="100%">
        <HStack flexGrow={0} flexShrink={0} h={12} justifyContent="space-between" p={2}>
          <ButtonGroup isAttached size="sm" variant="outline">
            <Button isActive={status === 'past'} onClick={onPastClick}>
              Past
            </Button>
            <Button isActive={status === 'future'} onClick={onFutureClick}>
              Future
            </Button>
          </ButtonGroup>
        </HStack>

        <VStack alignItems="stretch" gap={2} overflow="auto" p={2}>
          {interviewsSnap.docs.map((interviewSnap) => (
            <InterviewRow
              interviewSnap={interviewSnap}
              key={interviewSnap.id}
              onClick={handleClick}
            />
          ))}
        </VStack>
      </VStack>
    );
  }

  return (
    <InterviewListLoading />
  );
}

export default function InterviewList() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<InterviewListLoading />}>
        <InterviewListMain />
      </Suspense>
    </Catch>
  );
}
