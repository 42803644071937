import {
  Button,
  ButtonGroup,
  Center,
  Grid,
  GridItem,
  HStack,
  VStack,
} from '@chakra-ui/react';
import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Suspense, useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';

import {
  TelegramChatStatus,
  useTelegramChatsCollectionRef,
} from '../../../common/collections/TelegramChats';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import TelegramChatRow from './TelegramChatRow';

export function AdminTelegramChatsScreenMain() {
  const telegramChatsCollectionRef = useTelegramChatsCollectionRef();

  const [status, setStatus] = useState<TelegramChatStatus>(TelegramChatStatus.OPEN);

  const onOpenClick = useCallback(
    () => { setStatus(TelegramChatStatus.OPEN); },
    [],
  );

  const onClosedClick = useCallback(
    () => { setStatus(TelegramChatStatus.CLOSED); },
    [],
  );

  const { snap: telegramChatsSnap } = useQuerySnapshot(
    query(
      telegramChatsCollectionRef,
      where('status', '==', status),
      orderBy('lastMessageAt', 'desc'),
      limit(200),
    ),
  );

  return (
    <Grid columnGap={4} h="100%" templateColumns="repeat(3, 1fr)">
      <GridItem minH={0} minW={0}>
        <VStack alignItems="stretch" gap={0} h="100%">
          <HStack flexGrow={0} flexShrink={0} h={12} justifyContent="space-between" p={2}>
            <ButtonGroup isAttached size="sm" variant="outline">
              <Button isActive={status === TelegramChatStatus.OPEN} onClick={onOpenClick}>
                Open
              </Button>
              <Button isActive={status === TelegramChatStatus.CLOSED} onClick={onClosedClick}>
                Closed
              </Button>
            </ButtonGroup>
          </HStack>

          <VStack alignItems="stretch" gap={2} overflow="auto" p={2}>
            {telegramChatsSnap?.docs.map(
              (conversationSnap) => (
                <TelegramChatRow
                  key={conversationSnap.id}
                  telegramChatSnap={conversationSnap}
                />
              ),
            )}
          </VStack>
        </VStack>
      </GridItem>

      <Outlet />
    </Grid>
  );
}

export default function AdminTelegramChatsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <AdminTelegramChatsScreenMain />
      </Suspense>
    </Catch>
  );
}
