import { Center, useDisclosure } from '@chakra-ui/react';
import { orderBy, query } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getProfileVersionsCollectionRef,
  ProfileVersionStatus,
} from '../../common/collections/Profiles';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import ProfileForm from './ProfileForm';
import ProfileVersionAbsent from './ProfileVersionAbsent';
import ProfileVersionApproved from './ProfileVersionApproved';
import ProfileVersionReceived from './ProfileVersionReceived';
import ProfileVersionRejected from './ProfileVersionRejected';

export function WelcomeScreenMain() {
  const myProfileRef = useMyProfileRef();

  const { snap: profileVersionsSnap } = useQuerySnapshot(
    query(
      getProfileVersionsCollectionRef(myProfileRef),
      orderBy('sentAt', 'desc'),
    ),
  );

  const lastProfileVersionSnap = useMemo(
    () => (profileVersionsSnap?.docs.length
      ? profileVersionsSnap.docs[0]
      : undefined),
    [profileVersionsSnap?.docs],
  );

  const { isOpen, onClose, onOpen } = useDisclosure();

  const handleApplicationExit = useCallback(
    () => {
      mixpanel.track('Membership Application Exited');
      onClose();
    },
    [onClose],
  );

  const handleApplyClick = useCallback(
    () => {
      mixpanel.track('Membership Apply Clicked');
      onOpen();
    },
    [onOpen],
  );

  const handleReapplyClick = useCallback(
    () => {
      mixpanel.track('Membership Reapply Clicked');
      onOpen();
    },
    [onOpen],
  );

  const navigate = useNavigate();
  const handleContinueClick = useCallback(
    () => {
      mixpanel.track('Membership Finalize Clicked');
      navigate('/');
    },
    [navigate],
  );

  if (isOpen) {
    return (
      <ProfileForm
        lastProfileVersionSnap={lastProfileVersionSnap}
        onBack={handleApplicationExit}
      />
    );
  }

  if (lastProfileVersionSnap?.data()?.status === ProfileVersionStatus.APPROVED) {
    return (
      <ProfileVersionApproved
        lastProfileVersionSnap={lastProfileVersionSnap}
        onContinueClick={handleContinueClick}
      />
    );
  }

  if (lastProfileVersionSnap?.data()?.status === ProfileVersionStatus.SENT) {
    return (
      <ProfileVersionReceived
        lastProfileVersionSnap={lastProfileVersionSnap}
      />
    );
  }

  if (lastProfileVersionSnap?.data()?.status === ProfileVersionStatus.REJECTED) {
    return (
      <ProfileVersionRejected
        lastProfileVersionSnap={lastProfileVersionSnap}
        onReapplyClick={handleReapplyClick}
      />
    );
  }

  return (
    <ProfileVersionAbsent
      onApplyClick={handleApplyClick}
    />
  );
}

export default function WelcomeScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <WelcomeScreenMain />
      </Suspense>
    </Catch>
  );
}
