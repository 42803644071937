import {
  HStack,
  Skeleton,
  Text,
  useToken,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference, DocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { Suspense, useMemo } from 'react';

import { CityDoc } from '../../common/collections/Cities';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';
import Catch from '../Catch';

export type PropsMain = {
  citySnap: DocumentSnapshot<CityDoc>;
};

export function CityRowMain({ citySnap }: PropsMain) {
  if (!citySnap.exists()) {
    throw new Error('City does not exist');
  }

  const cityDoc = useMemo(() => citySnap.data(), [citySnap]);

  const [mono] = useToken('fonts', ['mono']);

  return (
    <HStack flex={1} spacing={4} userSelect="none">
      <Text fontFamily={mono} fontSize="4xl" lineHeight={1}>
        {citySnap.id}
      </Text>

      <VStack alignItems="stretch" flex={1} spacing={1}>
        <Text fontSize="sm" lineHeight={1}>
          {cityDoc.name}
        </Text>
        <Text fontSize="xs" lineHeight={1}>
          {_.compact([cityDoc.countryEmoji, cityDoc.countryName]).join(' ')}
        </Text>
      </VStack>
    </HStack>
  );
}

export function CityRowLoading() {
  return (
    <HStack spacing={4} width="100%">
      <Skeleton h="30px" w="60px" />

      <VStack alignItems="stretch" flex={1} spacing={1}>
        <Skeleton h="14px" w="100%" />
        <Skeleton h="12px" w="100%" />
      </VStack>
    </HStack>
  );
}

export type Props = {
  cityRef: DocumentReference<CityDoc>;
};

export default function CityRow({ cityRef }: Props) {
  const { snap: citySnap } = useDocumentSnapshot(cityRef);

  if (!citySnap) {
    return (
      <CityRowLoading />
    );
  }

  return (
    <Catch fallback={null}>
      <Suspense fallback={<CityRowLoading />}>
        <CityRowMain
          citySnap={citySnap}
        />
      </Suspense>
    </Catch>
  );
}
