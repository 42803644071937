import {
  AspectRatio,
  Box,
  Container,
  Grid,
  GridItem,
  HStack,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileVersionDoc } from '../../common/collections/Profiles';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import PictureImage from '../../components/PictureImage';
import { TelegramMainButton } from '../../components/TelegramMainButton';
import useShowError from '../../hooks/useShowError';

export type Props = {
  onComplete: (data: ProfileVersionDoc) => Promise<void>;
  profileVersionDoc: ProfileVersionDoc;
};

export function ConfirmationFormMain({
  onComplete,
  profileVersionDoc,
}: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });
  const { t: gt } = useTranslation('Gender');

  const insets = useInsets();

  const [sending, setSending] = useState(false);
  const showError = useShowError();
  const handleSubmitClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
      setSending(true);

      onComplete(profileVersionDoc)
        .finally(() => setSending(false))
        .catch(showError);
    },
    [profileVersionDoc, onComplete, showError],
  );

  const age = useMemo(() => {
    const birthDate = moment.utc(profileVersionDoc.birthDate).startOf('day');

    return Math.floor(
      moment.duration(
        moment().diff(birthDate),
      ).asYears(),
    );
  }, [profileVersionDoc.birthDate]);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        flexShrink={0}
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-4))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        minH={0}
        overflow="auto"
        py={2}
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
        >
          <Grid
            autoRows="1fr"
            gap={2}
            templateColumns="repeat(3, 1fr)"
          >
            {profileVersionDoc.pictureRefs.map((pictureRef) => (
              <GridItem key={pictureRef.id}>
                <AspectRatio ratio={9 / 16}>
                  <PictureImage
                    borderRadius="md"
                    cursor="pointer"
                    h="100%"
                    objectFit="cover"
                    pictureRef={pictureRef}
                  />
                </AspectRatio>
              </GridItem>
            ))}

            {new Array(Math.ceil(
              profileVersionDoc.pictureRefs.length / 3,
            ) * 3 - profileVersionDoc.pictureRefs.length)
              .fill(null)
              .map((_, i) => (
                <Box
                  _dark={{
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                  }}
                  _light={{
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  }}
                  borderRadius="md"
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                />
              ))}
          </Grid>

          <Text fontSize="xl" fontWeight="bold" textAlign="center">
            {profileVersionDoc.name}
          </Text>

          <Text textAlign="center">
            {gt(profileVersionDoc.gender)}
            {', '}
            {age}
          </Text>
        </VStack>
      </Container>

      <TelegramMainButton
        color="#9C27B0"
        hasShineEffect
        isLoading={sending}
        onClick={handleSubmitClick}
        text={t('sendButton.default')}
        textColor="#FFFFFF"
      />
    </VStack>
  );
}

export default function ConfirmationForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <ConfirmationFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
