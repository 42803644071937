import {
  Center,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';
import { Suspense, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { JobDoc } from '../../../common/collections/Jobs';
import Catch from '../../../components/Catch';
import { useInsets } from '../../../components/InsetsProvider';
import LogoFull from '../../../components/LogoFull';
import LogoIcon from '../../../components/LogoIcon';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import JobForm from './JobForm';

export function JobCreateScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const insets = useInsets();

  const onComplete = useCallback(
    (jobRef: DocumentReference<JobDoc>) => {
      navigate(`/vendor/jobs/${jobRef.id}`);
    },
    [navigate],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <JobForm
          onComplete={onComplete}
        />
      </Container>
    </VStack>
  );
}

export default function JobCreateScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <JobCreateScreenMain />
      </Suspense>
    </Catch>
  );
}
