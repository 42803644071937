import { ChatMember } from '@grammyjs/types';
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  refEqual,
  Timestamp,
} from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';

import { type TelegramChatDoc } from './TelegramChats';

export type TelegramChatMemberDoc = {
  _v: 1;
  createdAt: Timestamp;
  telegramChatMember: ChatMember;
  updatedAt: Timestamp;
};

export const isTelegramChatMemberDoc = (
  doc?: DocumentData,
): doc is TelegramChatMemberDoc => true;

export const isTelegramChatMemberRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TelegramChatMemberDoc> => ref.parent.id === 'telegramChatMembers';

function isTelegramChatMemberSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TelegramChatMemberDoc>;
function isTelegramChatMemberSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TelegramChatMemberDoc>;
function isTelegramChatMemberSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TelegramChatMemberDoc> | QueryDocumentSnapshot<TelegramChatMemberDoc> {
  return isTelegramChatMemberRef(snap.ref);
}

export { isTelegramChatMemberSnap };

export const getTelegramChatMembersCollectionRef = (telegramChatRef: DocumentReference<TelegramChatDoc>) => collection(telegramChatRef, 'telegramChatMembers') as CollectionReference<TelegramChatMemberDoc>;

export const useTelegramChatMembersCollectionRef = (
  telegramChatRef: DocumentReference<TelegramChatDoc>,
) => {
  const [nextTelegramChatRef, setNextTelegramChatRef] = useState(telegramChatRef);

  useEffect(
    () => {
      if (!refEqual(telegramChatRef, nextTelegramChatRef)) {
        setNextTelegramChatRef(telegramChatRef);
      }
    },
    [telegramChatRef, nextTelegramChatRef],
  );

  return useMemo(
    () => getTelegramChatMembersCollectionRef(nextTelegramChatRef),
    [nextTelegramChatRef],
  );
};
