import {
  AspectRatio,
  Center,
  Grid,
  Text,
} from '@chakra-ui/react';
import {
  collectionGroup,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  where,
} from 'firebase/firestore';
import { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  isProfileRef,
  ProfileVersionDoc,
  ProfileVersionStatus,
} from '../../../common/collections/Profiles';
import Catch from '../../../components/Catch';
import { useFirestore } from '../../../components/FirestoreProvider';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import ProfileVersionCard, { ProfileVersionCardLoading } from './ProfileVersionCard';

export function ProfileVersionGridLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" h="100%" templateColumns="repeat(2, 1fr)">
      <ProfileVersionCardLoading />
      <ProfileVersionCardLoading />
      <ProfileVersionCardLoading />
      <ProfileVersionCardLoading />
    </Grid>
  );
}

export function ProfileVersionGridMain() {
  const firestore = useFirestore();

  const { snap: profileVersionsSnap } = useQuerySnapshot(
    query(
      collectionGroup(firestore, 'profileVersions') as Query<ProfileVersionDoc>,
      where('status', '==', ProfileVersionStatus.SENT),
      orderBy('sentAt', 'desc'),
      limit(200),
    ),
  );

  const navigate = useNavigate();
  const handleClick = useCallback(
    (profileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>) => {
      const profileRef = profileVersionSnap.ref.parent.parent;

      if (!profileRef || !isProfileRef(profileRef)) {
        return;
      }

      navigate(`/admin/profileVersions/${profileRef.id}/${profileVersionSnap.id}`);
    },
    [navigate],
  );

  if (profileVersionsSnap?.docs.length) {
    return (
      <Grid gap={2} gridAutoRows="1fr" p={2} templateColumns="repeat(3, 1fr)">
        {profileVersionsSnap.docs.map((profileVersionSnap) => (
          <AspectRatio key={profileVersionSnap.id} ratio={9 / 16}>
            <ProfileVersionCard
              onClick={handleClick}
              profileVersionSnap={profileVersionSnap}
            />
          </AspectRatio>
        ))}
      </Grid>
    );
  }

  if (profileVersionsSnap) {
    return (
      <Center height="100%">
        <Text textAlign="center">
          No Profile Versions
        </Text>
      </Center>
    );
  }

  return (
    <ProfileVersionGridLoading />
  );
}

export default function ProfileVersionGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ProfileVersionGridLoading />}>
        <ProfileVersionGridMain />
      </Suspense>
    </Catch>
  );
}
