import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { KeyStatus, useKeysCollectionRef } from '../../common/collections/Keys';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import KeyRow, { KeyRowLoading } from './KeyRow';

export function KeyGridMain() {
  const { t } = useTranslation('TheirKeysScreen', { keyPrefix: 'KeyList' });

  const keysCollectionRef = useKeysCollectionRef();
  const { error, snap: keysSnap } = useQuerySnapshot(
    query(
      keysCollectionRef,
      where('status', '==', KeyStatus.PUBLISHED),
      orderBy('startsAt', 'asc'),
      limit(100),
    ),
  );

  if (error) {
    throw error;
  }

  if (!keysSnap) {
    return (
      <Container height="100%" maxW="lg">
        <Center height="100%">
          <LogoIcon boxSize={16} />
        </Center>
      </Container>
    );
  }

  if (keysSnap.docs.length) {
    return (
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
        {keysSnap.docs.map((keySnap) => (
          <AspectRatio
            key={keySnap.id}
            ratio={9 / 16}
          >
            <KeyRow
              keySnap={keySnap}
              scrollSnapAlign="center"
            />
          </AspectRatio>
        ))}

        {new Array(Math.ceil(keysSnap.docs.length / 2) * 2 - keysSnap.docs.length)
          .fill(null)
          .map((_, i) => (
            <Box
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              borderRadius="md"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
      </Grid>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export function KeyGridLoading() {
  return (
    <VStack alignItems="stretch" className="myKeysList" gap={8}>
      <VStack alignItems="stretch" gap={2}>
        <Skeleton h={5} />

        <Grid gap={4} gridAutoRows="1fr" templateColumns="repeat(2, 1fr)">
          <KeyRowLoading />
          <KeyRowLoading />
          <KeyRowLoading />
          <KeyRowLoading />
        </Grid>
      </VStack>
    </VStack>
  );
}

export default function KeyGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<KeyGridLoading />}>
        <KeyGridMain />
      </Suspense>
    </Catch>
  );
}
