import { Card, CardBody, Text } from '@chakra-ui/react';
import { Message } from '@grammyjs/types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export type Props = {
  telegramMessage: Message.MigrateFromChatIdMessage;
};

export default function MigrateFromChatIdMessageRow({ telegramMessage }: Props) {
  const navigate = useNavigate();

  const onClick = useCallback(
    () => navigate(`/admin/telegramChats/${String(telegramMessage.migrate_from_chat_id)}`),
    [navigate, telegramMessage.migrate_from_chat_id],
  );

  return (
    <Card alignSelf="center" fontSize="sm" maxW="sm" onClick={onClick} variant="elevated">
      <CardBody p={2}>
        <Text> Migrated from chat </Text>
      </CardBody>
    </Card>
  );
}
