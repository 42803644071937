import {
  Avatar,
  BoxProps,
  Button,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { compact, find } from 'lodash';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { LuBadgeCheck, LuUser } from 'react-icons/lu';
import { useMatch } from 'react-router-dom';

import { InterviewDoc } from '../../../common/collections/Interviews';
import countryCodes from '../../../common/countryCodes';
import Catch from '../../../components/Catch';
import PictureAvatar from '../../../components/PictureAvatar';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';

export type Props = {
  interviewSnap: QueryDocumentSnapshot<InterviewDoc>;
  onClick: (interviewSnap: QueryDocumentSnapshot<InterviewDoc>) => void;
} & Omit<BoxProps, 'onClick'>;

export function InterviewRowMain({
  interviewSnap,
  onClick,
  ...boxProps
}: Props) {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick(interviewSnap);
    },
    [onClick, interviewSnap],
  );

  const interviewDoc = useMemo(() => interviewSnap.data(), [interviewSnap]);

  const { snap: profileSnap } = useDocumentSnapshot(interviewDoc.profileRef);

  const profileDoc = useMemo(() => profileSnap?.data(), [profileSnap]);

  const { snap: originSnap } = useDocumentSnapshot(profileDoc?.originRef);

  const originDoc = useMemo(() => originSnap?.data(), [originSnap]);

  const currentTime = useCurrentTime(1000 * 60);

  const match = useMatch(`/admin/interviews/${interviewSnap.id}/*`);

  const getEmoji = useCallback(
    (code?: string) => {
      if (!code) {
        return code;
      }
      return find(countryCodes, (data) => data.code === code)?.emoji ?? code;
    },
    [],
  );

  return (
    <HStack
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      _hover={{ bg: 'rgba(0, 0, 0, 0.05)' }}
      as="button"
      bg={match ? 'rgba(0, 0, 0, 0.1)' : undefined}
      borderRadius="md"
      gap={2}
      onClick={handleClick}
      padding={2}
      transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
    >
      {profileDoc ? (
        <PictureAvatar
          icon={<Icon as={LuUser} />}
          loading="lazy"
          pictureRef={profileDoc.pictureRefs[0]}
        />
      ) : (
        <Avatar
          icon={<Icon as={LuUser} />}
        />
      )}

      <VStack alignItems="stretch" flexGrow={1} flexShrink={1} gap={0} minW={0}>
        <HStack gap={1}>
          {profileDoc?.isVerified ? (
            <Icon as={LuBadgeCheck} color="blue.500" />
          ) : false}

          {profileDoc ? (
            <Text
              flexGrow={1}
              flexShrink={1}
              overflow="hidden"
              textAlign="left"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {profileDoc.name}
              {', '}
              {profileDoc.age}
              {' '}
              {compact([getEmoji(originDoc?.countryRef?.id), originDoc?.name]).join(' ')}
            </Text>
          ) : null}
        </HStack>

        <Text fontSize="sm" textAlign="left">
          {moment(interviewDoc.scheduledAt.toDate()).calendar(currentTime)}
        </Text>
      </VStack>

      <Button
        as="a"
        colorScheme="blue"
        href={interviewDoc.meetingUrl}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        variant="outline"
      >
        Join
      </Button>
    </HStack>
  );
}

export function InterviewRowLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export default function InterviewRow({ interviewSnap, onClick, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <InterviewRowLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <InterviewRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          interviewSnap={interviewSnap}
          onClick={onClick}
        />
      </Suspense>
    </Catch>
  );
}
