import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

import { useFunctions } from '../components/FunctionsProvider';

export type Data = {
  initData: string;
};

export type Result = string;

export default function useTelegramCreateCustomToken() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-createCustomToken'), [functions]);
}
