import {
  AspectRatio,
  Center,
  Image,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { TelegramUserDoc } from '../../../common/collections/TelegramUsers';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import { useStorage } from '../../../components/StorageProvider';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';

export type Props = {
  telegramUserSnap: QueryDocumentSnapshot<TelegramUserDoc>;
};

export function TelegramUserMain({ telegramUserSnap }: Props) {
  const telegramUserDoc = useMemo(() => telegramUserSnap.data(), [telegramUserSnap]);

  const [url, setUrl] = useState<string>();
  const storage = useStorage();
  useEffect(
    () => {
      if (telegramUserDoc.pictureStorageUri) {
        getDownloadURL(ref(storage, telegramUserDoc.pictureStorageUri))
          .then(setUrl)
          .catch(() => { });
      }
    },
    [storage, telegramUserDoc.pictureStorageUri],
  );

  return (
    <VStack alignItems="stretch" overflow="auto">
      {url ? (
        <AspectRatio ratio={1}>
          <Image
            borderRadius="md"
            fit="cover"
            h="100%"
            src={url}
          />
        </AspectRatio>
      ) : null}

      <Table size="sm" variant="simple">
        <Tbody>
          <Tr>
            <Td>
              Telegram User ID
            </Td>
            <Td>
              {telegramUserSnap.id}
            </Td>
          </Tr>
          {telegramUserDoc.uid ? (
            <Tr>
              <Td>
                Profile ID
              </Td>
              <Td>
                {telegramUserDoc.uid}
              </Td>
            </Tr>
          ) : null}
          <Tr>
            <Td>
              Name
            </Td>
            <Td>
              {telegramUserDoc.telegramUser?.first_name}
              {' '}
              {telegramUserDoc.telegramUser?.last_name}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Username
            </Td>
            <Td>
              @
              {telegramUserDoc.telegramUser?.username}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Language
            </Td>
            <Td>
              {telegramUserDoc.telegramUser?.language_code}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Premium
            </Td>
            <Td>
              {telegramUserDoc.telegramUser?.is_premium ? 'True' : 'False'}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </VStack>
  );
}

export default function TelegramUser(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <TelegramUserMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
