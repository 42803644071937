import { Box, Grid } from '@chakra-ui/react';
import {
  getDocs,
  limit,
  orderBy,
  query,
  QueryDocumentSnapshot,
  startAfter,
} from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AutoSizer,
  GridCellProps,
  Index,
  IndexRange,
  InfiniteLoader,
  Grid as VirtualizedGrid,
} from 'react-virtualized';

import { ProfileDoc, useProfilesCollectionRef } from '../../../common/collections/Profiles';
import Catch from '../../../components/Catch';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import ProfileCard, { ProfileCardLoading } from './ProfileCard';

export function ProfileGridLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" h="100%" templateColumns="repeat(2, 1fr)">
      <ProfileCardLoading />
      <ProfileCardLoading />
      <ProfileCardLoading />
      <ProfileCardLoading />
    </Grid>
  );
}

export function ProfileGridMain() {
  const profilesCollectionRef = useProfilesCollectionRef();

  const [profileSnaps, setProfileSnaps] = useState<QueryDocumentSnapshot<ProfileDoc>[]>([]);

  useEffect(
    () => {
      getDocs(
        query(
          profilesCollectionRef,
          orderBy('createdAt', 'desc'),
          limit(40),
        ),
      )
        .then((snap) => setProfileSnaps(snap.docs))
        .catch(() => { });
    },
    [profilesCollectionRef],
  );

  const isRowLoaded = useCallback(
    // eslint-disable-next-line arrow-body-style
    ({ index }: Index) => {
      return profileSnaps.length > index * 2;
    },
    [profileSnaps.length],
  );

  const loadMoreRows = useCallback(
    async ({ startIndex, stopIndex }: IndexRange) => {
      const nextProfilesSnap = await getDocs(
        query(
          profilesCollectionRef,
          orderBy('createdAt', 'desc'),
          limit((stopIndex - startIndex + 1) * 2),
          startAfter(profileSnaps[profileSnaps.length - 1]),
        ),
      );

      setProfileSnaps([...profileSnaps, ...nextProfilesSnap.docs]);
    },
    [profileSnaps, profilesCollectionRef],
  );

  const navigate = useNavigate();
  const handleClick = useCallback(
    (profileSnap: QueryDocumentSnapshot<ProfileDoc>) => {
      navigate(`/admin/profiles/${profileSnap.id}`);
    },
    [navigate],
  );

  const renderCell = useCallback(
    ({
      columnIndex,
      key,
      rowIndex,
      style,
    }: GridCellProps) => {
      const profileSnap = profileSnaps[rowIndex * 2 + columnIndex];

      return (
        <Box key={key} p={2} style={style}>
          <ProfileCard
            onClick={handleClick}
            profileSnap={profileSnap}
            scrollSnapAlign="center"
          />
        </Box>
      );
    },
    [handleClick, profileSnaps],
  );

  if (profileSnaps.length) {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            minimumBatchSize={10}
            rowCount={10000}
          >
            {({ onRowsRendered, registerChild }) => (
              <VirtualizedGrid
                cellRenderer={renderCell}
                columnCount={2}
                columnWidth={width / 2}
                height={height}
                onSectionRendered={({
                  rowStartIndex: startIndex, rowStopIndex: stopIndex,
                }) => onRowsRendered({ startIndex, stopIndex })}
                overscanColumnCount={0}
                overscanRowCount={3}
                ref={registerChild}
                rowCount={profileSnaps.length / 2}
                rowHeight={(width / 18) * 16}
                width={width}
              />
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  }

  return (
    <ProfileGridLoading />
  );
}

export default function ProfileGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<ProfileGridLoading />}>
        <ProfileGridMain />
      </Suspense>
    </Catch>
  );
}
