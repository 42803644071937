import {
  doc,
  getDoc,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { clamp } from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  getProfileVersionsCollectionRef,
  ProfileVersionDoc,
  ProfileVersionStatus,
} from '../../common/collections/Profiles';
import Gender from '../../common/Gender';
import Sexuality from '../../common/Sexuality';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';
import BirthDateForm from './BirthDateForm';
import BodyForm from './BodyForm';
import ConfirmationForm from './ConfirmationForm';
import DescriptionForm from './DescriptionForm';
import FaceForm from './FaceForm';
import GenderForm from './GenderForm';
import GoalsForm from './GoalsForm';
import LanguagesForm from './LanguagesForm';
import NameForm from './NameForm';
import OriginForm from './OriginForm';
import PicturesForm from './PicturesForm';
import RelationshipStylesForm from './RelationshipStylesForm';
import SexualityForm from './SexualityForm';
import SocialForm from './SocialForm';
import WealthForm from './WealthForm';

export type Props = {
  lastProfileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc> | undefined;
  onBack: () => void;
};

export enum Step {
  GENDER,
  NAME,
  BIRTHDATE,
  GOALS,
  ORIGIN,
  LANGUAGES,
  PICTURES,
  BODY,
  FACE,
  SEXUALITY,
  RELATIONSHIP_STYLES,
  SOCIAL,
  WEALTH,
  CONFIRMATION,
  DESCRIPTION,
}

const femalePath = [
  Step.GENDER,
  Step.NAME,
  Step.BIRTHDATE,
  Step.GOALS,
  Step.SEXUALITY,
  Step.RELATIONSHIP_STYLES,
  Step.ORIGIN,
  Step.LANGUAGES,
  Step.BODY,
  Step.FACE,
  Step.PICTURES,
  Step.SOCIAL,
  Step.DESCRIPTION,
  Step.CONFIRMATION,
];

const malePath = [
  Step.GENDER,
  Step.NAME,
  Step.BIRTHDATE,
  Step.ORIGIN,
  Step.LANGUAGES,
  Step.WEALTH,
  Step.PICTURES,
  Step.SOCIAL,
  Step.DESCRIPTION,
  Step.CONFIRMATION,
];

export function ProfileFormMain({ lastProfileVersionSnap, onBack }: Props) {
  const [
    bodyFormRes,
    setBodyFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'cupSize' | 'height' | 'pantiesSize' | 'weight'>>();

  const [
    faceFormRes,
    setFaceFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'eyeColor' | 'hairColor' | 'hairLength'>>();

  const [
    genderFormRes,
    setGenderFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'gender'>>();

  const [
    goalsFormRes,
    setGoalsFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'goals'>>();

  const [
    nameFormRes,
    setNameFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'name'>>();

  const [
    birthDateFormRes,
    setBirthDateFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'birthDate'>>();

  const [
    originFormRes,
    setOriginFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'originRef'>>();

  const [
    languagesFormRes,
    setLanguagesFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'languages'>>();

  const [
    picturesFormRes,
    setPicturesFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'pictureRefs'>>();

  const [
    sexualityFormRes,
    setSexualityFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'sexuality'>>();

  const [
    relationshipStylesFormRes,
    setRelationshipStylesFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'relationshipStyles'>>();

  const [
    socialFormRes,
    setSocialFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'instagramTag' | 'linkedinTag' | 'tiktokTag'>>();

  const [
    descriptionFormRes,
    setDescriptionFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'description'>>();

  const [
    wealthFormRes,
    setWealthFormRes,
  ] = useState<Pick<ProfileVersionDoc, 'wealth'>>();

  const [step, setStep] = useState<Step>(Step.GENDER);

  const nextStep = useCallback((gender?: Gender) => {
    switch (gender ?? genderFormRes?.gender) {
      case Gender.FEMALE: {
        const index = femalePath.indexOf(step);
        setStep(femalePath[clamp(index + 1, 0, femalePath.length - 1)]);
        return;
      }
      case Gender.MALE: {
        const index = malePath.indexOf(step);
        setStep(malePath[clamp(index + 1, 0, malePath.length - 1)]);
        return;
      }
      default: setStep(Step.GENDER);
    }
  }, [genderFormRes?.gender, step]);

  const prevStep = useCallback(() => {
    switch (genderFormRes?.gender) {
      case Gender.FEMALE: {
        const index = femalePath.indexOf(step);

        if (index - 1 < 0) {
          onBack();
          return;
        }

        setStep(femalePath[clamp(index - 1, 0, femalePath.length - 1)]);
        return;
      }
      case Gender.MALE: {
        const index = malePath.indexOf(step);

        if (index - 1 < 0) {
          onBack();
          return;
        }

        setStep(malePath[clamp(index - 1, 0, malePath.length - 1)]);
        return;
      }
      default: {
        onBack();
      }
    }
  }, [genderFormRes?.gender, onBack, step]);

  useEffect(
    () => {
      window.Telegram.WebApp.BackButton.onClick(prevStep);

      return () => {
        window.Telegram.WebApp.BackButton.offClick(prevStep);
      };
    },
    [prevStep],
  );

  useEffect(
    () => {
      window.Telegram.WebApp.BackButton.show();
      window.Telegram.WebApp.enableClosingConfirmation();

      return () => {
        window.Telegram.WebApp.disableClosingConfirmation();
        window.Telegram.WebApp.BackButton.hide();
      };
    },
    [],
  );

  const myProfileRef = useMyProfileRef();

  const nextProfileVersionDoc = useMemo<ProfileVersionDoc | undefined>(
    () => {
      if (
        !genderFormRes
        || !nameFormRes
        || !birthDateFormRes
        || !originFormRes
        || !languagesFormRes
        || !picturesFormRes
        || !socialFormRes
        || !descriptionFormRes
      ) {
        return undefined;
      }

      const birthDate = moment.utc(birthDateFormRes.birthDate).startOf('day');

      const profileVersionDoc: ProfileVersionDoc = {
        _v: 1,
        birthDate: birthDate.format('YYYY-MM-DD'),
        description: descriptionFormRes.description,
        gender: genderFormRes.gender,
        instagramTag: socialFormRes.instagramTag,
        languages: languagesFormRes.languages,
        linkedinTag: socialFormRes.linkedinTag,
        name: nameFormRes.name,
        originRef: originFormRes.originRef,
        pictureRefs: picturesFormRes.pictureRefs,
        sentAt: Timestamp.now(),
        sexuality: Sexuality.STRAIGHT,
        status: ProfileVersionStatus.SENT,
        tiktokTag: socialFormRes.tiktokTag,
      };

      if (goalsFormRes) {
        profileVersionDoc.goals = goalsFormRes.goals;
      }

      if (sexualityFormRes) {
        profileVersionDoc.sexuality = sexualityFormRes.sexuality;
      }

      if (relationshipStylesFormRes) {
        profileVersionDoc.relationshipStyles = relationshipStylesFormRes.relationshipStyles;
      }

      if (bodyFormRes) {
        profileVersionDoc.cupSize = bodyFormRes.cupSize;
        profileVersionDoc.height = bodyFormRes.height;
        profileVersionDoc.pantiesSize = bodyFormRes.pantiesSize;
        profileVersionDoc.weight = bodyFormRes.weight;
      }

      if (faceFormRes) {
        profileVersionDoc.eyeColor = faceFormRes.eyeColor;
        profileVersionDoc.hairColor = faceFormRes.hairColor;
        profileVersionDoc.hairLength = faceFormRes.hairLength;
      }

      if (wealthFormRes) {
        profileVersionDoc.wealth = wealthFormRes.wealth;
      }

      return profileVersionDoc;
    },
    [
      birthDateFormRes,
      bodyFormRes,
      descriptionFormRes,
      faceFormRes,
      genderFormRes,
      goalsFormRes,
      languagesFormRes,
      nameFormRes,
      originFormRes,
      picturesFormRes,
      relationshipStylesFormRes,
      sexualityFormRes,
      socialFormRes,
      wealthFormRes,
    ],
  );

  const handleSendProfileVersion = useCallback(
    async (profileVersionDoc: ProfileVersionDoc) => {
      const originSnap = await getDoc(profileVersionDoc.originRef);
      const originDoc = originSnap.data();

      if (!originSnap.exists() || !originDoc) {
        throw new Error('City not found');
      }

      const profileVersionRef = doc(getProfileVersionsCollectionRef(myProfileRef));

      await setDoc(
        profileVersionRef,
        profileVersionDoc,
      );

      mixpanel.track('Membership Application Complete');

      onBack();
    },
    [myProfileRef, onBack],
  );

  const handleBodyFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'cupSize' | 'height' | 'pantiesSize' | 'weight'>) => {
      mixpanel.track('Membership Body Form Complete', data);
      setBodyFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleFaceFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'eyeColor' | 'hairColor' | 'hairLength'>) => {
      mixpanel.track('Membership Face Form Complete', data);
      setFaceFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleGenderFormComplete = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (data: Pick<ProfileVersionDoc, 'gender'>) => {
      mixpanel.track('Membership Gender Form Complete', data);
      setGenderFormRes(data);
      nextStep(data.gender);
    },
    [nextStep],
  );

  const handleGoalsFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'goals'>) => {
      mixpanel.track('Membership Goals Form Complete', data);
      setGoalsFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleNameFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'name'>) => {
      mixpanel.track('Membership Name Form Complete', data);
      setNameFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleBirthDateFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'birthDate'>) => {
      mixpanel.track('Membership Birth Date Form Complete', data);
      setBirthDateFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleOriginFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'originRef'>) => {
      mixpanel.track('Membership Origin Form Complete', { originId: data.originRef.id });
      setOriginFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleLanguagesFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'languages'>) => {
      mixpanel.track('Membership Languages Form Complete', data);
      setLanguagesFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handlePicturesFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'pictureRefs'>) => {
      mixpanel.track('Membership Pictures Form Complete');
      setPicturesFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleSexualityFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'sexuality'>) => {
      mixpanel.track('Membership Sexuality Form Complete');
      setSexualityFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleRelationshipStylesFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'relationshipStyles'>) => {
      mixpanel.track('Membership Relationship Styles Form Complete');
      setRelationshipStylesFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleSocialFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'instagramTag' | 'linkedinTag' | 'tiktokTag'>) => {
      mixpanel.track('Membership Social Form Complete');
      setSocialFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleDescriptionFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'description'>) => {
      mixpanel.track('Membership Description Form Complete');
      setDescriptionFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const handleWealthFormComplete = useCallback(
    (data: Pick<ProfileVersionDoc, 'wealth'>) => {
      mixpanel.track('Membership Wealth Form Complete');
      setWealthFormRes(data);
      nextStep();
    },
    [nextStep],
  );

  const lastProfileVersionDoc = useMemo(
    () => lastProfileVersionSnap?.data(),
    [lastProfileVersionSnap],
  );

  switch (step) {
    case Step.BODY: return (
      <BodyForm
        data={bodyFormRes ?? lastProfileVersionDoc}
        onComplete={handleBodyFormComplete}
      />
    );
    case Step.CONFIRMATION: {
      return nextProfileVersionDoc ? (
        <ConfirmationForm
          onComplete={handleSendProfileVersion}
          profileVersionDoc={nextProfileVersionDoc}
        />
      ) : null;
    }
    case Step.FACE: return (
      <FaceForm
        data={faceFormRes ?? lastProfileVersionDoc}
        onComplete={handleFaceFormComplete}
      />
    );
    case Step.GENDER: return (
      <GenderForm
        data={genderFormRes ?? lastProfileVersionDoc}
        onComplete={handleGenderFormComplete}
      />
    );
    case Step.GOALS: return (
      <GoalsForm
        data={goalsFormRes ?? lastProfileVersionDoc}
        onComplete={handleGoalsFormComplete}
      />
    );
    case Step.NAME: return (
      <NameForm
        data={nameFormRes ?? lastProfileVersionDoc}
        onComplete={handleNameFormComplete}
      />
    );
    case Step.BIRTHDATE: return (
      <BirthDateForm
        data={birthDateFormRes ?? lastProfileVersionDoc}
        onComplete={handleBirthDateFormComplete}
      />
    );
    case Step.ORIGIN: return (
      <OriginForm
        data={originFormRes ?? lastProfileVersionDoc}
        onComplete={handleOriginFormComplete}
      />
    );
    case Step.LANGUAGES: return (
      <LanguagesForm
        data={languagesFormRes ?? lastProfileVersionDoc}
        onComplete={handleLanguagesFormComplete}
      />
    );
    case Step.PICTURES: return (
      <PicturesForm
        data={picturesFormRes ?? lastProfileVersionDoc}
        onComplete={handlePicturesFormComplete}
      />
    );
    case Step.SEXUALITY: return (
      <SexualityForm
        data={sexualityFormRes ?? lastProfileVersionDoc}
        onComplete={handleSexualityFormComplete}
      />
    );
    case Step.RELATIONSHIP_STYLES: return (
      <RelationshipStylesForm
        data={relationshipStylesFormRes ?? lastProfileVersionDoc}
        onComplete={handleRelationshipStylesFormComplete}
      />
    );
    case Step.SOCIAL: return (
      <SocialForm
        data={socialFormRes ?? lastProfileVersionDoc}
        onComplete={handleSocialFormComplete}
      />
    );
    case Step.DESCRIPTION: return (
      <DescriptionForm
        data={descriptionFormRes ?? lastProfileVersionDoc}
        onComplete={handleDescriptionFormComplete}
      />
    );
    case Step.WEALTH: return (
      <WealthForm
        data={wealthFormRes ?? lastProfileVersionDoc}
        onComplete={handleWealthFormComplete}
      />
    );
    default: return null;
  }
}

export default function ProfileForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <ProfileFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
