import { insightsClient } from '@algolia/client-insights';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import algoliasearch from 'algoliasearch';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import { getAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import { getStorage } from 'firebase/storage';
import mixpanel from 'mixpanel-browser';
import { ReportHandler } from 'web-vitals';

import AlgoliaInsightsClientProvider from './components/AlgoliaInsightsClientProvider';
import AlgoliaSearchClientProvider from './components/AlgoliaSearchClientProvider';
import AnalyticsProvider from './components/AnalyticsProvider';
import AuthProvider from './components/AuthProvider';
import FirestoreProvider from './components/FirestoreProvider';
import FunctionsProvider from './components/FunctionsProvider';
import IPDataProvider from './components/IPDataProvider';
import StorageProvider from './components/StorageProvider';
import Navigation from './Navigation';
import theme from './theme';

Sentry.init({
  dsn: 'https://22dd56be421b384a329481b97b11b606@o4508630559948800.ingest.de.sentry.io/4508630562373712',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  tracesSampleRate: 1.0,
});

const firebaseApp = initializeApp({
  apiKey: 'AIzaSyDRLwoqAus2J6ieZR57TCkCIK7QULDXAz4',
  appId: '1:472996625527:web:fd8632165ade4bef55da72',
  authDomain: 'qupidu.firebaseapp.com',
  measurementId: 'G-F9MVVESRSR',
  messagingSenderId: '472996625527',
  projectId: 'qupidu',
  storageBucket: 'qupidu.appspot.com',
});

initializeAppCheck(firebaseApp, {
  isTokenAutoRefreshEnabled: true,
  provider: new ReCaptchaV3Provider('6Ld_ASUqAAAAAAwkG6OCJ_4glcZVg12C6yLQYcTd'),
});

mixpanel.init('e5ae96029741dcea4a6b690eecebe5e9', {
  cross_subdomain_cookie: true,
  persistence: 'localStorage',
  track_pageview: true,
});

const remoteConfigInstance = getRemoteConfig(firebaseApp);
remoteConfigInstance.settings.minimumFetchIntervalMillis = 1000 * 60 * 5;

const authInstance = getAuth(firebaseApp);
const analyticsInstance = getAnalytics(firebaseApp);
const functionsInstance = getFunctions(firebaseApp);
const storageInstance = getStorage(firebaseApp);

const firestoreInstance = initializeFirestore(firebaseApp, {
  ignoreUndefinedProperties: true,
  // localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
});

if (localStorage.getItem('chakra-ui-color-mode')) {
  localStorage.removeItem('chakra-ui-color-mode');
}

if (localStorage.getItem('i18nextLng')) {
  localStorage.removeItem('i18nextLng');
}

const algoliaSearchClient = algoliasearch('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');
const algoliaInsightsClient = insightsClient('G4ZEIPSJ7Z', '87b1a20fccf5600b3aff0754e664c2ce');

function App() {
  return (
    <IPDataProvider>
      <AlgoliaSearchClientProvider searchClient={algoliaSearchClient}>
        <AlgoliaInsightsClientProvider insightsClient={algoliaInsightsClient}>
          <AuthProvider sdk={authInstance}>
            <AnalyticsProvider sdk={analyticsInstance}>
              <FirestoreProvider sdk={firestoreInstance}>
                <FunctionsProvider sdk={functionsInstance}>
                  <StorageProvider sdk={storageInstance}>
                    <ChakraProvider theme={theme}>
                      <Navigation />
                    </ChakraProvider>
                  </StorageProvider>
                </FunctionsProvider>
              </FirestoreProvider>
            </AnalyticsProvider>
          </AuthProvider>
        </AlgoliaInsightsClientProvider>
      </AlgoliaSearchClientProvider>
    </IPDataProvider>
  );
}

const webVitalsReportHandler: ReportHandler = ({ delta, id, name }) => {
  logEvent(analyticsInstance, name, {
    eventAction: name,
    eventCategory: 'Web Vitals',
    eventLabel: id,
    eventValue: Math.round(name === 'CLS' ? delta * 1000 : delta),
    nonInteraction: true,
    transport: 'beacon',
  });
};

import('web-vitals').then(({
  getCLS, getFCP, getFID, getLCP, getTTFB,
}) => {
  getCLS(webVitalsReportHandler);
  getFID(webVitalsReportHandler);
  getFCP(webVitalsReportHandler);
  getLCP(webVitalsReportHandler);
  getTTFB(webVitalsReportHandler);
}).catch(() => { /* do nothing */ });

export default App;
