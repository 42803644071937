import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { compact } from 'lodash';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import AppLanguage from '../../common/AppLanguage';
import { useCitiesCollectionRef } from '../../common/collections/Cities';
import { usePicturesCollectionRef } from '../../common/collections/Picture';
import { TripDoc, TripStatus, useTripsCollectionRef } from '../../common/collections/Trips';
import CityFormControl from '../../components/CityFormControl';
import InstagramFormControl from '../../components/InstagramFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';

export type Props = {
  onComplete: (tripRef: DocumentReference<TripDoc>) => void;
};

export default function TripForm({ onComplete }: Props) {
  const { t } = useTranslation('TripCreateScreen', { keyPrefix: 'TripForm' });

  const translationSchema = useMemo(
    () => yup
      .object()
      .optional()
      .shape({
        description: yup
          .string()
          .label(t('description.label'))
          .optional(),
        name: yup
          .string()
          .label(t('name.label'))
          .optional(),
      }),
    [t],
  );

  const schema = useMemo(
    () => yup.object().shape({
      cityId: yup
        .string()
        .label(t('cityId.label'))
        .required(),
      description: yup
        .string()
        .label(t('description.label'))
        .required(),
      endsAt: yup
        .string()
        .label(t('endsAt.label'))
        .required(),
      femaleParticipantPlaces: yup
        .number()
        .min(1)
        .max(100)
        .label(t('femaleParticipantPlaces.label'))
        .required(),
      femaleParticipantPrice: yup
        .object()
        .shape({
          amount: yup
            .number()
            .min(0)
            .max(100000)
            .label(t('femaleParticipantPrice.amount.label'))
            .required(),
          currency: yup
            .string()
            .label(t('femaleParticipantPrice.currency.label'))
            .oneOf(['EUR', 'PLN', 'USD', 'UAH'])
            .required(),
        })
        .label(t('femaleParticipantPlaces.label'))
        .required(),
      femaleParticipantTicketCoverage: yup
        .number()
        .min(0)
        .max(100)
        .label(t('femaleParticipantTicketCoverage.label'))
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label')),
      maleParticipantPlaces: yup
        .number()
        .min(1)
        .max(100)
        .label(t('maleParticipantPlaces.label'))
        .required(),
      maleParticipantPrice: yup
        .object()
        .shape({
          amount: yup
            .number()
            .min(0)
            .max(100000)
            .label(t('maleParticipantPrice.amount.label'))
            .required(),
          currency: yup
            .string()
            .label(t('maleParticipantPrice.currency.label'))
            .oneOf(['EUR', 'PLN', 'USD', 'UAH'])
            .required(),
        })
        .label(t('maleParticipantPlaces.label'))
        .required(),
      name: yup
        .string()
        .label(t('name.label'))
        .required(),
      pictureIds: yup
        .array()
        .label(t('pictureIds.label'))
        .compact()
        .min(3)
        .max(9)
        .of(
          yup
            .string()
            .label(t('pictureIds.item.label'))
            .required(),
        )
        .required(),
      startsAt: yup
        .string()
        .label(t('startsAt.label'))
        .required(),
      translations: yup
        .object<Record<AppLanguage, Pick<TripDoc, 'description' | 'name'>>>()
        .label(t('translations.label'))
        .required()
        .shape({
          ru: translationSchema,
          uk: translationSchema,
        }),
    }),
    [t, translationSchema],
  );

  const myProfileSnap = useMyProfileSnap();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      cityId: 'DPS',
      description: '',
      endsAt: moment
        .utc()
        .add(1, 'month')
        .add(1, 'week')
        .set({
          hour: 18,
          millisecond: 0,
          minute: 0,
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss'),
      femaleParticipantPlaces: 1,
      femaleParticipantPrice: {
        amount: 0,
        currency: 'EUR',
      },
      femaleParticipantTicketCoverage: 100,
      instagramTag: '',
      maleParticipantPlaces: 1,
      maleParticipantPrice: {
        amount: 10000,
        currency: 'EUR',
      },
      name: '',
      pictureIds: [],
      startsAt: moment
        .utc()
        .add(1, 'month')
        .set({
          hour: 10,
          millisecond: 0,
          minute: 0,
          second: 0,
        })
        .format('YYYY-MM-DDTHH:mm:ss'),
      translations: {
        ru: {
          description: '',
          name: '',
        },
        uk: {
          description: '',
          name: '',
        },
      },
    }),
    [],
  );

  const tripsCollectionRef = useTripsCollectionRef();
  const citiesCollectionRef = useCitiesCollectionRef();
  const picturesCollectionRef = usePicturesCollectionRef();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const tripRef = doc(tripsCollectionRef);
      const cityRef = doc(citiesCollectionRef, values.cityId);

      await setDoc(
        tripRef,
        {
          _v: 1,
          cityRef,
          createdAt: Timestamp.now(),
          description: values.description,
          endsAt: Timestamp.fromDate(new Date(values.endsAt)),
          femaleParticipantPlaces: values.femaleParticipantPlaces,
          femaleParticipantPrice: values.femaleParticipantPrice,
          femaleParticipants: [],
          femaleParticipantTicketCoverage: values.femaleParticipantTicketCoverage,
          instagramTag: values.instagramTag,
          maleParticipantPlaces: values.maleParticipantPlaces,
          maleParticipantPrice: values.maleParticipantPrice,
          maleParticipants: [myProfileSnap.ref],
          name: values.name,
          organizerRef: myProfileSnap.ref,
          pictureRefs: compact(values.pictureIds).map((id) => doc(picturesCollectionRef, id)),
          startsAt: Timestamp.fromDate(new Date(values.startsAt)),
          status: TripStatus.PUBLISHED,
          translations: values.translations,
          updatedAt: Timestamp.now(),
        },
      );

      onComplete(tripRef);
    },
    [
      citiesCollectionRef,
      myProfileSnap.ref,
      onComplete,
      picturesCollectionRef,
      tripsCollectionRef,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          gap={4}
          h="100%"
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
            <PicturesFormControl
              label={t('pictureIds.label')}
              name="pictureIds"
            />

            <TextFormControl
              label={t('name.label')}
              name="name"
            />

            <TextareaFormControl
              label={t('description.label')}
              name="description"
              placeholder={t('description.placeholder')}
              rows={10}
            />

            <Tabs>
              <TabList overflowX="auto">
                <Tab>Russian</Tab>
                <Tab>Ukrainian</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <TextFormControl
                    label={t('name.label')}
                    name="translations.ru.name"
                  />

                  <TextareaFormControl
                    label={t('description.label')}
                    name="translations.ru.description"
                    placeholder={t('description.placeholder')}
                    rows={10}
                  />
                </TabPanel>

                <TabPanel>
                  <TextFormControl
                    label={t('name.label')}
                    name="translations.uk.name"
                  />

                  <TextareaFormControl
                    label={t('description.label')}
                    name="translations.uk.description"
                    placeholder={t('description.placeholder')}
                    rows={10}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>

            <InstagramFormControl
              label={t('instagramTag.label')}
              name="instagramTag"
              type="text"
            />

            <CityFormControl
              label={t('cityId.label')}
              name="cityId"
              placeholder={t('cityId.placeholder')}
            />

            <TextFormControl
              label={t('startsAt.label')}
              name="startsAt"
              type="datetime-local"
            />

            <TextFormControl
              label={t('endsAt.label')}
              name="endsAt"
              type="datetime-local"
            />

            <NumberFormControl
              label={t('femaleParticipantPlaces.label')}
              max={100}
              min={1}
              name="femaleParticipantPlaces"
              step={1}
            />

            <NumberFormControl
              label={t('femaleParticipantTicketCoverage.label')}
              max={100}
              min={0}
              name="femaleParticipantTicketCoverage"
              step={10}
            />

            <NumberFormControl
              label={t('femaleParticipantPrice.amount.label')}
              max={10000}
              min={0}
              name="femaleParticipantPrice.amount"
              step={100}
            />

            <NumberFormControl
              label={t('maleParticipantPlaces.label')}
              max={100}
              min={1}
              name="maleParticipantPlaces"
              step={1}
            />

            <NumberFormControl
              label={t('maleParticipantPrice.amount.label')}
              max={10000}
              min={0}
              name="maleParticipantPrice.amount"
              step={100}
            />
          </VStack>

          <Button
            colorScheme={isValid ? undefined : 'red'}
            isLoading={isValidating || isSubmitting}
            loadingText={t('createButton.loading')}
            onClick={() => handleSubmit()}
          >
            {t('createButton.default')}
          </Button>
        </VStack>
      )}
    </Formik>
  );
}
