import { Analytics } from 'firebase/analytics';
import { createContext, PropsWithChildren, useContext } from 'react';

type Props = {
  sdk: Analytics;
};

const Context = createContext<Analytics | null>(null);

export const useAnalytics = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error();
  }

  return ctx;
};

export default function AnalyticsProvider({
  children,
  sdk,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={sdk}>
      {children}
    </Context.Provider>
  );
}
