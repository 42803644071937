import {
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { LuAlertTriangle } from 'react-icons/lu';
import { Outlet, useParams } from 'react-router-dom';

import {
  getProfileVersionsCollectionRef,
  useProfilesCollectionRef,
} from '../../../common/collections/Profiles';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import ProfileVersion from '../../fragments/ProfileVersion';

export function ProfileVersionScreenMain() {
  const {
    profileId,
    profileVersionId,
  } = useParams<{
    profileId: string,
    profileVersionId: string,
  }>();

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => doc(profilesCollectionRef, profileId),
    [profileId, profilesCollectionRef],
  );

  const profileVersionRef = useMemo(
    () => doc(getProfileVersionsCollectionRef(profileRef), profileVersionId),
    [profileRef, profileVersionId],
  );

  const { snap: profileVersionSnap } = useDocumentSnapshot(profileVersionRef);

  if (!profileVersionSnap) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!profileVersionSnap.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Profile Version not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <>
      <ProfileVersion
        profileVersionSnap={profileVersionSnap}
      />

      <Outlet />
    </>
  );
}

export default function ProfileVersionScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileVersionScreenMain />
      </Suspense>
    </Catch>
  );
}
