import { Avatar, AvatarProps, Icon } from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { LuUser } from 'react-icons/lu';

import { ProfileDoc } from '../common/collections/Profiles';
import useDocumentSnapshot from '../hooks/useDocumentSnapshot';
import Catch from './Catch';
import PictureAvatar from './PictureAvatar';

export type MainProps = {
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
} & AvatarProps;

export function ProfileAvatarMain({ profileSnap, ...avatarProps }: MainProps) {
  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  return (
    <PictureAvatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      pictureRef={profileDoc.pictureRefs[0]}
    />
  );
}

export type Props = {
  profileRef: DocumentReference<ProfileDoc>;
} & AvatarProps;

export default function ProfileAvatar({ profileRef, ...avatarProps }: Props) {
  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  if (!profileSnap) {
    return (
      <Avatar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...avatarProps}
        icon={<Icon as={LuUser} />}
      />
    );
  }

  if (!profileSnap.exists()) {
    return (
      <Avatar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...avatarProps}
        icon={<Icon as={LuUser} />}
      />
    );
  }

  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ProfileAvatarMain
          profileSnap={profileSnap}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...avatarProps}
        />
      </Suspense>
    </Catch>
  );
}
