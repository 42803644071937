import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
  lookup: () => {
    if (window.Telegram.WebApp.initDataUnsafe.user?.language_code) {
      return window.Telegram.WebApp.initDataUnsafe.user.language_code;
    }

    return undefined;
  },
  name: 'telegram',
});

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{ns}}/{{lng}}.json',
    },
    debug: false,
    detection: {
      order: ['telegram', 'navigator'],
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: [
      'en',
      'ru',
      'uk',
    ],
  });

export default i18n;
