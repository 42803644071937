import {
  Center,
  HStack,
  Icon,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { FaInstagram, FaTelegram } from 'react-icons/fa';
import {
  LuCalendar,
  LuCrown,
  LuHeart,
  LuInfo,
  LuUserPlus,
} from 'react-icons/lu';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useApplicationsCollectionRef } from '../../../common/collections/Applications';
import {
  getProfileVersionsCollectionRef,
  useProfilesCollectionRef,
} from '../../../common/collections/Profiles';
import { useTelegramUsersCollectionRef } from '../../../common/collections/TelegramUsers';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import Applications from '../../fragments/Applications';
import Details from '../../fragments/Details';
import Instagram from '../../fragments/Instagram';
import Interviews from '../../fragments/Interviews';
import Profile from '../../fragments/Profile';
import ProfileVersion from '../../fragments/ProfileVersion';
import TelegramUser from '../../fragments/TelegramUser';

export function AdminTelegramChatTelegramUserScreenMain() {
  const { telegramUserId } = useParams<{ telegramUserId: string }>();

  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();
  const telegramUserRef = useMemo(
    () => doc(telegramUsersCollectionRef, telegramUserId),
    [telegramUserId, telegramUsersCollectionRef],
  );

  const { snap: telegramUserSnap } = useDocumentSnapshot(telegramUserRef);

  const telegramUserDoc = useMemo(
    () => telegramUserSnap?.data(),
    [telegramUserSnap],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => (telegramUserDoc?.uid ? doc(profilesCollectionRef, telegramUserDoc.uid) : undefined),
    [profilesCollectionRef, telegramUserDoc?.uid],
  );

  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  const { snap: profileVersionsSnap } = useQuerySnapshot(
    profileRef ? query(
      getProfileVersionsCollectionRef(profileRef),
      orderBy('sentAt', 'desc'),
    ) : undefined,
  );

  const applicationsCollectionRef = useApplicationsCollectionRef();

  const { snap: applicationsSnap } = useQuerySnapshot(
    profileSnap ? query(
      applicationsCollectionRef,
      where('applicantRef', '==', profileSnap.ref),
      orderBy('sentAt', 'desc'),
    ) : undefined,
  );

  const instagramTag = useMemo(
    () => {
      const profileDoc = profileSnap?.data();

      if (profileDoc?.instagramTag) {
        return profileDoc.instagramTag;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const profileVersionSnap of (profileVersionsSnap?.docs ?? [])) {
        const profileVersionDoc = profileVersionSnap.data();

        if (profileVersionDoc?.instagramTag) {
          return profileVersionDoc.instagramTag;
        }
      }

      return undefined;
    },
    [profileSnap, profileVersionsSnap?.docs],
  );

  const navigate = useNavigate();

  const applicationsMatch = useMatch('/admin/telegramChats/:telegramChatId/telegramUsers/:telegramUserId/applications');
  const detailsMatch = useMatch('/admin/telegramChats/:telegramChatId/telegramUsers/:telegramUserId/details');
  const instagramMatch = useMatch('/admin/telegramChats/:telegramChatId/telegramUsers/:telegramUserId/instagram');
  const interviewsMatch = useMatch('/admin/telegramChats/:telegramChatId/telegramUsers/:telegramUserId/interviews');
  const profileMatch = useMatch('/admin/telegramChats/:telegramChatId/telegramUsers/:telegramUserId/profile');
  const profileVersionMatch = useMatch('/admin/telegramChats/:telegramChatId/telegramUsers/:telegramUserId/profileVersion');
  const telegramMatch = useMatch('/admin/telegramChats/:telegramChatId/telegramUsers/:telegramUserId/telegram');

  return (
    <VStack
      alignItems="stretch"
      gap={0}
      minH={0}
    >
      <HStack flexGrow={0} flexShrink={0} h={12} justifyContent="space-between" p={2}>
        {profileSnap?.exists() ? (
          <IconButton
            aria-label="Profile"
            icon={<Icon as={LuCrown} />}
            isActive={!!profileMatch}
            onClick={() => navigate('profile')}
            size="sm"
            variant="ghost"
          />
        ) : null}

        {profileRef ? (
          <IconButton
            aria-label="Interviews"
            icon={<Icon as={LuCalendar} />}
            isActive={!!interviewsMatch}
            onClick={() => navigate('interviews')}
            size="sm"
            variant="ghost"
          />
        ) : null}

        {profileSnap?.exists() && applicationsSnap ? (
          <IconButton
            aria-label="Applications"
            icon={<Icon as={LuHeart} />}
            isActive={!!applicationsMatch}
            onClick={() => navigate('applications')}
            size="sm"
            variant="ghost"
          />
        ) : null}

        {instagramTag ? (
          <IconButton
            aria-label="Instagram"
            icon={<Icon as={FaInstagram} />}
            isActive={!!instagramMatch}
            onClick={() => navigate('instagram')}
            size="sm"
            variant="ghost"
          />
        ) : null}

        {profileVersionsSnap?.docs.length ? (
          <IconButton
            aria-label="Versions"
            icon={<Icon as={LuUserPlus} />}
            isActive={!!profileVersionMatch}
            onClick={() => navigate('profileVersion')}
            size="sm"
            variant="ghost"
          />
        ) : null}

        {telegramUserSnap?.exists() ? (
          <IconButton
            aria-label="Versions"
            icon={<Icon as={FaTelegram} />}
            isActive={!!telegramMatch}
            onClick={() => navigate('telegram')}
            size="sm"
            variant="ghost"
          />
        ) : null}

        <IconButton
          aria-label="Details"
          icon={<Icon as={LuInfo} />}
          isActive={!!detailsMatch}
          onClick={() => navigate('details')}
          size="sm"
          variant="ghost"
        />
      </HStack>

      <Routes>
        <Route element={<Navigate to="profile" />} index />

        {profileSnap?.exists() ? (
          <Route element={<Profile profileSnap={profileSnap} />} path="profile" />
        ) : null}

        {profileRef ? (
          <Route element={<Interviews profileRef={profileRef} />} path="interviews" />
        ) : null}

        {profileSnap?.exists() && applicationsSnap ? (
          <Route
            element={
              <Applications applicationsSnap={applicationsSnap} profileRef={profileSnap.ref} />
          }
            path="applications"
          />
        ) : null}

        {instagramTag ? (
          <Route
            element={
              <Instagram instagramTag={instagramTag} />
          }
            path="instagram"
          />
        ) : null}

        {profileVersionsSnap?.docs.length ? (
          <Route
            element={
              <ProfileVersion profileVersionSnap={profileVersionsSnap.docs[0]} />
          }
            path="profileVersion"
          />
        ) : null}

        {telegramUserSnap?.exists() ? (
          <Route
            element={
              <TelegramUser telegramUserSnap={telegramUserSnap} />
          }
            path="telegram"
          />
        ) : null}

        {profileRef ? (
          <Route
            element={
              <Details profileRef={profileRef} />
        }
            path="details"
          />
        ) : null}
      </Routes>
    </VStack>
  );
}

export default function AdminTelegramChatTelegramUserScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <AdminTelegramChatTelegramUserScreenMain />
      </Suspense>
    </Catch>
  );
}
