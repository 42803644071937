import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  orderBy,
  query,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { Formik, FormikHelpers } from 'formik';
import moment from 'moment-timezone';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import * as yup from 'yup';

import { InterviewDoc, useInterviewsCollectionRef } from '../../../common/collections/Interviews';
import { ProfileDoc } from '../../../common/collections/Profiles';
import Catch from '../../../components/Catch';
import NumberFormControl from '../../../components/NumberFormControl';
import TextFormControl from '../../../components/TextFormControl';
import useStripeRefundInterview from '../../../functions/useStripeRefundInterview';
import useCurrentTime from '../../../hooks/useCurrentTime';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import useShowError from '../../../hooks/useShowError';

export type Props = {
  profileRef: DocumentReference<ProfileDoc>;
};

export function InterviewsMain({ profileRef }: Props) {
  const interviewsCollectionRef = useInterviewsCollectionRef();

  const { snap: interviewsSnap } = useQuerySnapshot(
    query(
      interviewsCollectionRef,
      where('profileRef', '==', profileRef),
      orderBy('scheduledAt', 'asc'),
    ),
  );

  const currentTime = useCurrentTime(1000 * 60);

  const { isOpen, onClose, onOpen } = useDisclosure();

  const schema = useMemo(
    () => yup.object().shape({
      duration: yup
        .number()
        .label('Duration')
        .required(),
      meetingUrl: yup
        .string()
        .label('Meeting Url')
        .url()
        .required(),
      scheduledAt: yup
        .string()
        .label('Scheduled At')
        .required(),
    }),
    [],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      duration: 15,
      meetingUrl: '',
      scheduledAt: moment().startOf('hour').add(1, 'day').format('YYYY-MM-DDTHH:mm:ss'),
    }),
    [],
  );

  const onSubmit = useCallback(
    async (
      values: typeof schema['__outputType'],
      formikHelpers: FormikHelpers<typeof schema['__outputType']>,
    ) => {
      await setDoc(
        doc(interviewsCollectionRef),
        {
          _v: 1,
          createdAt: Timestamp.now(),
          duration: values.duration,
          meetingUrl: values.meetingUrl,
          profileRef,
          scheduledAt: Timestamp.fromDate(new Date(values.scheduledAt)),
          updatedAt: Timestamp.now(),
        },
      );
      formikHelpers.resetForm();
      onClose();
    },
    [interviewsCollectionRef, onClose, profileRef],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  const showError = useShowError();
  const stripeRefundInterview = useStripeRefundInterview();
  const [isRefunding, setRefunding] = useState<boolean>(false);
  const handleRefundInterview = useCallback(
    (interviewSnap: QueryDocumentSnapshot<InterviewDoc>) => {
      setRefunding(true);
      stripeRefundInterview({ interviewId: interviewSnap.id })
        .finally(() => setRefunding(false))
        .catch(showError);
    },
    [showError, stripeRefundInterview],
  );

  return (
    <>
      <VStack alignItems="stretch" flex={1} gap={1}>
        <VStack alignItems="stretch" flex={1} overflow="auto" p={2}>
          {interviewsSnap?.docs.map((interviewSnap) => {
            const interviewDoc = interviewSnap.data();
            return (
              <HStack key={interviewSnap.id}>
                <VStack alignItems="stretch" flex={1} gap={0} overflow="hidden">
                  <Text flex={1}>
                    {moment(interviewDoc.scheduledAt.toDate()).calendar(currentTime)}
                  </Text>

                  {interviewDoc.timezone ? (
                    <Text flex={1} fontSize="xs">
                      {
                        moment(interviewDoc.scheduledAt.toDate())
                          .tz(interviewDoc.timezone)
                          .calendar(currentTime)
                      }
                      {' '}
                      (
                      {interviewDoc.timezone}
                      )
                    </Text>
                  ) : null}
                </VStack>

                {interviewDoc.stripeChargeId && !interviewDoc.stripeRefundId ? (
                  <Button
                    colorScheme="green"
                    isLoading={isRefunding}
                    onClick={() => handleRefundInterview(interviewSnap)}
                    variant="outline"
                  >
                    Refund
                  </Button>
                ) : null}

                <Button
                  as="a"
                  colorScheme="blue"
                  href={interviewDoc.meetingUrl}
                  target="_blank"
                  variant="outline"
                >
                  Join
                </Button>
              </HStack>
            );
          })}
        </VStack>

        <HStack p={2}>
          <Button flex={1} onClick={onOpen}>
            Schedule
          </Button>
        </HStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        <Formik<typeof schema['__outputType']>
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur={validateAll}
          validateOnChange={validateAll}
          validationSchema={schema}
        >
          {({
            handleSubmit,
            isSubmitting,
          }) => (
            <ModalContent
              as="form"
              mx={4}
              noValidate
              onSubmit={(e) => {
                setValidateAll(true);
                e.preventDefault();
                handleSubmit();
              }}
            >
              <ModalCloseButton />

              <ModalHeader>
                Schedule Interview
              </ModalHeader>

              <ModalBody>
                <VStack alignItems="stretch" gap={4}>
                  <TextFormControl
                    autoComplete="scheduledAt"
                    label="Scheduled At"
                    name="scheduledAt"
                    type="datetime-local"
                  />

                  <NumberFormControl
                    label="Duration"
                    max={120}
                    min={15}
                    name="duration"
                    right="min."
                    step={15}
                  />

                  <TextFormControl
                    label="Meeting Url"
                    name="meetingUrl"
                    type="url"
                  />
                </VStack>
              </ModalBody>

              <ModalFooter>
                <Button
                  onClick={onClose}
                  variant="ghost"
                >
                  Close
                </Button>

                <Button
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  type="submit"
                >
                  Schedule
                </Button>
              </ModalFooter>
            </ModalContent>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default function Interviews(props: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <InterviewsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
