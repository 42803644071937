import {
  Container,
  HStack,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';

import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import KeyGrid, { KeyGridLoading } from './KeysGrid';

export function KeysScreenMain() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflow="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <KeyGrid />
      </Container>
    </VStack>
  );
}

export function KeysScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <KeyGridLoading />
      </Container>
    </VStack>
  );
}

export default function KeysScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<KeysScreenLoading />}>
        <KeysScreenMain />
      </Suspense>
    </Catch>
  );
}
