import {
  Center,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { Suspense, useEffect } from 'react';
import { LuAlertTriangle } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';

import { useAllowancesCollectionRef } from '../../common/collections/Allowances';
import Catch from '../../components/Catch';
import LogoIcon from '../../components/LogoIcon';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import AllowanceRow from './AllowanceRow';

export function TheirAllowancesScreenMain() {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

        navigate('..');
      };

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const { allowanceId } = useParams<{ allowanceId: string }>();

  const allowancesCollectionRef = useAllowancesCollectionRef();
  const { snap: allowanceSnap } = useDocumentSnapshot(
    doc(
      allowancesCollectionRef,
      allowanceId,
    ),
  );

  if (!allowanceSnap || (!allowanceSnap.exists() && allowanceSnap.metadata.fromCache)) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!allowanceSnap.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Allowance not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <AllowanceRow allowanceSnap={allowanceSnap} />
  );
}

export default function TheirAllowancesScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <TheirAllowancesScreenMain />
      </Suspense>
    </Catch>
  );
}
