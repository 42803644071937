import {
  Box,
  BoxProps,
  Container,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import AppLanguage from '../../common/AppLanguage';
import { AllowanceDoc } from '../../common/collections/Allowances';
import Catch from '../../components/Catch';
import PictureCell from '../../components/PictureCell';
import useDimensions from '../../hooks/useDimensions';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';
import i18n from '../../i18n';

export type Props = {
  allowanceSnap: QueryDocumentSnapshot<AllowanceDoc>;
} & BoxProps;

export function AllowanceMain({
  allowanceSnap, ...boxProps
}: Props) {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Allowance Clicked');

      window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

      navigate(`/allowances/${allowanceSnap.id}`);
    },
    [navigate, allowanceSnap.id],
  );

  const allowanceDoc = useMemo(() => allowanceSnap.data(), [allowanceSnap]);

  const { snap: citySnap } = useDocumentSnapshot(allowanceDoc.cityRef);

  const cityDoc = useMemo(
    () => citySnap?.data(),
    [citySnap],
  );

  const [ref, { height: h, width: w }] = useDimensions();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <PictureCell
        bottom={0}
        height={h ?? 1600}
        left={0}
        pictureRef={allowanceDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        top={0}
        width={w ?? 900}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {/* eslint-disable-next-line max-len */}
              {(allowanceDoc.translations && allowanceDoc.translations[i18n.language as AppLanguage]?.name) ?? allowanceDoc.name}
            </Heading>
          </Container>

          {cityDoc ? (
            <Text
              fontSize="sm"
              opacity={0.75}
              textAlign="center"
            >
              {compact([
                cityDoc.name,
                cityDoc.countryName,
              ]).join(', ')}
            </Text>
          ) : null}
        </VStack>
      </VStack>
    </Box>
  );
}

export function AllowanceLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export default function Allowance({ allowanceSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <AllowanceLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <AllowanceMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          allowanceSnap={allowanceSnap}
        />
      </Suspense>
    </Catch>
  );
}
