enum Goal {
  FRIENDSHIP = 'FRIENDSHIP',
  FUN = 'FUN',
  LOVE = 'LOVE',
  MARRIAGE = 'MARRIAGE',
  MODELLING = 'MODELLING',
  PARTYING = 'PARTYING',
  SEX = 'SEX',
  TRAVEL = 'TRAVEL',
}

export default Goal;
