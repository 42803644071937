import { Container, HStack, VStack } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { Formik } from 'formik';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { isCityRef, useCitiesCollectionRef } from '../../common/collections/Cities';
import { ProfileVersionDoc } from '../../common/collections/Profiles';
import CityFormControl from '../../components/CityFormControl';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import { TelegramMainButton } from '../../components/TelegramMainButton';

export type Props = {
  data: Pick<ProfileVersionDoc, 'originRef'> | undefined;
  onComplete: (data: Pick<ProfileVersionDoc, 'originRef'>) => void;
};

export function OriginFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      originId: yup
        .string()
        .label(t('originId.label'))
        .required(),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      originId: data?.originRef?.id && isCityRef(data.originRef)
        ? data.originRef.id
        : '',
    }),
    [data],
  );

  const citiesCollectionRef = useCitiesCollectionRef();

  const handleFormSubmit = useCallback(
    (values: typeof schema['__outputType']) => {
      const originRef = doc(citiesCollectionRef, values.originId);
      onComplete({ originRef });
    },
    [citiesCollectionRef, onComplete],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <CityFormControl
                  aroundLatLngViaIP
                  helperText={t('originId.helperText')}
                  label={t('originId.label')}
                  name="originId"
                  placeholder={t('originId.placeholder')}
                />
              </VStack>
            </Container>

            <TelegramMainButton
              color={isValid ? '#424242' : '#F44336'}
              isLoading={isValidating || isSubmitting}
              onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                setValidateAll(true);
                handleSubmit();
              }}
              text={isValid ? t('nextButton.default') : t('nextButton.invalid')}
              textColor="#FFFFFF"
            />
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function OriginForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <OriginFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
