import { AvatarGroup, AvatarGroupProps } from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  query,
  QuerySnapshot,
} from 'firebase/firestore';
import { Suspense } from 'react';

import {
  getTelegramChatMembersCollectionRef,
  TelegramChatMemberDoc,
} from '../../../common/collections/TelegramChatMembers';
import { TelegramChatDoc } from '../../../common/collections/TelegramChats';
import { useTelegramUsersCollectionRef } from '../../../common/collections/TelegramUsers';
import Catch from '../../../components/Catch';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import Profile from './Profile';

export type MainProps = {
  telegramChatMembersSnap: QuerySnapshot<TelegramChatMemberDoc>;
} & Omit<AvatarGroupProps, 'children'>;

export function ChatMembersMain({
  telegramChatMembersSnap, ...avatarGroupProps
}: MainProps) {
  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();

  return (
    <AvatarGroup
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarGroupProps}
    >
      {telegramChatMembersSnap.docs.map((telegramChatMemberSnap) => {
        const telegramChatMemberDoc = telegramChatMemberSnap.data();

        return (
          <Profile
            telegramUserRef={
              doc(
                telegramUsersCollectionRef,
                String(telegramChatMemberDoc.telegramChatMember.user.id),
              )
            }
          />
        );
      })}
    </AvatarGroup>
  );
}

export type Props = {
  telegramChatRef: DocumentReference<TelegramChatDoc>;
} & Omit<AvatarGroupProps, 'children'>;

export default function ChatMembers({ telegramChatRef, ...avatarProps }: Props) {
  const telegramChatMembersCollectionRef = getTelegramChatMembersCollectionRef(telegramChatRef);

  const { snap: telegramChatMembersSnap } = useQuerySnapshot(
    query(telegramChatMembersCollectionRef),
  );

  if (!telegramChatMembersSnap) {
    return null;
  }

  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ChatMembersMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...avatarProps}
          telegramChatMembersSnap={telegramChatMembersSnap}
        />
      </Suspense>
    </Catch>
  );
}
