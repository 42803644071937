import { Icon } from '@chakra-ui/react';
import { DocumentSnapshot, onSnapshot } from 'firebase/firestore';
import {
  Suspense,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuUser } from 'react-icons/lu';

import { ProfileDoc } from '../../common/collections/Profiles';
import Catch from '../../components/Catch';
import PictureAvatar from '../../components/PictureAvatar';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';

function ProfileButtonMain() {
  const myProfileRef = useMyProfileRef();

  const [
    myProfileSnap,
    setMyProfileSnap,
  ] = useState<DocumentSnapshot<ProfileDoc> | undefined>(undefined);

  useEffect(
    () => {
      if (myProfileRef) {
        return onSnapshot(myProfileRef, setMyProfileSnap);
      }

      setMyProfileSnap(undefined);
      return () => { };
    },
    [myProfileRef],
  );

  const myProfileDoc = useMemo(() => myProfileSnap?.data(), [myProfileSnap]);

  if (!myProfileDoc) {
    return (
      <Icon as={LuUser} boxSize={6} />
    );
  }

  return (
    <PictureAvatar
      boxSize={6}
      icon={<Icon as={LuUser} />}
      loading="lazy"
      pictureRef={myProfileDoc.pictureRefs[0]}
    />
  );
}

export default function ProfileButton() {
  return (
    <Catch fallback={<Icon as={LuUser} boxSize={6} />}>
      <Suspense fallback={<Icon as={LuUser} boxSize={6} />}>
        <ProfileButtonMain />
      </Suspense>
    </Catch>
  );
}
