import { Box, BoxProps } from '@chakra-ui/react';
import { DocumentReference } from 'firebase/firestore';

import { type PictureDoc } from '../common/collections/Picture';
import PictureImage from './PictureImage';

export type Props = {
  height: number;
  pictureRef: DocumentReference<PictureDoc>;
  width: number;
} & BoxProps;

export default function StoragePictureCell({
  height,
  pictureRef,
  width,
  ...boxProps
}: Props) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      <PictureImage
        alt=""
        h={`${height}px`}
        objectFit="cover"
        pictureRef={pictureRef}
        scrollSnapAlign="start"
        scrollSnapStop="always"
        w={`${width}px`}
      />
    </Box>
  );
}
