import {
  Box,
  BoxProps,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AppLanguage from '../../../common/AppLanguage';
import { TripDoc } from '../../../common/collections/Trips';
import Catch from '../../../components/Catch';
import PictureImage from '../../../components/PictureImage';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import i18n from '../../../i18n';

export type MainProps = {
  tripSnap: QueryDocumentSnapshot<TripDoc>;
} & BoxProps;

export function TripMain({
  tripSnap, ...boxProps
}: MainProps) {
  const { t } = useTranslation('TheirTripsScreen', { keyPrefix: 'Trip' });

  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Trip Clicked');

      window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

      navigate(`/trips/${tripSnap.id}`);
    },
    [navigate, tripSnap.id],
  );

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
    >
      <PictureImage
        bottom={0}
        left={0}
        objectFit="cover"
        pictureRef={tripDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        scrollSnapAlign="start"
        scrollSnapStop="always"
        top={0}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Heading
            as="h1"
            fontSize="sm"
            fontWeight="bold"
            textAlign="center"
          >
            {/* eslint-disable-next-line max-len */}
            {(tripDoc.translations && tripDoc.translations[i18n.language as AppLanguage]?.name) ?? tripDoc.name}
          </Heading>

          <Text
            fontSize="xs"
            opacity={0.75}
          >
            {t('dates.value', {
              endsAt: tripDoc.endsAt.toDate(),
              formatParams: {
                endsAt: { day: 'numeric', month: 'short', year: 'numeric' },
                startsAt: { day: 'numeric', month: 'short' },
              },
              startsAt: tripDoc.startsAt.toDate(),
            })}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}

export function TripLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export type Props = {
  tripRef: DocumentReference<TripDoc>;
} & BoxProps;

export default function Trip({ tripRef, ...boxProps }: Props) {
  const { snap: tripSnap } = useDocumentSnapshot(tripRef);

  if (!tripSnap?.exists()) {
    return (
      <TripLoading
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...boxProps}
      />
    );
  }

  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <TripLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <TripMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          tripSnap={tripSnap}
        />
      </Suspense>
    </Catch>
  );
}
