import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import type Preferences from '../Preferences';

import { useFirestore } from '../../components/FirestoreProvider';
import AppLanguage from '../AppLanguage';
import { type CityDoc } from './Cities';
import { type PictureDoc } from './Picture';
import { type ProfileDoc } from './Profiles';

export enum KeyStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface KeyDoc {
  _v: 1;
  checkoutSessionUrl?: string;
  cityRef: DocumentReference<CityDoc>;
  createdAt: Timestamp;
  description: string;
  endsAt: Timestamp;
  femaleParticipantPlaces: number;
  femaleParticipantPrice: { amount: number, currency: 'EUR' | 'PLN' | 'USD'; };
  femaleParticipants: DocumentReference<ProfileDoc>[];
  femaleParticipantTicketCoverage: number;
  indexedAt?: Timestamp;
  instagramTag?: string;
  maleParticipantPlaces: number;
  maleParticipantPrice: { amount: number, currency: 'EUR' | 'PLN' | 'USD'; };
  maleParticipants: DocumentReference<ProfileDoc>[];
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  pictureRefs: DocumentReference<PictureDoc>[];
  preferences?: {
    females?: Preferences;
    males?: Preferences;
  };
  publishedAt?: Timestamp;
  startsAt: Timestamp;
  status: KeyStatus;
  translations?: Record<AppLanguage, Pick<KeyDoc, 'description' | 'name'>>;
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
}

export const isKeyDoc = (
  data?: DocumentData,
): data is KeyDoc => true;

export const isKeyRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<KeyDoc> => ref.parent.id === 'keys';

function isKeySnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<KeyDoc>;
function isKeySnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<KeyDoc>;
function isKeySnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<KeyDoc> | QueryDocumentSnapshot<KeyDoc> {
  return isKeyRef(snap.ref);
}

export { isKeySnap };

export const getKeysCollectionRef = (firestore: Firestore) => collection(firestore, 'keys') as CollectionReference<KeyDoc>;

export const useKeysCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getKeysCollectionRef(firestore), [firestore]);
};
