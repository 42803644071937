import {
  Button,
  Container,
  Heading,
  HStack,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Joyride, { Step } from 'react-joyride';

import ensureWriteAccess from '../../common/ensureWriteAccess';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';
import useJoyrideLocale from '../../hooks/useJoyrideLocale';
import useJoyrideStyles from '../../hooks/useJoyrideStyles';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import JobGrid, { JobGridLoading } from './JobGrid';

export function JobsScreenMain() {
  const { i18n, t } = useTranslation('TheirJobsScreen');

  const insets = useInsets();

  const myProfileRef = useMyProfileRef();
  const showError = useShowError();
  const [isBecomeSponsorLoading, setBecomeSponsorLoading] = useState<boolean>(false);
  const onBecomeSponsorClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setBecomeSponsorLoading(true);

      mixpanel.track('Become Sponsor Clicked');

      (async () => {
        await ensureWriteAccess();

        const calendlyUrl = new URL(`https://calendly.com/qupidu/exclusive-consultation-${i18n.language}`);
        calendlyUrl.searchParams.set('a1', myProfileRef.id);

        window.Telegram.WebApp.openLink(calendlyUrl.toString());
      })()
        .finally(() => setBecomeSponsorLoading(false))
        .catch(showError);
    },
    [i18n.language, myProfileRef.id, showError],
  );

  const locale = useJoyrideLocale();

  const styles = useJoyrideStyles();

  const steps = useMemo<Step[]>(() => ([
    {
      content: t('tour.welcome.content'),
      placement: 'center',
      target: 'body',
      title: t('tour.welcome.title'),
    },
    {
      content: t('tour.jobsSponsorButton.content'),
      target: '.jobsSponsorButton',
    },
    {
      content: t('tour.jobItem.content'),
      target: '.jobItem',
    },
  ]), [t]);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <Heading fontSize="2xl">
            Jobs
          </Heading>
        </HStack>
      </Container>

      <Joyride
        continuous
        hideCloseButton
        locale={locale}
        run
        showSkipButton
        steps={steps}
        styles={styles}
      />

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflow="auto"
      >
        <JobGrid />
      </Container>

      <Container
        maxW="lg"
        py={2}
      >
        <Button
          className="jobsSponsorButton"
          colorScheme="indigo"
          isLoading={isBecomeSponsorLoading}
          onClick={onBecomeSponsorClick}
          width="100%"
        >
          {t('becomeSponsorButton.default')}
        </Button>
      </Container>
    </VStack>
  );
}

export function JobsScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <JobGridLoading />
      </Container>
    </VStack>
  );
}

export default function JobsScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<JobsScreenLoading />}>
        <JobsScreenMain />
      </Suspense>
    </Catch>
  );
}
