import {
  Button,
  Center,
  Icon,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import {
  deleteField,
  DocumentReference,
  setDoc,
  Timestamp,
} from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuBadgeCheck, LuBadgeX } from 'react-icons/lu';

import { getProfileHiddenRef, ProfileDoc } from '../../../common/collections/Profiles';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';

export type Props = {
  profileRef: DocumentReference<ProfileDoc>;
};

export function DetailsMain({ profileRef }: Props) {
  const { snap: profileSnap } = useDocumentSnapshot(profileRef);
  const { snap: profileHiddenSnap } = useDocumentSnapshot(getProfileHiddenRef(profileRef));

  const profileDoc = useMemo(() => profileSnap?.data(), [profileSnap]);
  const profileHiddenDoc = useMemo(() => profileHiddenSnap?.data(), [profileHiddenSnap]);

  const handleAddVerifiedBadgeClick = useCallback(
    () => {
      if (profileSnap?.ref) {
        setDoc(
          profileSnap.ref,
          {
            isVerified: true,
            verifiedAt: Timestamp.now(),
          },
          { merge: true },
        ).catch(() => { });
      }
    },
    [profileSnap?.ref],
  );

  const handleRemoveVerifiedBadgeClick = useCallback(
    () => {
      if (profileSnap?.ref) {
        setDoc(
          profileSnap.ref,
          {
            isVerified: deleteField(),
            verifiedAt: deleteField(),
          },
          { merge: true },
        ).catch(() => { });
      }
    },
    [profileSnap?.ref],
  );

  const [description, setDescription] = useState<string>('');

  useEffect(
    () => { setDescription(profileHiddenDoc?.description ?? ''); },
    [profileHiddenDoc?.description],
  );

  const handleSaveClick = useCallback(
    () => {
      if (profileHiddenSnap?.ref) {
        setDoc(
          profileHiddenSnap.ref,
          {
            description,
          },
          { merge: true },
        ).catch(() => { });
      }
    },
    [description, profileHiddenSnap?.ref],
  );

  return (
    <VStack alignItems="stretch" flex={1} overflow="auto" p={2}>
      {profileDoc?.isVerified === true ? (
        <Button
          leftIcon={<Icon as={LuBadgeX} />}
          onClick={handleRemoveVerifiedBadgeClick}
        >
          Remove verified badge
        </Button>
      ) : (
        <Button
          colorScheme="blue"
          leftIcon={<Icon as={LuBadgeCheck} />}
          onClick={handleAddVerifiedBadgeClick}
        >
          Add verified badge
        </Button>
      )}

      <Textarea
        flex={1}
        onChange={(e) => setDescription(e.target.value)}
        resize="none"
        value={description}
      />

      <Button
        onClick={handleSaveClick}
      >
        Save
      </Button>
    </VStack>
  );
}

export default function Details(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <DetailsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
