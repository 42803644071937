import base64URLencode from './base64URLencode';

export enum WebAppActionType {
  EDIT_TRIP = 'edit_trip',
  EDIT_VENTURE = 'edit_venture',
  LIST_ALLOWANCES = 'list_allowances',
  LIST_APPLICATIONS = 'list_applications',
  LIST_JOBS = 'list_jobs',
  LIST_KEYS = 'list_keys',
  LIST_TRIPS = 'list_trips',
  LIST_VENTURES = 'list_ventures',
  NEW_TRIP = 'new_trip',
  NEW_VENTURE = 'new_venture',
  VIEW_ALLOWANCE = 'view_allowance',
  VIEW_ALLOWANCE_APPLICATION = 'view_allowance_application',
  VIEW_APPLICATION = 'view_application',
  VIEW_CONVERSATION = 'view_conversation',
  VIEW_JOB = 'view_job',
  VIEW_JOB_APPLICATION = 'view_job_application',
  VIEW_KEY = 'view_key',
  VIEW_PROFILE = 'view_profile',
  VIEW_TRIP = 'view_trip',
  VIEW_TRIP_APPLICATION = 'view_trip_application',
  VIEW_VENTURE = 'view_venture',
  VIEW_VENTURE_APPLICATION = 'view_venture_application',
  WELCOME = 'welcome',
}

export type NewTripAction = {
  action: WebAppActionType.NEW_TRIP;
};

export type NewVentureAction = {
  action: WebAppActionType.NEW_VENTURE;
};

export type EditTripAction = {
  action: WebAppActionType.EDIT_TRIP;
  tripId: string;
};

export type EditVentureAction = {
  action: WebAppActionType.EDIT_VENTURE;
  ventureId: string;
};

export type ListApplicationAction = {
  action: WebAppActionType.LIST_APPLICATIONS;
};

export type ViewApplicationAction = {
  action: WebAppActionType.VIEW_APPLICATION;
  applicationId: string;
};

export type ViewTripApplicationAction = {
  action: WebAppActionType.VIEW_TRIP_APPLICATION;
  applicationId: string;
  tripId: string;
};

export type ViewAllowanceApplicationAction = {
  action: WebAppActionType.VIEW_ALLOWANCE_APPLICATION;
  allowanceId: string;
  applicationId: string;
};

export type ViewVentureApplicationAction = {
  action: WebAppActionType.VIEW_VENTURE_APPLICATION;
  applicationId: string;
  ventureId: string;
};

export type ViewJobApplicationAction = {
  action: WebAppActionType.VIEW_JOB_APPLICATION;
  applicationId: string;
  jobId: string;
};

export type ViewConversationAction = {
  action: WebAppActionType.VIEW_CONVERSATION;
  conversationId: string;
};

export type ViewProfileAction = {
  action: WebAppActionType.VIEW_PROFILE;
  profileId: string;
};

export type ListJobsAction = {
  action: WebAppActionType.LIST_JOBS;
};

export type ViewJobAction = {
  action: WebAppActionType.VIEW_JOB;
  jobId: string;
};

export type ListTripsAction = {
  action: WebAppActionType.LIST_TRIPS;
};

export type ViewTripAction = {
  action: WebAppActionType.VIEW_TRIP;
  tripId: string;
};

export type ListAllowancesAction = {
  action: WebAppActionType.LIST_ALLOWANCES;
};

export type ViewAllowanceAction = {
  action: WebAppActionType.VIEW_ALLOWANCE;
  allowanceId: string;
};

export type ListVenturesAction = {
  action: WebAppActionType.LIST_VENTURES;
};

export type ViewVentureAction = {
  action: WebAppActionType.VIEW_VENTURE;
  ventureId: string;
};

export type ListKeysAction = {
  action: WebAppActionType.LIST_KEYS;
};

export type ViewKeyAction = {
  action: WebAppActionType.VIEW_KEY;
  keyId: string;
};

export type ViewKeysAction = {
  action: WebAppActionType.VIEW_KEY;
  keyId: string;
};

export type WelcomeAction = {
  action: WebAppActionType.WELCOME;
};

type WebAppAction =
  | EditTripAction
  | EditVentureAction
  | ListAllowancesAction
  | ListApplicationAction
  | ListJobsAction
  | ListKeysAction
  | ListTripsAction
  | ListVenturesAction
  | NewTripAction
  | NewVentureAction
  | ViewAllowanceAction
  | ViewAllowanceApplicationAction
  | ViewApplicationAction
  | ViewConversationAction
  | ViewJobAction
  | ViewJobApplicationAction
  | ViewKeyAction
  | ViewProfileAction
  | ViewTripAction
  | ViewTripApplicationAction
  | ViewVentureAction
  | ViewVentureApplicationAction
  | WelcomeAction;

export default WebAppAction;

export function getTelegramAppLinkData(data?: WebAppAction): string {
  const url = new URL('https://t.me/qupidubot');
  if (data) {
    url.searchParams.set('startapp', base64URLencode(JSON.stringify(data)));
  } else {
    url.searchParams.set('startapp', '');
  }

  return url.toString();
}
