import {
  FirestoreError,
  onSnapshot,
  Query,
  queryEqual,
  QuerySnapshot,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';

export default function useQuerySnapshot<T>(nextQuery: Query<T> | undefined) {
  const [currentQuery, setCurrentQuery] = useState<Query<T> | undefined>(nextQuery);

  useEffect(
    () => {
      if (
        currentQuery === undefined
        || nextQuery === undefined
        || !queryEqual(currentQuery, nextQuery)
      ) {
        setCurrentQuery(nextQuery);
      }
    },
    [currentQuery, nextQuery],
  );

  const [snap, setSnap] = useState<QuerySnapshot<T>>();
  const [error, setError] = useState<FirestoreError>();

  useEffect(
    () => {
      if (currentQuery !== undefined) {
        return onSnapshot(currentQuery, setSnap, setError);
      }

      setSnap(undefined);
      setError(undefined);

      return () => { };
    },
    [currentQuery],
  );

  return { error, snap };
}
