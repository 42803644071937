import {
  Center,
  HStack,
  Icon,
  IconButton,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { first } from 'lodash';
import { Suspense, useMemo } from 'react';
import { FaInstagram, FaTelegram } from 'react-icons/fa';
import {
  LuAlertTriangle,
  LuCalendar,
  LuHeart,
  LuInfo,
  LuMessageSquare,
  LuUserPlus,
} from 'react-icons/lu';
import {
  Navigate,
  Route,
  Routes,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { useApplicationsCollectionRef } from '../../../common/collections/Applications';
import {
  getProfileVersionsCollectionRef,
  useProfilesCollectionRef,
} from '../../../common/collections/Profiles';
import { useTelegramChatsCollectionRef } from '../../../common/collections/TelegramChats';
import { useTelegramUsersCollectionRef } from '../../../common/collections/TelegramUsers';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import Applications from '../../fragments/Applications';
import Details from '../../fragments/Details';
import Instagram from '../../fragments/Instagram';
import Interviews from '../../fragments/Interviews';
import Profile from '../../fragments/Profile';
import ProfileVersion from '../../fragments/ProfileVersion';
import TelegramChat from '../../fragments/TelegramChat';
import TelegramUser from '../../fragments/TelegramUser';

export function ProfileScreenMain() {
  const { profileId } = useParams<{ profileId: string }>();

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => doc(profilesCollectionRef, profileId),
    [profileId, profilesCollectionRef],
  );

  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  const { snap: profileVersionsSnap } = useQuerySnapshot(
    profileRef ? query(
      getProfileVersionsCollectionRef(profileRef),
      orderBy('sentAt', 'desc'),
    ) : undefined,
  );

  const applicationsCollectionRef = useApplicationsCollectionRef();

  const { snap: applicationsSnap } = useQuerySnapshot(
    profileSnap ? query(
      applicationsCollectionRef,
      where('applicantRef', '==', profileSnap.ref),
      orderBy('sentAt', 'desc'),
    ) : undefined,
  );

  const instagramTag = useMemo(
    () => {
      const profileDoc = profileSnap?.data();

      if (profileDoc?.instagramTag) {
        return profileDoc.instagramTag;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const profileVersionSnap of (profileVersionsSnap?.docs ?? [])) {
        const profileVersionDoc = profileVersionSnap.data();

        if (profileVersionDoc?.instagramTag) {
          return profileVersionDoc.instagramTag;
        }
      }

      return undefined;
    },
    [profileSnap, profileVersionsSnap?.docs],
  );

  const navigate = useNavigate();

  const applicationsMatch = useMatch('/admin/profiles/:profileId/applications');
  const detailsMatch = useMatch('/admin/profiles/:profileId/details');
  const instagramMatch = useMatch('/admin/profiles/:profileId/instagram');
  const interviewsMatch = useMatch('/admin/profiles/:profileId/interviews');
  const profileVersionMatch = useMatch('/admin/profiles/:profileId/profileVersion');
  const telegramChatMatch = useMatch('/admin/profiles/:profileId/telegramChat');
  const telegramMatch = useMatch('/admin/profiles/:profileId/telegram');

  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();
  const { snap: telegramUsersSnap } = useQuerySnapshot(
    query(
      telegramUsersCollectionRef,
      where('uid', '==', profileRef.id),
      limit(1),
    ),
  );

  const telegramUserSnap = useMemo(
    () => first(telegramUsersSnap?.docs),
    [telegramUsersSnap?.docs],
  );

  const telegramChatsCollectionRef = useTelegramChatsCollectionRef();
  const telegramChatRef = useMemo(
    () => (telegramUserSnap ? doc(telegramChatsCollectionRef, telegramUserSnap.id) : undefined),
    [telegramChatsCollectionRef, telegramUserSnap],
  );

  if (!profileSnap || (!profileSnap.exists() && profileSnap.metadata.fromCache)) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!profileSnap?.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Profile not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <>
      <Profile
        profileSnap={profileSnap}
      />

      <VStack
        alignItems="stretch"
        gap={0}
        minH={0}
      >
        <HStack flexGrow={0} flexShrink={0} h={16} justifyContent="space-between" p={2}>
          {telegramChatRef ? (
            <IconButton
              aria-label="Versions"
              icon={<Icon as={LuMessageSquare} />}
              isActive={!!telegramChatMatch}
              onClick={() => navigate('telegramChat')}
              variant="ghost"
            />
          ) : null}

          {profileRef ? (
            <IconButton
              aria-label="Interviews"
              icon={<Icon as={LuCalendar} />}
              isActive={!!interviewsMatch}
              onClick={() => navigate('interviews')}
              variant="ghost"
            />
          ) : null}

          {profileSnap?.exists() && applicationsSnap ? (
            <IconButton
              aria-label="Applications"
              icon={<Icon as={LuHeart} />}
              isActive={!!applicationsMatch}
              onClick={() => navigate('applications')}
              variant="ghost"
            />
          ) : null}

          {instagramTag ? (
            <IconButton
              aria-label="Instagram"
              icon={<Icon as={FaInstagram} />}
              isActive={!!instagramMatch}
              onClick={() => navigate('instagram')}
              variant="ghost"
            />
          ) : null}

          {profileVersionsSnap?.docs.length ? (
            <IconButton
              aria-label="Versions"
              icon={<Icon as={LuUserPlus} />}
              isActive={!!profileVersionMatch}
              onClick={() => navigate('profileVersion')}
              variant="ghost"
            />
          ) : null}

          {telegramUserSnap?.exists() ? (
            <IconButton
              aria-label="Versions"
              icon={<Icon as={FaTelegram} />}
              isActive={!!telegramMatch}
              onClick={() => navigate('telegram')}
              variant="ghost"
            />
          ) : null}

          <IconButton
            aria-label="Details"
            icon={<Icon as={LuInfo} />}
            isActive={!!detailsMatch}
            onClick={() => navigate('details')}
            variant="ghost"
          />
        </HStack>

        <Routes>
          <Route element={<Navigate to="telegramChat" />} index />
          <Route element={<Navigate to="../telegramChat" />} path="telegramChat/telegramUsers/:telegramUserId" />

          {telegramChatRef ? (
            <Route element={<TelegramChat telegramChatRef={telegramChatRef} />} path="telegramChat" />
          ) : null}

          {profileRef ? (
            <Route element={<Interviews profileRef={profileRef} />} path="interviews" />
          ) : null}

          {profileSnap?.exists() && applicationsSnap ? (
            <Route
              element={
                <Applications applicationsSnap={applicationsSnap} profileRef={profileSnap.ref} />
            }
              path="applications"
            />
          ) : null}

          {instagramTag ? (
            <Route
              element={
                <Instagram instagramTag={instagramTag} />
            }
              path="instagram"
            />
          ) : null}

          {profileVersionsSnap?.docs.length ? (
            <Route
              element={
                <ProfileVersion profileVersionSnap={profileVersionsSnap.docs[0]} />
            }
              path="profileVersion"
            />
          ) : null}

          {telegramUserSnap?.exists() ? (
            <Route
              element={
                <TelegramUser telegramUserSnap={telegramUserSnap} />
            }
              path="telegram"
            />
          ) : null}

          {profileRef ? (
            <Route
              element={
                <Details profileRef={profileRef} />
          }
              path="details"
            />
          ) : null}
        </Routes>
      </VStack>
    </>
  );
}

export default function ProfileScreen() {
  const { profileId } = useParams<{ profileId: string }>();

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileScreenMain key={profileId} />
      </Suspense>
    </Catch>
  );
}
