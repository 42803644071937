import { Avatar, AvatarProps } from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { useEffect, useMemo, useState } from 'react';

import { type PictureDoc } from '../common/collections/Picture';
import useDocumentSnapshot from '../hooks/useDocumentSnapshot';
import { useStorage } from './StorageProvider';

export type PropsMain = {
  pictureSnap: QueryDocumentSnapshot<PictureDoc>;
} & AvatarProps;

export function PictureAvatarMain({
  pictureSnap, ...avatarProps
}: PropsMain) {
  const pictureDoc = useMemo(() => pictureSnap.data(), [pictureSnap]);

  const storage = useStorage();

  const [src, setSrc] = useState<string | undefined>(undefined);

  useEffect(
    () => {
      getDownloadURL(ref(storage, pictureDoc.avatarUri))
        .then((nextSrc) => {
          setSrc(nextSrc);
        })
        .catch(() => {
          setSrc(undefined);
        });
    },
    [pictureDoc.avatarUri, storage],
  );

  return (
    <Avatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      src={src}
    />
  );
}

export type Props = {
  pictureRef: DocumentReference<PictureDoc>;
} & AvatarProps;

export default function StoragePictureAvatar({
  pictureRef, ...avatarProps
}: Props) {
  const { snap: pictureSnap } = useDocumentSnapshot(pictureRef);

  if (!pictureSnap?.exists()) {
    return (
      <Avatar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...avatarProps}
      />
    );
  }

  return (
    <PictureAvatarMain
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      pictureSnap={pictureSnap}
    />
  );
}
