import { Center } from '@chakra-ui/react';
import { DocumentSnapshot } from 'firebase/firestore';
import { Suspense, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { getProfileHiddenRef, ProfileDoc, ProfileHiddenDoc } from '../common/collections/Profiles';
import Catch from '../components/Catch';
import LogoIcon from '../components/LogoIcon';
import { useMyProfileRef } from '../components/refProviders/MyProfileRefProvider';
import MyProfileHiddenSnapProvider from '../components/snapProviders/MyProfileHiddenSnapProvider';
import MyProfileSnapProvider from '../components/snapProviders/MyProfileSnapProvider';
import useDocumentSnapshot from '../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from './ErrorFallbackScreen';

export type PropsMain = {
  fallback: React.ReactElement;
  myProfileHiddenSnap: DocumentSnapshot<ProfileHiddenDoc>;
  myProfileSnap: DocumentSnapshot<ProfileDoc>;
};

export function CheckMyProfileSnapLayoutMain({
  fallback,
  myProfileHiddenSnap,
  myProfileSnap,
}: PropsMain) {
  if (!myProfileSnap.exists() || !myProfileHiddenSnap.exists()) {
    return fallback;
  }

  return (
    <MyProfileSnapProvider profileSnap={myProfileSnap}>
      <MyProfileHiddenSnapProvider profileHiddenSnap={myProfileHiddenSnap}>
        <Outlet />
      </MyProfileHiddenSnapProvider>
    </MyProfileSnapProvider>
  );
}

export type Props = {
  fallback: React.ReactElement;
};

export default function CheckMyProfileSnapLayout(props: Props) {
  const myProfileRef = useMyProfileRef();
  const myProfileHiddenRef = useMemo(() => getProfileHiddenRef(myProfileRef), [myProfileRef]);

  const { snap: myProfileSnap } = useDocumentSnapshot(myProfileRef);
  const { snap: myProfileHiddenSnap } = useDocumentSnapshot(myProfileHiddenRef);

  if (!myProfileSnap || !myProfileHiddenSnap) {
    return (
      <Center h="100%"><LogoIcon boxSize={16} /></Center>
    );
  }

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <CheckMyProfileSnapLayoutMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          myProfileHiddenSnap={myProfileHiddenSnap}
          myProfileSnap={myProfileSnap}
        />
      </Suspense>
    </Catch>
  );
}
