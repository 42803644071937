import {
  doc,
  limit,
  query,
  where,
} from 'firebase/firestore';
import { first } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useProfilesCollectionRef } from '../../../common/collections/Profiles';
import { useTelegramChatsCollectionRef } from '../../../common/collections/TelegramChats';
import { useTelegramUsersCollectionRef } from '../../../common/collections/TelegramUsers';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import TelegramChatFragment from '../../fragments/TelegramChat';

export default function AdminProfileVersionTelegramChatScreen() {
  const profilesCollectionRef = useProfilesCollectionRef();
  const telegramUsersCollectionRef = useTelegramUsersCollectionRef();
  const telegramChatsCollectionRef = useTelegramChatsCollectionRef();

  const { profileId } = useParams<{ profileId: string; }>();

  const profileRef = useMemo(
    () => doc(profilesCollectionRef, profileId),
    [profileId, profilesCollectionRef],
  );

  const { snap: telegramUsersSnap } = useQuerySnapshot(
    query(
      telegramUsersCollectionRef,
      where('uid', '==', profileRef.id),
      limit(1),
    ),
  );

  const telegramUserSnap = useMemo(
    () => first(telegramUsersSnap?.docs),
    [telegramUsersSnap?.docs],
  );

  if (telegramUserSnap) {
    return (
      <TelegramChatFragment
        telegramChatRef={doc(telegramChatsCollectionRef, telegramUserSnap.id)}
      />
    );
  }

  return null;
}
