import { Center } from '@chakra-ui/react';
import { StrictMode, Suspense } from 'react';
import 'react-chat-elements/dist/main.css';
import ReactDOM from 'react-dom/client';

import App from './App';
import LogoIcon from './components/LogoIcon';
import './i18n';

if (window.Telegram.WebApp.initData === '' && window.location.host !== 'localhost:3000') {
  window.location.replace('https://t.me/qupidubot?startapp');
} else {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );

  root.render(
    <StrictMode>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <App />
      </Suspense>
    </StrictMode>,
  );
}
