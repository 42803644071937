import {
  Box,
  BoxProps,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { compact } from 'lodash';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { LuBadgeCheck } from 'react-icons/lu';

import { ProfileDoc } from '../../../common/collections/Profiles';
import Catch from '../../../components/Catch';
import PictureCell from '../../../components/PictureCell';
import useDimensions from '../../../hooks/useDimensions';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';

export type Props = {
  onClick: (profileSnap: QueryDocumentSnapshot<ProfileDoc>) => void;
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
} & Omit<BoxProps, 'onClick'>;

export function ProfileCardMain({
  onClick,
  profileSnap,
  ...boxProps
}: Props) {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick(profileSnap);
    },
    [onClick, profileSnap],
  );

  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const { snap: originSnap } = useDocumentSnapshot(profileDoc.originRef);

  const originDoc = useMemo(
    () => originSnap?.data(),
    [originSnap],
  );

  const [ref, { height: h, width: w }] = useDimensions();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <PictureCell
        bottom={0}
        height={h ?? 1600}
        left={0}
        pictureRef={profileDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        top={0}
        width={w ?? 900}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <HStack gap={1}>
            {profileDoc.isVerified ? <Icon as={LuBadgeCheck} boxSize={5} /> : null}

            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {profileDoc.name}
              {', '}
              {profileDoc.age}
            </Heading>
          </HStack>

          {originDoc ? (
            <Text
              fontSize="sm"
              opacity={0.75}
              textAlign="center"
            >
              {compact([
                originDoc.name,
                originDoc.countryName,
              ]).join(', ')}
            </Text>
          ) : null}
        </VStack>
      </VStack>
    </Box>
  );
}

export function ProfileCardLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export default function ProfileCard({ onClick, profileSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <ProfileCardLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <ProfileCardMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          onClick={onClick}
          profileSnap={profileSnap}
        />
      </Suspense>
    </Catch>
  );
}
