import { Firestore } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext } from 'react';

type Props = {
  sdk: Firestore;
};

const Context = createContext<Firestore | null>(null);

export const useFirestore = () => {
  const ctx = useContext(Context);

  if (!ctx) {
    throw new Error();
  }

  return ctx;
};

export default function FirestoreProvider({
  children,
  sdk,
}: PropsWithChildren<Props>) {
  return (
    <Context.Provider value={sdk}>
      {children}
    </Context.Provider>
  );
}
