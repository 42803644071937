import {
  AspectRatio,
  Button,
  Center,
  Grid,
  HStack,
  Icon,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _, { isNil } from 'lodash';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuCopy } from 'react-icons/lu';

import { bodyCountLabels } from '../../../common/BodyCount';
import { ProfileDoc } from '../../../common/collections/Profiles';
import { cupSizeLabels } from '../../../common/CupSize';
import Gender from '../../../common/Gender';
import { hairLengthLabels } from '../../../common/HairLength';
import { pantiesSizeLabels } from '../../../common/PantiesSize';
import { wealthLabels } from '../../../common/Wealth';
import { getTelegramAppLinkData, WebAppActionType } from '../../../common/WebAppAction';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import PictureImage from '../../../components/PictureImage';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';

export type Props = {
  profileSnap: QueryDocumentSnapshot<ProfileDoc>;
};

export function ProfileMain({ profileSnap }: Props) {
  const { t: lt } = useTranslation('Languages');
  const { t: zt } = useTranslation('Zodiac');
  const { t: cst } = useTranslation('CupSize');
  const { t: pst } = useTranslation('PantiesSize');
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');
  const { t: hlt } = useTranslation('HairLength');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: gt } = useTranslation('Goal');
  const { t: st } = useTranslation('Sexuality');
  const { t: bct } = useTranslation('BodyCount');
  const { t: wt } = useTranslation('Wealth');

  const profileDoc = useMemo(() => profileSnap.data(), [profileSnap]);

  const { snap: originSnap } = useDocumentSnapshot(profileDoc.originRef);

  const originDoc = useMemo(
    () => originSnap?.data(),
    [originSnap],
  );

  const handleInstagramClick = useCallback(
    () => {
      if (profileDoc.instagramTag) {
        window.Telegram.WebApp.openLink(`https://www.instagram.com/${profileDoc.instagramTag}`);
      }
    },
    [profileDoc.instagramTag],
  );

  const handleTikTokClick = useCallback(
    () => {
      if (profileDoc.tiktokTag) {
        window.Telegram.WebApp.openLink(`https://www.tiktok.com/@${profileDoc.tiktokTag}`);
      }
    },
    [profileDoc.tiktokTag],
  );

  const handleLinkedInClick = useCallback(
    () => {
      if (profileDoc.linkedinTag) {
        window.Telegram.WebApp.openLink(`https://www.linkedin.com/in/${profileDoc.linkedinTag}`);
      }
    },
    [profileDoc.linkedinTag],
  );

  const { onCopy: onProfileUrlCopy, value: profileUrl } = useClipboard(getTelegramAppLinkData({
    action: WebAppActionType.VIEW_PROFILE,
    profileId: profileSnap.id,
  }));

  return (
    <VStack alignItems="stretch" overflow="auto" p={2}>
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(3, 1fr)">
        {profileDoc.pictureRefs.map((pictureRef) => (
          <AspectRatio key={pictureRef.id} ratio={9 / 16}>
            <PictureImage
              borderRadius="md"
              h="100%"
              objectFit="cover"
              pictureRef={pictureRef}
            />
          </AspectRatio>
        ))}
      </Grid>

      <Table size="sm" variant="simple">
        <Tbody>
          <Tr>
            <Td>
              Profile ID
            </Td>
            <Td>
              {profileSnap.id}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Profile Url
            </Td>
            <Td>
              <HStack>
                <Text
                  as="a"
                  href={profileUrl}
                  maxW="100px"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                >
                  {profileUrl}
                </Text>

                <IconButton
                  aria-label="Copy"
                  icon={<Icon as={LuCopy} />}
                  onClick={onProfileUrlCopy}
                />
              </HStack>
            </Td>
          </Tr>
          <Tr>
            <Td>
              Name
            </Td>
            <Td>
              {profileDoc.name}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Age
            </Td>
            <Td>
              {profileDoc.age}
              y
            </Td>
          </Tr>
          <Tr>
            <Td>
              Height
            </Td>
            <Td>
              {profileDoc.height}
              cm
            </Td>
          </Tr>
          <Tr>
            <Td>
              Weight
            </Td>
            <Td>
              {profileDoc.weight}
              kg
            </Td>
          </Tr>
          {profileDoc.gender === Gender.FEMALE ? (
            <>
              <Tr>
                <Td>
                  Bikini
                </Td>
                <Td>
                  {profileDoc.cupSize ? cst(`${cupSizeLabels[profileDoc.cupSize]}.label`) : '-'}
                  {' / '}
                  {profileDoc.pantiesSize ? pst(`${pantiesSizeLabels[profileDoc.pantiesSize]}.label`) : '-'}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Eyes
                </Td>
                <Td>
                  {profileDoc.eyeColor ? ect(`${profileDoc.eyeColor}.label`) : '-'}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Hair
                </Td>
                <Td>
                  {profileDoc.hairColor ? hct(`${profileDoc.hairColor}.label`) : '-'}
                  {' / '}
                  {profileDoc.hairLength ? hlt(`${hairLengthLabels[profileDoc.hairLength]}.label`) : '-'}
                </Td>
              </Tr>
            </>
          ) : (
            <Tr>
              <Td>
                Wealth
              </Td>
              <Td>
                {profileDoc.wealth ? wt(`${wealthLabels[profileDoc.wealth]}.label`) : '-'}
              </Td>
            </Tr>
          )}
          <Tr>
            <Td>
              Zodiac
            </Td>
            <Td>
              {zt(profileDoc.zodiac)}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Languages
            </Td>
            <Td whiteSpace="normal">
              {profileDoc.languages.map((l) => lt(l)).join(', ')}
            </Td>
          </Tr>

          {profileDoc.goals?.length ? (
            <Tr>
              <Td>
                Goals
              </Td>
              <Td>
                {(profileDoc.goals ?? []).map((r) => gt(`${r}.label`)).join(', ')}
              </Td>
            </Tr>
          ) : null}

          {profileDoc.relationshipStyles?.length ? (
            <Tr>
              <Td>
                Relationship Style
              </Td>
              <Td>
                {(profileDoc.relationshipStyles ?? []).map((r) => rst(`${r}.label`)).join(', ')}
              </Td>
            </Tr>
          ) : null}

          <Tr>
            <Td>
              Sexuality
            </Td>
            <Td>
              {profileDoc.sexuality ? st(`${profileDoc.sexuality}.label`) : '-'}
            </Td>
          </Tr>

          {!isNil(profileDoc.bodyCount) ? (
            <Tr>
              <Td>
                {bct('label')}
              </Td>
              <Td>
                {bct(`${bodyCountLabels[profileDoc.bodyCount]}.label`)}
              </Td>
            </Tr>
          ) : null}

          {originDoc ? (
            <Tr>
              <Td>
                Origin
              </Td>
              <Td whiteSpace="normal">
                {_.compact([
                  originDoc.name,
                  originDoc.countryName,
                ]).join(', ')}
              </Td>
            </Tr>
          ) : null}

          <>
            {profileDoc.instagramTag ? (
              <Tr>
                <Td>
                  Instagram
                </Td>
                <Td>
                  <Button onClick={handleInstagramClick} variant="link">
                    {profileDoc.instagramTag}
                  </Button>
                </Td>
              </Tr>
            ) : null}

            {profileDoc.tiktokTag ? (
              <Tr>
                <Td>
                  TikTok
                </Td>
                <Td>
                  <Button onClick={handleTikTokClick} variant="link">
                    {profileDoc.tiktokTag}
                  </Button>
                </Td>
              </Tr>
            ) : null}

            {profileDoc.linkedinTag ? (
              <Tr>
                <Td>
                  LinkedIn
                </Td>
                <Td>
                  <Button onClick={handleLinkedInClick} variant="link">
                    {profileDoc.linkedinTag}
                  </Button>
                </Td>
              </Tr>
            ) : null}
          </>

          {profileDoc.description ? (
            <Tr>
              <Td colSpan={2}>
                {profileDoc.description}
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
    </VStack>
  );
}

export default function Profile(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
