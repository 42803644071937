import {
  AspectRatio,
  Badge,
  Button,
  Center,
  Grid,
  Table,
  Tbody,
  Td,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import _ from 'lodash';
import moment from 'moment';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileVersionDoc, ProfileVersionStatus } from '../../../common/collections/Profiles';
import { cupSizeLabels } from '../../../common/CupSize';
import dateToZodiac from '../../../common/dateToZodiac';
import Gender from '../../../common/Gender';
import { hairLengthLabels } from '../../../common/HairLength';
import { pantiesSizeLabels } from '../../../common/PantiesSize';
import { wealthLabels } from '../../../common/Wealth';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import PictureImage from '../../../components/PictureImage';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';

export type Props = {
  profileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
};

export function ProfileMain({ profileVersionSnap }: Props) {
  const { t: lt } = useTranslation('Languages');
  const { t: zt } = useTranslation('Zodiac');
  const { t: cst } = useTranslation('CupSize');
  const { t: pst } = useTranslation('PantiesSize');
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');
  const { t: hlt } = useTranslation('HairLength');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: gt } = useTranslation('Goal');
  const { t: st } = useTranslation('Sexuality');
  const { t: wt } = useTranslation('Wealth');

  const profileVersionDoc = useMemo(() => profileVersionSnap.data(), [profileVersionSnap]);

  const { snap: originSnap } = useDocumentSnapshot(profileVersionDoc.originRef);

  const originDoc = useMemo(
    () => originSnap?.data(),
    [originSnap],
  );

  const handleInstagramClick = useCallback(
    () => {
      if (profileVersionDoc.instagramTag) {
        window.Telegram.WebApp.openLink(`https://www.instagram.com/${profileVersionDoc.instagramTag}`);
      }
    },
    [profileVersionDoc.instagramTag],
  );

  const handleTikTokClick = useCallback(
    () => {
      if (profileVersionDoc.tiktokTag) {
        window.Telegram.WebApp.openLink(`https://www.tiktok.com/@${profileVersionDoc.tiktokTag}`);
      }
    },
    [profileVersionDoc.tiktokTag],
  );

  const handleLinkedInClick = useCallback(
    () => {
      if (profileVersionDoc.linkedinTag) {
        window.Telegram.WebApp.openLink(`https://www.linkedin.com/in/${profileVersionDoc.linkedinTag}`);
      }
    },
    [profileVersionDoc.linkedinTag],
  );

  const age = useMemo(() => {
    const birthDate = moment.utc(profileVersionDoc.birthDate).startOf('day');

    return Math.floor(
      moment.duration(
        moment().diff(birthDate),
      ).asYears(),
    );
  }, [profileVersionDoc.birthDate]);

  const zodiac = useMemo(
    () => dateToZodiac(moment.utc(profileVersionDoc.birthDate).toDate()),
    [profileVersionDoc.birthDate],
  );

  return (
    <VStack alignItems="stretch" overflow="auto">
      <Grid autoRows="1fr" gap={1} p={1} templateColumns="repeat(3, 1fr)">
        {profileVersionDoc.pictureRefs.map((pictureRef) => (
          <AspectRatio key={pictureRef.id} ratio={9 / 16}>
            <PictureImage
              borderRadius="md"
              h="100%"
              objectFit="cover"
              pictureRef={pictureRef}
            />
          </AspectRatio>
        ))}
      </Grid>

      <Table size="sm" variant="simple">
        <Tbody>
          <Tr>
            <Td>
              Profile ID
            </Td>
            <Td>
              {profileVersionSnap.ref.parent.parent?.id}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Version ID
            </Td>
            <Td>
              {profileVersionSnap.id}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Sent
            </Td>
            <Td>
              {moment(profileVersionDoc.sentAt.toDate()).calendar()}
            </Td>
          </Tr>

          {profileVersionDoc.withdrawnAt ? (
            <Tr>
              <Td>
                Withdrawn
              </Td>
              <Td>
                {moment(profileVersionDoc.withdrawnAt.toDate()).calendar()}
              </Td>
            </Tr>
          ) : null}

          {profileVersionDoc.rejectedAt ? (
            <Tr>
              <Td>
                Rejected
              </Td>
              <Td>
                {moment(profileVersionDoc.rejectedAt.toDate()).calendar()}
              </Td>
            </Tr>
          ) : null}

          {profileVersionDoc.approvedAt ? (
            <Tr>
              <Td>
                Approved
              </Td>
              <Td>
                {moment(profileVersionDoc.approvedAt.toDate()).calendar()}
              </Td>
            </Tr>
          ) : null}

          {profileVersionDoc.rejectionReason ? (
            <Tr>
              <Td>
                Rejection Reason
              </Td>
              <Td>
                {profileVersionDoc.rejectionReason}
              </Td>
            </Tr>
          ) : null}

          <Tr>
            <Td>
              Status
            </Td>
            <Td>
              {profileVersionDoc.status === ProfileVersionStatus.SENT ? (
                <Badge colorScheme="blue">
                  {profileVersionDoc.status}
                </Badge>
              ) : null}

              {profileVersionDoc.status === ProfileVersionStatus.WITHDRAWN ? (
                <Badge colorScheme="gray">
                  {profileVersionDoc.status}
                </Badge>
              ) : null}

              {profileVersionDoc.status === ProfileVersionStatus.APPROVED ? (
                <Badge colorScheme="green">
                  {profileVersionDoc.status}
                </Badge>
              ) : null}

              {profileVersionDoc.status === ProfileVersionStatus.REJECTED ? (
                <Badge colorScheme="red">
                  {profileVersionDoc.status}
                </Badge>
              ) : null}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Name
            </Td>
            <Td>
              {profileVersionDoc.name}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Age
            </Td>
            <Td>
              {age}
              y
            </Td>
          </Tr>
          <Tr>
            <Td>
              Height
            </Td>
            <Td>
              {profileVersionDoc.height}
              cm
            </Td>
          </Tr>
          <Tr>
            <Td>
              Weight
            </Td>
            <Td>
              {profileVersionDoc.weight}
              kg
            </Td>
          </Tr>
          {profileVersionDoc.gender === Gender.FEMALE ? (
            <>
              <Tr>
                <Td>
                  Bikini
                </Td>
                <Td>
                  {profileVersionDoc.cupSize ? cst(`${cupSizeLabels[profileVersionDoc.cupSize]}.label`) : '-'}
                  {' / '}
                  {profileVersionDoc.pantiesSize ? pst(`${pantiesSizeLabels[profileVersionDoc.pantiesSize]}.label`) : '-'}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Eyes
                </Td>
                <Td>
                  {profileVersionDoc.eyeColor ? ect(`${profileVersionDoc.eyeColor}.label`) : '-'}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  Hair
                </Td>
                <Td>
                  {profileVersionDoc.hairColor ? hct(`${profileVersionDoc.hairColor}.label`) : '-'}
                  {' / '}
                  {profileVersionDoc.hairLength ? hlt(`${hairLengthLabels[profileVersionDoc.hairLength]}.label`) : '-'}
                </Td>
              </Tr>
            </>
          ) : (
            <Tr>
              <Td>
                Wealth
              </Td>
              <Td>
                {profileVersionDoc.wealth ? wt(`${wealthLabels[profileVersionDoc.wealth]}.label`) : '-'}
              </Td>
            </Tr>
          )}
          <Tr>
            <Td>
              Zodiac
            </Td>
            <Td>
              {zodiac ? zt(zodiac) : undefined}
            </Td>
          </Tr>
          <Tr>
            <Td>
              Languages
            </Td>
            <Td whiteSpace="normal">
              {profileVersionDoc.languages.map((l) => lt(l)).join(', ')}
            </Td>
          </Tr>

          {profileVersionDoc.goals?.length ? (
            <Tr>
              <Td>
                Goals
              </Td>
              <Td>
                {(profileVersionDoc.goals ?? []).map((r) => gt(`${r}.label`)).join(', ')}
              </Td>
            </Tr>
          ) : null}

          {profileVersionDoc.relationshipStyles?.length ? (
            <Tr>
              <Td>
                Relationship Style
              </Td>
              <Td>
                {(profileVersionDoc.relationshipStyles ?? []).map((r) => rst(`${r}.label`)).join(', ')}
              </Td>
            </Tr>
          ) : null}

          <Tr>
            <Td>
              Sexuality
            </Td>
            <Td>
              {profileVersionDoc.sexuality ? st(`${profileVersionDoc.sexuality}.label`) : '-'}
            </Td>
          </Tr>

          {originDoc ? (
            <Tr>
              <Td>
                Origin
              </Td>
              <Td whiteSpace="normal">
                {_.compact([
                  originDoc.name,
                  originDoc.countryName,
                ]).join(', ')}
              </Td>
            </Tr>
          ) : null}

          <>
            {profileVersionDoc.instagramTag ? (
              <Tr>
                <Td>
                  Instagram
                </Td>
                <Td>
                  <Button onClick={handleInstagramClick} variant="link">
                    {profileVersionDoc.instagramTag}
                  </Button>
                </Td>
              </Tr>
            ) : null}

            {profileVersionDoc.tiktokTag ? (
              <Tr>
                <Td>
                  TikTok
                </Td>
                <Td>
                  <Button onClick={handleTikTokClick} variant="link">
                    {profileVersionDoc.tiktokTag}
                  </Button>
                </Td>
              </Tr>
            ) : null}

            {profileVersionDoc.linkedinTag ? (
              <Tr>
                <Td>
                  LinkedIn
                </Td>
                <Td>
                  <Button onClick={handleLinkedInClick} variant="link">
                    {profileVersionDoc.linkedinTag}
                  </Button>
                </Td>
              </Tr>
            ) : null}
          </>

          {profileVersionDoc.description ? (
            <Tr>
              <Td colSpan={2}>
                {profileVersionDoc.description}
              </Td>
            </Tr>
          ) : null}
        </Tbody>
      </Table>
    </VStack>
  );
}

export default function Profile(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ProfileMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
