import { httpsCallable } from 'firebase/functions';
import { useMemo } from 'react';

import AppLanguage from '../common/AppLanguage';
import { useFunctions } from '../components/FunctionsProvider';

export type Data = {
  language: AppLanguage;
  telegramChatId: string;
  tripId: string;
};

export type Result = void;

export default function useTelegramSendTrip() {
  const functions = useFunctions();
  return useMemo(() => httpsCallable<Data, Result>(functions, 'telegram-sendTrip'), [functions]);
}
