import {
  Button,
  Container,
  Grid,
  GridItem,
  VStack,
} from '@chakra-ui/react';
import { logEvent, setUserProperties } from 'firebase/analytics';
import {
  deleteField,
  doc,
  getDoc,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import { Formik } from 'formik';
import { latLngToCell } from 'h3-js';
import { compact, uniq } from 'lodash';
import mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import BodyCount from '../../common/BodyCount';
import { isCityRef, useCitiesCollectionRef } from '../../common/collections/Cities';
import { usePicturesCollectionRef } from '../../common/collections/Picture';
import CupSize from '../../common/CupSize';
import dateToZodiac from '../../common/dateToZodiac';
import EyeColor from '../../common/EyeColor';
import Gender from '../../common/Gender';
import Goal from '../../common/Goal';
import HairColor from '../../common/HairColor';
import HairLength from '../../common/HairLength';
import Language from '../../common/Language';
import PantiesSize from '../../common/PantiesSize';
import RelationshipStyle from '../../common/RelationshipStyle';
import Sexuality from '../../common/Sexuality';
import Wealth from '../../common/Wealth';
import { useAnalytics } from '../../components/AnalyticsProvider';
import BodyCountFormControl from '../../components/BodyCountFormControl';
import CityFormControl from '../../components/CityFormControl';
import CupSizeFormControl from '../../components/CupSizeFormControl';
import { useFirestore } from '../../components/FirestoreProvider';
import GoalsFormControl from '../../components/GoalsFormControl';
import HairLengthFormControl from '../../components/HairLengthFormControl';
import { useInsets } from '../../components/InsetsProvider';
import InstagramFormControl from '../../components/InstagramFormControl';
import { useIPData } from '../../components/IPDataProvider';
import LanguagesFormControl from '../../components/LanguagesFormControl';
import LinkedinFormControl from '../../components/LinkedinFormControl';
import NumberFormControl from '../../components/NumberFormControl';
import PantiesSizeFormControl from '../../components/PantiesSizeFormControl';
import PicturesFormControl from '../../components/PicturesFormControl';
import RelationshipStylesFormControl from '../../components/RelationshipStylesFormControl';
import SelectFormControl from '../../components/SelectFormControl';
import SexualityFormControl from '../../components/SexualityFormControl';
import {
  useMyProfileHiddenSnap,
} from '../../components/snapProviders/MyProfileHiddenSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import TextareaFormControl from '../../components/TextareaFormControl';
import TextFormControl from '../../components/TextFormControl';
import TiktokFormControl from '../../components/TiktokFormControl';
import WealthFormControl from '../../components/WealthFormControl';

export default function ProfileForm() {
  const { t } = useTranslation('MyProfileUpdateScreen', { keyPrefix: 'ProfileForm' });
  const { t: ect } = useTranslation('EyeColor');
  const { t: hct } = useTranslation('HairColor');

  const insets = useInsets();

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const myProfileHiddenSnap = useMyProfileHiddenSnap();
  const myProfileHiddenDoc = useMemo(() => myProfileHiddenSnap.data(), [myProfileHiddenSnap]);

  const birthDateText = useCallback(
    (
      value?: string,
    ) => {
      if (!value) {
        return false;
      }

      const current = moment(value);

      if (!current.isValid()) {
        return false;
      }

      const lowest = moment().subtract(16, 'years').startOf('day');
      const highest = moment().subtract(70, 'years').endOf('day');

      const daysOlderThanLowest = moment.duration(moment(lowest).diff(current)).asDays();
      const daysYoungerThanHighest = moment.duration(moment(current).diff(highest)).asDays();

      if (daysOlderThanLowest < 0) {
        return false;
      }

      if (daysYoungerThanHighest < 0) {
        return false;
      }

      return true;
    },
    [],
  );

  const schema = useMemo(
    () => yup.object().shape({
      birthDate: yup
        .string()
        .label(t('birthDate.label'))
        .test('age', birthDateText)
        .required(),
      bodyCount: yup
        .number()
        .label(t('bodyCount.label'))
        .integer()
        .min(BodyCount.VIRGIN)
        .max(BodyCount.THOUSANDS)
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      cupSize: yup
        .number()
        .label(t('cupSize.label'))
        .integer()
        .min(CupSize.AA)
        .max(CupSize.E)
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      description: yup
        .string()
        .label(t('description.label'))
        .trim(),
      eyeColor: yup
        .string()
        .label(t('eyeColor.label'))
        .oneOf(Object.values(EyeColor))
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      gender: yup
        .string()
        .label(t('gender.label'))
        .oneOf(Object.values(Gender))
        .required(),
      goals: yup
        .array(yup
          .string()
          .label(t('goals.item.label'))
          .oneOf(Object.values(Goal))
          .required())
        .label(t('goals.label'))
        .min(1)
        .required(),
      hairColor: yup
        .string()
        .label(t('hairColor.label'))
        .oneOf(Object.values(HairColor))
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      hairLength: yup
        .number()
        .label(t('hairLength.label'))
        .integer()
        .min(HairLength.BUZZ_CUT)
        .max(HairLength.TAILBONE)
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      height: yup
        .number()
        .label(t('height.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
      instagramTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('instagramTag.matchesError'))
        .label(t('instagramTag.label'))
        .required(),
      languages: yup
        .array(yup
          .string()
          .label(t('languages.item.label'))
          .oneOf(Object.values(Language))
          .required())
        .label(t('languages.label'))
        .min(1)
        .required(),
      linkedinTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('linkedinTag.matchesError'))
        .label(t('linkedinTag.label'))
        .optional(),
      name: yup
        .string()
        .label(t('name.label'))
        .trim()
        .required(),
      originId: yup
        .string()
        .label(t('originId.label'))
        .required(),
      pantiesSize: yup
        .number()
        .label(t('pantiesSize.label'))
        .integer()
        .min(PantiesSize.XXS)
        .max(PantiesSize.XXL)
        .when('gender', {
          is: Gender.FEMALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      pictureIds: yup
        .array()
        .label(t('pictureIds.label'))
        .compact()
        .min(3)
        .max(9)
        .required()
        .of(
          yup
            .string()
            .label(t('pictureIds.item.label'))
            .required(),
        ),
      relationshipStyles: yup
        .array()
        .label(t('relationshipStyles.label'))
        .compact()
        .min(1)
        .required()
        .of(
          yup
            .string()
            .label(t('relationshipStyles.item.label'))
            .oneOf(Object.values(RelationshipStyle))
            .required(),
        ),
      sexuality: yup
        .string()
        .label(t('sexuality.label'))
        .oneOf(Object.values(Sexuality))
        .required(),
      tiktokTag: yup
        .string()
        .matches(/^[a-zA-Z0-9._]+$/, t('tiktokTag.matchesError'))
        .label(t('tiktokTag.label'))
        .optional(),
      wealth: yup
        .number()
        .label(t('wealth.label'))
        .integer()
        .min(Wealth.BROKE)
        .max(Wealth.MILLIONAIRE)
        .when('gender', {
          is: Gender.MALE,
          otherwise: (s) => s.transform(() => undefined),
          then: (s) => s.required(),
        }),
      weight: yup
        .number()
        .label(t('weight.label'))
        .integer()
        .min(30)
        .max(300)
        .required(),
    }),
    [t, birthDateText],
  );

  const ipData = useIPData();

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => {
      const ipDataLanguages: Language[] = ipData?.languages.map(
        (l) => (l as unknown as { code: Language }).code,
      ) ?? [];

      const navigatorLanguages: Language[] = ([navigator.language, ...navigator.languages])
        .map((l) => l.split('-')[0] as Language);

      const languages: Language[] = uniq([
        ...ipDataLanguages,
        ...navigatorLanguages,
      ]).sort();

      return {
        birthDate: myProfileHiddenDoc?.birthDate ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? moment().subtract(20, 'years').format('YYYY-MM-DD')
            : moment().subtract(35, 'years').format('YYYY-MM-DD')
        ),
        bodyCount: myProfileDoc?.bodyCount ?? BodyCount.THOUSANDS,
        cupSize: myProfileDoc?.cupSize ?? CupSize.AA,
        description: myProfileDoc?.description ?? '',
        eyeColor: myProfileDoc?.eyeColor ?? EyeColor.BLUE,
        gender: myProfileDoc?.gender ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? Gender.FEMALE
            : Gender.MALE
        ),
        goals: myProfileDoc?.goals ?? [],
        hairColor: myProfileDoc?.hairColor ?? HairColor.WHITE,
        hairLength: myProfileDoc?.hairLength ?? HairLength.BUZZ_CUT,
        height: myProfileDoc?.height ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? 170
            : 180
        ),
        instagramTag: myProfileDoc?.instagramTag ?? '',
        languages: myProfileDoc?.languages ?? languages,
        linkedinTag: myProfileDoc?.linkedinTag ?? '',
        name: myProfileDoc?.name ?? '',
        originId: myProfileDoc?.originRef && isCityRef(myProfileDoc.originRef)
          ? myProfileDoc.originRef.id
          : '',
        pantiesSize: myProfileDoc?.pantiesSize ?? PantiesSize.XXS,
        pictureIds: (myProfileDoc.pictureRefs ?? []).map((r) => r.id),
        relationshipStyles: myProfileDoc?.relationshipStyles ?? [],
        sexuality: myProfileDoc.sexuality ?? Sexuality.STRAIGHT,
        tiktokTag: myProfileDoc?.tiktokTag ?? '',
        wealth: myProfileDoc?.wealth ?? Wealth.BROKE,
        weight: myProfileDoc.weight ?? (
          myProfileDoc?.gender === Gender.FEMALE
            ? 50
            : 80
        ),
      };
    },
    [
      ipData,
      myProfileDoc,
      myProfileHiddenDoc,
    ],
  );

  const firestore = useFirestore();
  const citiesCollectionRef = useCitiesCollectionRef();
  const picturesCollectionRef = usePicturesCollectionRef();

  const analytics = useAnalytics();
  const navigate = useNavigate();

  const handleFormSubmit = useCallback(
    async (values: typeof schema['__outputType']) => {
      const originRef = doc(citiesCollectionRef, values.originId);
      const originSnap = await getDoc(originRef);
      const originDoc = originSnap.data();

      if (!originSnap.exists() || !originDoc) {
        throw new Error('City not found');
      }

      const birthDate = moment.utc(values.birthDate).startOf('day');

      const age = Math.floor(
        moment.duration(
          moment().diff(birthDate),
        ).asYears(),
      );

      const zodiac = dateToZodiac(birthDate.toDate());

      const batch = writeBatch(firestore);

      batch.set(
        myProfileSnap.ref,
        {
          age,
          bodyCount: values.bodyCount,
          cupSize: values.cupSize,
          description: values.description,
          expiresAt: Timestamp.fromMillis(Date.now() + 1000 * 60 * 60 * 24 * 365),
          eyeColor: values.eyeColor,
          gender: values.gender,
          goals: values.goals,
          h3Cell1: originDoc.location
            ? latLngToCell(originDoc.location.latitude, originDoc.location.longitude, 1)
            : undefined,
          h3Cell2: originDoc.location
            ? latLngToCell(originDoc.location.latitude, originDoc.location.longitude, 2)
            : undefined,
          h3Cell3: originDoc.location
            ? latLngToCell(originDoc.location.latitude, originDoc.location.longitude, 3)
            : undefined,
          h3Cell4: originDoc.location
            ? latLngToCell(originDoc.location.latitude, originDoc.location.longitude, 4)
            : undefined,
          h3Cell5: originDoc.location
            ? latLngToCell(originDoc.location.latitude, originDoc.location.longitude, 5)
            : undefined,
          h3Cell6: originDoc.location
            ? latLngToCell(originDoc.location.latitude, originDoc.location.longitude, 6)
            : undefined,
          h3Cell7: originDoc.location
            ? latLngToCell(originDoc.location.latitude, originDoc.location.longitude, 7)
            : undefined,
          hairColor: values.hairColor,
          hairLength: values.hairLength,
          height: values.height,
          instagramTag: typeof values.instagramTag === 'string' && values.instagramTag !== '' ? values.instagramTag : deleteField(),
          languages: values.languages,
          linkedinTag: typeof values.linkedinTag === 'string' && values.linkedinTag !== '' ? values.linkedinTag : deleteField(),
          name: values.name,
          originRef,
          pantiesSize: values.pantiesSize,
          pictureRefs: compact(values.pictureIds).map((id) => doc(picturesCollectionRef, id)),
          relationshipStyles: values.relationshipStyles,
          sexuality: values.sexuality,
          tiktokTag: typeof values.tiktokTag === 'string' && values.tiktokTag !== '' ? values.tiktokTag : deleteField(),
          updatedAt: Timestamp.now(),
          wealth: values.wealth,
          weight: values.weight,
          zodiac,
        },
        { merge: true },
      );

      batch.set(
        myProfileHiddenSnap.ref,
        {
          birthDate: birthDate.format('YYYY-MM-DD'),
        },
        { merge: true },
      );

      await batch.commit();

      mixpanel.people.set({
        $name: values.name,
        age,
        birthDate: birthDate.format('YYYY-MM-DD'),
        gender: values.gender,
        goals: values.goals,
        height: values.height,
        instagramTag: values.instagramTag,
        languages: values.languages,
        linkedinTag: values.linkedinTag,
        sexuality: values.sexuality,
        tiktokTag: values.tiktokTag,
        timezone: originDoc.timezone,
        weight: values.weight,
        zodiac,
      });

      mixpanel.track('My Profile Updated', {
        age,
        birthDate: birthDate.format('YYYY-MM-DD'),
        gender: values.gender,
        goals: values.goals,
        height: values.height,
        instagramTag: values.instagramTag,
        languages: values.languages,
        linkedinTag: values.linkedinTag,
        name: values.name,
        sexuality: values.sexuality,
        tiktokTag: values.tiktokTag,
        timezone: originDoc.timezone,
        weight: values.weight,
        zodiac,
      });

      logEvent(analytics, 'update_profile');
      setUserProperties(analytics, {
        age,
        languages: values.languages,
        sexuality: values.sexuality,
        zodiac,
      });

      navigate('..');
    },
    [
      citiesCollectionRef,
      firestore,
      myProfileSnap.ref,
      myProfileHiddenSnap.ref,
      analytics,
      navigate,
      picturesCollectionRef,
    ],
  );

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validateOnBlur={validateAll}
      validateOnChange={validateAll}
      validationSchema={schema}
    >
      {({
        handleSubmit,
        isSubmitting,
        isValid,
        isValidating,
        values,
      }) => (
        <VStack
          alignItems="stretch"
          as="form"
          flex={1}
          gap={0}
          minH={0}
          noValidate
          onSubmit={(e) => {
            setValidateAll(true);
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Container
            flex={1}
            maxW="lg"
            overflowY="auto"
            py={2}
          >
            <VStack alignItems="stretch" flex={1} gap={4} overflow="auto">
              <PicturesFormControl
                label={t('pictureIds.label')}
                name="pictureIds"
              />

              <TextFormControl
                autoComplete="given-name"
                label={t('name.label')}
                name="name"
                type="text"
              />

              <CityFormControl
                aroundLatLngViaIP
                label={t('originId.label')}
                name="originId"
                placeholder={t('originId.placeholder')}
              />

              <SexualityFormControl
                gender={values.gender}
                label={t('sexuality.label')}
                name="sexuality"
              />

              <RelationshipStylesFormControl
                label={t('relationshipStyles.label')}
                name="relationshipStyles"
              />

              <TextFormControl
                autoComplete="bday"
                label={t('birthDate.label')}
                name="birthDate"
                type="date"
              />

              <Grid gap={4} templateColumns="repeat(2, 1fr)">
                <NumberFormControl
                  label={t('height.label')}
                  max={250}
                  min={150}
                  name="height"
                  right={t('height.unit')}
                  step={1}
                />

                <NumberFormControl
                  label={t('weight.label')}
                  max={120}
                  min={45}
                  name="weight"
                  right={t('weight.unit')}
                  step={1}
                />

                {values.gender === Gender.FEMALE ? (
                  <>
                    <BodyCountFormControl
                      label={t('bodyCount.label')}
                      name="bodyCount"
                    />

                    <CupSizeFormControl
                      label={t('cupSize.label')}
                      name="cupSize"
                    />

                    <PantiesSizeFormControl
                      label={t('pantiesSize.label')}
                      name="pantiesSize"
                    />

                    <SelectFormControl
                      label={t('eyeColor.label')}
                      name="eyeColor"
                      options={{
                        /* eslint-disable perfectionist/sort-objects */
                        [EyeColor.BLUE]: ect('BLUE.label'),
                        [EyeColor.GRAY]: ect('GRAY.label'),
                        [EyeColor.GREEN]: ect('GREEN.label'),
                        [EyeColor.HAZEL]: ect('HAZEL.label'),
                        [EyeColor.AMBER]: ect('AMBER.label'),
                        [EyeColor.BROWN]: ect('BROWN.label'),
                        /* eslint-enable perfectionist/sort-objects */
                      }}
                    />

                    <SelectFormControl
                      label={t('hairColor.label')}
                      name="hairColor"
                      options={{
                        /* eslint-disable perfectionist/sort-objects */
                        [HairColor.WHITE]: hct('WHITE.label'),
                        [HairColor.GRAY]: hct('GRAY.label'),
                        [HairColor.BLONDE]: hct('BLONDE.label'),
                        [HairColor.CHESTNUT]: hct('CHESTNUT.label'),
                        [HairColor.BROWN]: hct('BROWN.label'),
                        [HairColor.RED]: hct('RED.label'),
                        [HairColor.BLACK]: hct('BLACK.label'),
                        [HairColor.OTHER]: hct('OTHER.label'),
                        /* eslint-enable perfectionist/sort-objects */
                      }}
                    />

                    <GridItem colSpan={2}>
                      <HairLengthFormControl
                        label={t('hairLength.label')}
                        name="hairLength"
                      />
                    </GridItem>
                  </>
                ) : (
                  <GridItem colSpan={2}>
                    <WealthFormControl
                      label={t('wealth.label')}
                      name="wealth"
                    />
                  </GridItem>
                )}
              </Grid>

              <GoalsFormControl
                helperText={t('goals.helperText')}
                label={t('goals.label')}
                name="goals"
              />

              <LanguagesFormControl
                label={t('languages.label')}
                name="languages"
                placeholder={t('languages.placeholder')}
              />

              <TextareaFormControl
                label={t('description.label')}
                name="description"
                rows={10}
              />

              <InstagramFormControl
                label={t('instagramTag.label')}
                name="instagramTag"
                type="text"
              />

              <TiktokFormControl
                label={t('tiktokTag.label')}
                name="tiktokTag"
                type="text"
              />

              <LinkedinFormControl
                label={t('linkedinTag.label')}
                name="linkedinTag"
                type="text"
              />
            </VStack>
          </Container>

          <Container
            maxW="lg"
            pb={`max(${insets.bottom}, var(--chakra-space-2))`}
            pt={2}
          >
            <Button
              colorScheme={isValid ? undefined : 'red'}
              isLoading={isValidating || isSubmitting}
              loadingText={isValidating ? t('updateProfileButton.validating') : t('updateProfileButton.loading')}
              type="submit"
              w="100%"
            >
              {isValid ? t('updateProfileButton.default') : t('updateProfileButton.invalid')}
            </Button>
          </Container>
        </VStack>
      )}
    </Formik>
  );
}
