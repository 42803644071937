import { Card, CardBody, Text } from '@chakra-ui/react';
import { Message } from '@grammyjs/types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export type Props = {
  telegramMessage: Message.MigrateToChatIdMessage;
};

export default function MigrateToChatIdMessageRow({ telegramMessage }: Props) {
  const navigate = useNavigate();

  const onClick = useCallback(
    () => navigate(`/admin/telegramChats/${String(telegramMessage.migrate_to_chat_id)}`),
    [navigate, telegramMessage.migrate_to_chat_id],
  );

  return (
    <Card alignSelf="center" fontSize="sm" maxW="sm" onClick={onClick} variant="elevated">
      <CardBody p={2}>
        <Text> Migrated to chat </Text>
      </CardBody>
    </Card>
  );
}
