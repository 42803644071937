export default async (): Promise<void> => {
  if (window.Telegram.WebApp.initDataUnsafe.user?.allows_write_to_pm) {
    return undefined;
  }

  return new Promise<void>((res, rej) => {
    window.Telegram.WebApp.requestWriteAccess((success) => {
      if (success) {
        res();
      } else {
        rej(new Error('Failed to get write access'));
      }
    });
  });
};
