import {
  Button,
  Center,
  Container,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Suspense, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaGoogle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { getProfileHiddenRef, useProfilesCollectionRef } from '../../common/collections/Profiles';
import { useAuth } from '../../components/AuthProvider';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import LogoIcon from '../../components/LogoIcon';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Login from './Login';

const provider = new GoogleAuthProvider();

export function LoginScreenMain() {
  const { t } = useTranslation('LoginScreen');

  const insets = useInsets();

  const auth = useAuth();
  const navigate = useNavigate();
  const showError = useShowError();

  useEffect(
    () => {
      if (auth.currentUser) {
        navigate('/');
      }
    },
    [auth.currentUser, navigate],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const handleGoogleClick = useCallback(
    () => {
      signInWithPopup(auth, provider)
        .then(({ user }) => doc(profilesCollectionRef, user.uid))
        .then((profileRef) => getDoc(getProfileHiddenRef(profileRef)))
        .then(() => fbq('track', 'Lead'))
        .then(() => navigate('/'))
        .catch(showError);
    },
    [auth, navigate, profilesCollectionRef, showError],
  );

  return (
    <VStack alignItems="stretch" gap={4} h="100%" position="relative">
      <Container
        flexShrink={0}
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-4))`}
      >
        <Center>
          <LogoFull h={16} w={48} />
        </Center>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        minH={0}
        overflow="auto"
        py={2}
      >
        <VStack
          alignItems="stretch"
          gap={4}
          h="100%"
          justifyContent="center"
        >
          <Text textAlign="center">
            {t('welcomeText')}
          </Text>
        </VStack>
      </Container>

      <Container
        flexShrink={0}
        pb={`max(${insets.bottom}, var(--chakra-space-4))`}
        pt={2}
      >
        <VStack alignItems="stretch" gap={4}>
          <Login />

          <Button
            leftIcon={<Icon as={FaGoogle} />}
            onClick={handleGoogleClick}
          >
            {t('googleButton.default')}
          </Button>
        </VStack>
      </Container>
    </VStack>
  );
}

export default function LoginScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <LoginScreenMain />
      </Suspense>
    </Catch>
  );
}
