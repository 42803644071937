import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { useFirestore } from '../../components/FirestoreProvider';
import { type ProfileDoc } from './Profiles';

export interface PictureDoc {
  _v: 1;
  avatarUri: string;
  blurHash: string;
  createdAt: Timestamp;
  originUri: string;
  ownerRef: DocumentReference<ProfileDoc>;
  phoneUri: string;
  telegramUri: string;
  updatedAt: Timestamp;
}

export const isPictureDoc = (
  doc?: DocumentData,
): doc is PictureDoc => true;

export const isPictureRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<PictureDoc> => ref.parent.id === 'pictures';

function isPictureSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<PictureDoc>;
function isPictureSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<PictureDoc>;
function isPictureSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<PictureDoc> | QueryDocumentSnapshot<PictureDoc> {
  return isPictureRef(snap.ref);
}

export { isPictureSnap };

export const getPicturesCollectionRef = (firestore: Firestore): CollectionReference<PictureDoc> => collection(firestore, 'pictures') as CollectionReference<PictureDoc>;

export const usePicturesCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getPicturesCollectionRef(firestore), [firestore]);
};
