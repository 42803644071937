import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  GeoPoint,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { useFirestore } from '../../components/FirestoreProvider';
import { type ContinentDoc } from './Continent';
import { type CountryDoc } from './Countries';
import { type CurrencyDoc } from './Currency';

export interface CityDoc {
  _v: 1;
  activities?: string[];
  continentName?: string;
  continentRef?: DocumentReference<ContinentDoc>;
  countryEmoji?: string;
  countryName?: string;
  countryRef?: DocumentReference<CountryDoc>;
  currencyName?: string;
  currencyRef?: DocumentReference<CurrencyDoc>;
  iataCode: string;
  labels?: string[];
  location?: GeoPoint;
  name?: string;
  timezone?: string;
  updatedAt: Timestamp;
}

export const isCityDoc = (
  data?: DocumentData,
): data is CityDoc => true;

export const isCityRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<CityDoc> => ref.parent.id === 'cities';

function isCitySnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<CityDoc>;
function isCitySnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<CityDoc>;
function isCitySnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<CityDoc> | QueryDocumentSnapshot<CityDoc> {
  return isCityRef(snap.ref);
}

export { isCitySnap };

export const useCitiesCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => collection(firestore, 'cities') as CollectionReference<CityDoc>, [firestore]);
};
