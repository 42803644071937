import {
  Center,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { doc, QueryDocumentSnapshot } from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TripDoc, useTripsCollectionRef } from '../../common/collections/Trips';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import LogoIcon from '../../components/LogoIcon';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import TripForm from './TripForm';

export type PropsMain = {
  tripSnap: QueryDocumentSnapshot<TripDoc>;
};

export function TripUpdateScreenMain({ tripSnap }: PropsMain) {
  const navigate = useNavigate();
  useEffect(
    () => {
      const onClick = () => navigate('..');

      window.Telegram.WebApp.BackButton.onClick(onClick);
      window.Telegram.WebApp.BackButton.show();

      return () => {
        window.Telegram.WebApp.BackButton.hide();
        window.Telegram.WebApp.BackButton.offClick(onClick);
      };
    },
    [navigate],
  );

  const insets = useInsets();

  const onComplete = useCallback(
    () => {
      navigate(`/trips/${tripSnap.id}`);
    },
    [navigate, tripSnap.id],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <TripForm
          onComplete={onComplete}
          tripSnap={tripSnap}
        />
      </Container>
    </VStack>
  );
}

export default function TripUpdateScreen() {
  const { tripId } = useParams<{ tripId: string }>();

  const tripsCollectionRef = useTripsCollectionRef();
  const tripRef = useMemo(
    () => doc(tripsCollectionRef, tripId),
    [tripId, tripsCollectionRef],
  );

  const { snap: tripSnap } = useDocumentSnapshot(tripRef);

  if (!tripSnap) {
    return null;
  }

  if (!tripSnap.exists()) {
    return null;
  }

  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <TripUpdateScreenMain tripSnap={tripSnap} />
      </Suspense>
    </Catch>
  );
}
