import {
  Box,
  Center,
  Container,
  Grid,
  Text,
} from '@chakra-ui/react';
import {
  getDocs,
  limit,
  query,
  QueryDocumentSnapshot,
  startAfter,
  where,
} from 'firebase/firestore';
import {
  Suspense,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AutoSizer,
  GridCellProps,
  Index,
  IndexRange,
  InfiniteLoader,
  Grid as VirtualizedGrid,
} from 'react-virtualized';

import {
  useVenturesCollectionRef,
  VentureDoc,
  VentureStatus,
} from '../../common/collections/Ventures';
import Catch from '../../components/Catch';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Venture, { VentureLoading } from './Venture';

export function VentureGridLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" h="100%" templateColumns="repeat(2, 1fr)">
      <VentureLoading />
      <VentureLoading />
      <VentureLoading />
      <VentureLoading />
    </Grid>
  );
}

export function VentureGridMain() {
  const { t } = useTranslation('TheirVenturesScreen', { keyPrefix: 'VentureList' });

  const venturesCollectionRef = useVenturesCollectionRef();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ventureSnaps, setVentureSnaps] = useState<QueryDocumentSnapshot<VentureDoc>[]>([]);

  const showError = useShowError();
  useEffect(
    () => {
      getDocs(
        query(
          venturesCollectionRef,
          where('status', '==', VentureStatus.PUBLISHED),
          limit(40),
        ),
      )
        .then((snap) => {
          setVentureSnaps(snap.docs);
          setIsLoading(false);
        })
        .catch(showError);
    },
    [showError, venturesCollectionRef],
  );

  const isRowLoaded = useCallback(
    ({ index }: Index) => ventureSnaps.length > index * 2,
    [ventureSnaps.length],
  );

  const loadMoreRows = useCallback(
    async ({ startIndex, stopIndex }: IndexRange) => {
      const nextVenturesSnap = await getDocs(
        query(
          venturesCollectionRef,
          where('status', '==', VentureStatus.PUBLISHED),
          limit((stopIndex - startIndex + 1) * 2),
          startAfter(ventureSnaps[ventureSnaps.length - 1]),
        ),
      );

      setVentureSnaps([...ventureSnaps, ...nextVenturesSnap.docs]);
    },
    [ventureSnaps, venturesCollectionRef],
  );

  const renderCell = useCallback(
    ({
      columnIndex,
      key,
      rowIndex,
      style,
    }: GridCellProps) => {
      const ventureSnap = ventureSnaps[rowIndex * 2 + columnIndex];

      if (!ventureSnap) {
        return null;
      }

      return (
        <Box key={key} p={2} style={style}>
          <Venture
            scrollSnapAlign="center"
            ventureSnap={ventureSnap}
          />
        </Box>
      );
    },
    [ventureSnaps],
  );

  if (isLoading) {
    return (
      <VentureGridLoading />
    );
  }

  if (ventureSnaps.length) {
    return (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            minimumBatchSize={10}
            rowCount={10000}
          >
            {({ onRowsRendered, registerChild }) => (
              <VirtualizedGrid
                cellRenderer={renderCell}
                columnCount={2}
                columnWidth={width / 2}
                height={height}
                onSectionRendered={({
                  rowStartIndex: startIndex, rowStopIndex: stopIndex,
                }) => onRowsRendered({ startIndex, stopIndex })}
                overscanColumnCount={0}
                overscanRowCount={3}
                ref={registerChild}
                rowCount={Math.ceil(ventureSnaps.length / 2)}
                rowHeight={(width / 18) * 16}
                width={width}
              />
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function VentureGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<VentureGridLoading />}>
        <VentureGridMain />
      </Suspense>
    </Catch>
  );
}
