import { Card, CardBody, Text } from '@chakra-ui/react';
import { Message } from '@grammyjs/types';
import { first } from 'lodash';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export type Props = {
  telegramMessage: Message.NewChatMembersMessage;
};

export default function NewChatMembersMessageRow({ telegramMessage }: Props) {
  const navigate = useNavigate();

  const onClick = useCallback(
    () => navigate(`telegramUsers/${first(telegramMessage.new_chat_members)?.id ?? ''}`),
    [navigate, telegramMessage.new_chat_members],
  );

  return (
    <Card alignSelf="center" fontSize="sm" maxW="sm" onClick={onClick} variant="elevated">
      <CardBody p={2}>
        <Text>
          {`New chat members: ${telegramMessage.new_chat_members.map(({ first_name }) => first_name).join(', ')}`}
        </Text>
      </CardBody>
    </Card>
  );
}
