/* eslint-disable perfectionist/sort-enums */
enum BodyCount {
  VIRGIN = 0, // No sexual partners
  SINGLE_DIGIT = 1, // 1 to 9 partners
  DOZENS = 10, // 10 to 99 partners
  HUNDREDS = 100, // 100 to 999 partners
  THOUSANDS = 1000, // 1000+ partners
}

export const bodyCountLabels = [
  'VIRGIN',
  'SINGLE_DIGIT',
  'DOZENS',
  'HUNDREDS',
  'THOUSANDS',
];

export default BodyCount;
