import { Card, CardBody, Text } from '@chakra-ui/react';
import { Message } from '@grammyjs/types';

export type Props = {
  telegramMessage: Message.GroupChatCreatedMessage;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function GroupChatCreatedMessageRow({ telegramMessage }: Props) {
  return (
    <Card alignSelf="center" fontSize="sm" maxW="sm" variant="elevated">
      <CardBody p={2}>
        <Text>
          Group chat created
        </Text>
      </CardBody>
    </Card>
  );
}
