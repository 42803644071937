import {
  Box,
  BoxProps,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';

import AppLanguage from '../../../common/AppLanguage';
import { TripDoc } from '../../../common/collections/Trips';
import Catch from '../../../components/Catch';
import PictureImage from '../../../components/PictureImage';
import i18n from '../../../i18n';

export type Props = {
  onClick: (tripSnap: QueryDocumentSnapshot<TripDoc>) => Promise<void>;
  tripSnap: QueryDocumentSnapshot<TripDoc>;
} & Omit<BoxProps, 'onClick'>;

export function TripMain({
  onClick,
  tripSnap,
  ...boxProps
}: Props) {
  const { t } = useTranslation('TheirTripsScreen', { keyPrefix: 'Trip' });

  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onClick(tripSnap)
        .catch(() => { });
    },
    [onClick, tripSnap],
  );

  const tripDoc = useMemo(() => tripSnap.data(), [tripSnap]);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
    >
      <PictureImage
        bottom={0}
        left={0}
        objectFit="cover"
        pictureRef={tripDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        scrollSnapAlign="start"
        scrollSnapStop="always"
        top={0}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {/* eslint-disable-next-line max-len */}
              {(tripDoc.translations && tripDoc.translations[i18n.language as AppLanguage]?.name) ?? tripDoc.name}
            </Heading>
          </Container>

          <Text
            fontSize="sm"
            opacity={0.75}
          >
            {t('dates.value', {
              endsAt: tripDoc.endsAt.toDate(),
              formatParams: {
                endsAt: { day: 'numeric', month: 'short', year: 'numeric' },
                startsAt: { day: 'numeric', month: 'short' },
              },
              startsAt: tripDoc.startsAt.toDate(),
            })}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}

export default function Trip({ tripSnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <TripMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          tripSnap={tripSnap}
        />
      </Suspense>
    </Catch>
  );
}
