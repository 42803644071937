import {
  Box,
  BoxProps,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import { compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { VentureDoc } from '../../../common/collections/Ventures';
import Catch from '../../../components/Catch';
import PictureImage from '../../../components/PictureImage';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';

export type MainProps = {
  ventureSnap: QueryDocumentSnapshot<VentureDoc>;
} & BoxProps;

export function VentureMain({
  ventureSnap, ...boxProps
}: MainProps) {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Venture Clicked');

      window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');

      navigate(`/ventures/${ventureSnap.id}`);
    },
    [navigate, ventureSnap.id],
  );

  const ventureDoc = useMemo(() => ventureSnap.data(), [ventureSnap]);

  const { snap: citySnap } = useDocumentSnapshot(ventureDoc.cityRef);

  const cityDoc = useMemo(
    () => citySnap?.data(),
    [citySnap],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
    >
      <PictureImage
        bottom={0}
        left={0}
        objectFit="cover"
        pictureRef={ventureDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        scrollSnapAlign="start"
        scrollSnapStop="always"
        top={0}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Heading
            as="h1"
            fontSize="sm"
            fontWeight="bold"
            textAlign="center"
          >
            {ventureDoc.name}
          </Heading>

          {cityDoc ? (
            <Text
              fontSize="xs"
              opacity={0.75}
              textAlign="center"
            >
              {compact([
                cityDoc.name,
                cityDoc.countryName,
              ]).join(', ')}
            </Text>
          ) : null}
        </VStack>
      </VStack>
    </Box>
  );
}

export function VentureLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export type Props = {
  ventureRef: DocumentReference<VentureDoc>;
} & BoxProps;

export default function Venture({ ventureRef, ...boxProps }: Props) {
  const { snap: ventureSnap } = useDocumentSnapshot(ventureRef);

  if (!ventureSnap?.exists()) {
    return (
      <VentureLoading
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...boxProps}
      />
    );
  }

  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <VentureLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <VentureMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          ventureSnap={ventureSnap}
        />
      </Suspense>
    </Catch>
  );
}
