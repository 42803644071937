import {
  Button,
  Container,
  Heading,
  HStack,
  Skeleton,
  VStack,
} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ensureWriteAccess from '../../common/ensureWriteAccess';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import VentureGrid, { VentureGridLoading } from './VentureGrid';

export function VenturesScreenMain() {
  const { i18n, t } = useTranslation('TheirVenturesScreen');

  const insets = useInsets();

  const myProfileRef = useMyProfileRef();
  const showError = useShowError();
  const [isBecomeSponsorLoading, setBecomeSponsorLoading] = useState<boolean>(false);
  const onBecomeSponsorClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setBecomeSponsorLoading(true);

      mixpanel.track('Become Sponsor Clicked');

      (async () => {
        await ensureWriteAccess();

        const calendlyUrl = new URL(`https://calendly.com/qupidu/exclusive-consultation-${i18n.language}`);
        calendlyUrl.searchParams.set('a1', myProfileRef.id);

        window.Telegram.WebApp.openLink(calendlyUrl.toString());
      })()
        .finally(() => setBecomeSponsorLoading(false))
        .catch(showError);
    },
    [i18n.language, myProfileRef.id, showError],
  );

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <Heading fontSize="2xl">
            Ventures
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflow="auto"
      >
        <VentureGrid />
      </Container>

      <Container
        maxW="lg"
        py={2}
      >
        <Button
          className="venturesSponsorButton"
          colorScheme="indigo"
          isLoading={isBecomeSponsorLoading}
          onClick={onBecomeSponsorClick}
          width="100%"
        >
          {t('becomeSponsorButton.default')}
        </Button>
      </Container>
    </VStack>
  );
}

export function VenturesScreenLoading() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack gap={4}>
          <Skeleton flex={1} height={10} />
        </HStack>
      </Container>

      <Container
        flex={1}
        height="100%"
        maxW="lg"
        overflowY="auto"
        style={{
          paddingBottom: `calc(${insets.bottom} + var(--chakra-space-4))`,
        }}
      >
        <VentureGridLoading />
      </Container>
    </VStack>
  );
}

export default function VenturesScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<VenturesScreenLoading />}>
        <VenturesScreenMain />
      </Suspense>
    </Catch>
  );
}
