import { Icon, IconButton, VStack } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { LuMessagesSquare } from 'react-icons/lu';
import { useMatch, useNavigate } from 'react-router-dom';

export default function TelegramChatsButton() {
  const { t } = useTranslation('AdminLayout');

  const match = useMatch('/admin/telegramChats/*');

  const navigate = useNavigate();
  const onClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('rigid');
      navigate('/admin/telegramChats');
    },
    [navigate],
  );

  return (
    <VStack alignItems="center" spacing={0}>
      <IconButton
        aria-label={t('telegramChatsButton.default')}
        className="telegramChatsButton"
        icon={<Icon as={LuMessagesSquare} boxSize={6} />}
        isActive={!!match}
        isRound
        onClick={onClick}
        size="lg"
        variant="ghost"
      />
    </VStack>
  );
}
