import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Locale } from 'react-joyride';

export default function useJoyrideLocale() {
  const { t } = useTranslation('Tour');

  return useMemo<Locale>(() => ({
    back: t('back'),
    close: t('close'),
    last: t('last'),
    next: t('next'),
    nextLabelWithProgress: t('nextLabelWithProgress'),
    open: t('open'),
    skip: t('skip'),
  }), [t]);
}
