import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { bodyCountLabels } from '../common/BodyCount';
import { cupSizeLabels } from '../common/CupSize';
import { pantiesSizeLabels } from '../common/PantiesSize';
import Preferences from '../common/Preferences';

export type Props = {
  preferences: {
    females?: Preferences;
    males?: Preferences;
  };
};

export function PreferencesTableMain({
  preferences,
}: Props) {
  const { t: cst } = useTranslation('CupSize');
  const { t: bct } = useTranslation('BodyCount');
  const { t: pst } = useTranslation('PantiesSize');
  const { t: rst } = useTranslation('RelationshipStyle');
  const { t: st } = useTranslation('Sexuality');
  const { t: lt } = useTranslation('Languages');

  return (
    <Table size="sm" variant="simple">
      <Thead>
        <Th>
          Preference
        </Th>

        {preferences.females ? (
          <Th>
            Females
          </Th>
        ) : null}

        {preferences.males ? (
          <Th>
            Males
          </Th>
        ) : null}
      </Thead>

      <Tbody>
        {preferences.females?.age || preferences.males?.age
          ? (
            <Tr>
              <Th>
                Age
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.age ? (
                    <>
                      {preferences.females.age.min}
                      {' - '}
                      {preferences.females.age.max}
                      y
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td>
                  {preferences.males?.age ? (
                    <>
                      {preferences.males.age.min}
                      {' - '}
                      {preferences.males.age.max}
                      y
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.cupSize
          ? (
            <Tr>
              <Th>
                {cst('label')}
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.cupSize ? (
                    <>
                      {cst(`${cupSizeLabels[preferences.females.cupSize.min]}.label`)}
                      {' - '}
                      {cst(`${cupSizeLabels[preferences.females.cupSize.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td />
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.pantiesSize
          ? (
            <Tr>
              <Th>
                {pst('label')}
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.pantiesSize ? (
                    <>
                      {pst(`${pantiesSizeLabels[preferences.females.pantiesSize.min]}.label`)}
                      {' - '}
                      {pst(`${pantiesSizeLabels[preferences.females.pantiesSize.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td />
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.height || preferences.males?.height
          ? (
            <Tr>
              <Th>
                Height
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.height ? (
                    <>
                      {preferences.females.height.min}
                      {' - '}
                      {preferences.females.height.max}
                      cm
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td>
                  {preferences.males?.height ? (
                    <>
                      {preferences.males.height.min}
                      {' - '}
                      {preferences.males.height.max}
                      cm
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.weight || preferences.males?.weight
          ? (
            <Tr>
              <Th>
                Weight
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.weight ? (
                    <>
                      {preferences.females.weight.min}
                      {' - '}
                      {preferences.females.weight.max}
                      kg
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td>
                  {preferences.males?.weight ? (
                    <>
                      {preferences.males.weight.min}
                      {' - '}
                      {preferences.males.weight.max}
                      kg
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.bodyCount || preferences.males?.bodyCount
          ? (
            <Tr>
              <Th>
                {bct('label')}
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.bodyCount ? (
                    <>
                      {bct(`${bodyCountLabels[preferences.females.bodyCount.min]}.label`)}
                      {' - '}
                      {bct(`${bodyCountLabels[preferences.females.bodyCount.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td>
                  {preferences.males?.bodyCount ? (
                    <>
                      {bct(`${bodyCountLabels[preferences.males.bodyCount.min]}.label`)}
                      {' - '}
                      {bct(`${bodyCountLabels[preferences.males.bodyCount.max]}.label`)}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.sexualities || preferences.males?.sexualities
          ? (
            <Tr>
              <Th>
                {st('label')}
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.sexualities ? (
                    <>
                      {preferences.females.sexualities.map((sexuality) => st(`${sexuality}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td>
                  {preferences.males?.sexualities ? (
                    <>
                      {preferences.males.sexualities.map((sexuality) => st(`${sexuality}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.relationshipStyles || preferences.males?.relationshipStyles
          ? (
            <Tr>
              <Th>
                {rst('label')}
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.relationshipStyles ? (
                    <>
                      {preferences.females.relationshipStyles.map((relationshipStyle) => rst(`${relationshipStyle}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td>
                  {preferences.males?.relationshipStyles ? (
                    <>
                      {preferences.males.relationshipStyles.map((relationshipStyle) => rst(`${relationshipStyle}.label`)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}

        {preferences.females?.languages || preferences.males?.languages
          ? (
            <Tr>
              <Th>
                {lt('label')}
              </Th>

              {preferences.females ? (
                <Td>
                  {preferences.females?.languages ? (
                    <>
                      {preferences.females.languages.map((language) => lt(language)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}

              {preferences.males ? (
                <Td>
                  {preferences.males?.languages ? (
                    <>
                      {preferences.males.languages.map((language) => lt(language)).join(', ')}
                    </>
                  ) : null}
                </Td>
              ) : null}
            </Tr>
          )
          : null}
      </Tbody>
    </Table>
  );
}

export default function PreferencesTable(props: Props) {
  return (
    <Suspense fallback={null}>
      <PreferencesTableMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
