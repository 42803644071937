import {
  Box,
  Center,
  Container,
  HStack,
  Icon,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  arrayUnion,
  doc,
  refEqual,
  Timestamp,
  writeBatch,
} from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuAlertTriangle } from 'react-icons/lu';
import { useParams } from 'react-router-dom';

import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../common/collections/Applications';
import { useProfilesCollectionRef } from '../../common/collections/Profiles';
import { isTripRef } from '../../common/collections/Trips';
import { isVentureRef } from '../../common/collections/Ventures';
import Gender from '../../common/Gender';
import Catch from '../../components/Catch';
import { useFirestore } from '../../components/FirestoreProvider';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import LogoIcon from '../../components/LogoIcon';
import ProfileCard from '../../components/ProfileCard';
import { useMyProfileRef } from '../../components/refProviders/MyProfileRefProvider';
import { TelegramMainButton } from '../../components/TelegramMainButton';
import useDimensions from '../../hooks/useDimensions';
import useDocumentSnapshot from '../../hooks/useDocumentSnapshot';
import useShowError from '../../hooks/useShowError';
import ErrorFallbackScreen from '../ErrorFallbackScreen';

export function ApplicationScreenMain() {
  const { t } = useTranslation('ApplicationScreen');

  const { applicationId } = useParams<{ applicationId: string }>();

  const insets = useInsets();

  const [ref, { height, width }] = useDimensions();

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const applicationRef = useMemo(
    () => doc(applicationsCollectionRef, applicationId),
    [applicationId, applicationsCollectionRef],
  );

  const { snap: applicationSnap } = useDocumentSnapshot(applicationRef);

  const applicationDoc = useMemo(
    () => applicationSnap?.data(),
    [applicationSnap],
  );

  const { snap: applicantSnap } = useDocumentSnapshot(applicationDoc?.applicantRef);

  const applicantDoc = useMemo(
    () => applicantSnap?.data(),
    [applicantSnap],
  );

  const { snap: subjectSnap } = useDocumentSnapshot(applicationDoc?.subjectRef);

  const subjectDoc = useMemo(
    () => subjectSnap?.data(),
    [subjectSnap],
  );

  const firestore = useFirestore();
  const showError = useShowError();
  const [isAccepting, setIsAccepting] = useState(false);
  const handleAcceptClick = useCallback(
    () => {
      if (
        !applicationSnap?.exists()
        || !applicationDoc
        || !applicantSnap?.exists()
        || !applicantDoc
      ) {
        return;
      }

      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setIsAccepting(true);

      const batch = writeBatch(firestore);

      batch.set(
        applicationSnap.ref,
        {
          acceptedAt: Timestamp.now(),
          status: ApplicationStatus.ACCEPTED,
        },
        { merge: true },
      );

      switch (applicantDoc.gender) {
        case Gender.FEMALE: {
          batch.set(
            applicationDoc.subjectRef,
            { femaleParticipants: arrayUnion(applicantSnap.ref) },
            { merge: true },
          );
          break;
        }
        case Gender.MALE: {
          batch.set(
            applicationDoc.subjectRef,
            { maleParticipants: arrayUnion(applicantSnap.ref) },
            { merge: true },
          );
          break;
        }
        default: {
          throw new Error('Unknown Gender');
        }
      }

      batch
        .commit()
        .finally(() => setIsAccepting(true))
        .catch(showError);

      if (isTripRef(applicationDoc.subjectRef)) {
        mixpanel.track('Trip Application Accepted', {
          applicantId: applicationDoc.applicantRef.id,
          applicationId: applicationSnap.id,
          organizerId: applicationDoc.organizerRef.id,
          tripId: applicationDoc.subjectRef.id,
        });
      }

      if (isVentureRef(applicationDoc.subjectRef)) {
        mixpanel.track('Venture Application Accepted', {
          applicantId: applicationDoc.applicantRef.id,
          applicationId: applicationSnap.id,
          organizerId: applicationDoc.organizerRef.id,
          ventureId: applicationDoc.subjectRef.id,
        });
      }
    },
    [applicantDoc, applicantSnap, applicationDoc, applicationSnap, firestore, showError],
  );

  const myProfileRef = useMyProfileRef();
  const profilesCollectionRef = useProfilesCollectionRef();
  const isAdmin = useMemo(
    () => refEqual(doc(profilesCollectionRef, '1Cnp6jjyn5Z6aHdH2ZlnFmJZrWv1'), myProfileRef),
    [myProfileRef, profilesCollectionRef],
  );

  const isOrganizer = useMemo(
    () => subjectDoc?.organizerRef && refEqual(subjectDoc.organizerRef, myProfileRef),
    [myProfileRef, subjectDoc?.organizerRef],
  );

  if (!applicantSnap || (!applicantSnap.exists() && applicantSnap.metadata.fromCache)) {
    return (
      <Center h="100%">
        <LogoIcon boxSize={16} />
      </Center>
    );
  }

  if (!applicantSnap?.exists()) {
    return (
      <Center h="100%">
        <VStack>
          <Icon as={LuAlertTriangle} boxSize={10} />
          <Text>Profile not found</Text>
        </VStack>
      </Center>
    );
  }

  return (
    <Box
      h="100%"
      position="relative"
    >
      <Box
        left={0}
        position="absolute"
        right={0}
        top={`max(${insets.top}, var(--chakra-space-2))`}
        zIndex={50}
      >
        <Container maxW="lg">
          <HStack h={12} justifyContent="center">
            <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
          </HStack>
        </Container>
      </Box>

      <InsetsProvider
        bottom={`max(${insets.bottom}, var(--chakra-space-2))`}
        left={insets.left}
        right={insets.right}
        top={`calc(max(${insets.top}, var(--chakra-space-2)) + var(--chakra-space-10))`}
      >
        <Container h="100%" maxW="lg" px={0} ref={ref}>
          {height && width ? (
            <ProfileCard
              height={height}
              profileSnap={applicantSnap}
              showPrivate={isOrganizer || isAdmin}
              width={width}
            />
          ) : null}
        </Container>
      </InsetsProvider>

      {(isOrganizer || isAdmin) && applicationDoc?.status === ApplicationStatus.SENT ? (
        <TelegramMainButton
          color="#9C27B0"
          hasShineEffect
          isLoading={isAccepting}
          onClick={handleAcceptClick}
          text={t('acceptButton.default')}
          textColor="#FFFFFF"
        />
      ) : null}
    </Box>
  );
}

export default function ApplicationScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ApplicationScreenMain />
      </Suspense>
    </Catch>
  );
}
