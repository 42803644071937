import {
  Box,
  Center,
  Container,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Catch from '../../components/Catch';
import InsetsProvider, { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import AllowancesButton from './AllowancesButton';
import JobsButton from './JobsButton';
import ProfileButton from './ProfileButton';
import TripsButton from './TripsButton';
import VenturesButton from './VenturesButton';

export function MainLayoutMain() {
  const insets = useInsets();

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Box flex={1} minH={0} overflow="hidden">
        <InsetsProvider
          bottom="var(--chakra-space-2)"
          left={insets.left}
          right={insets.right}
          top={insets.top}
        >
          <Outlet />
        </InsetsProvider>
      </Box>

      <Container
        flexShrink={0}
        maxW="lg"
        pb={`max(${insets.bottom}, var(--chakra-space-2))`}
        pt={2}
      >
        <HStack gap={2} justifyContent="space-between">
          <ProfileButton />
          <TripsButton />
          <VenturesButton />
          <AllowancesButton />
          <JobsButton />
        </HStack>
      </Container>
    </VStack>
  );
}

export default function MainLayout() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <MainLayoutMain />
      </Suspense>
    </Catch>
  );
}
