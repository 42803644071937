import {
  Box,
  BoxProps,
  Container,
  Heading,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import { compact } from 'lodash';
import moment from 'moment';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';

import { ProfileVersionDoc } from '../../../common/collections/Profiles';
import Catch from '../../../components/Catch';
import PictureCell from '../../../components/PictureCell';
import useDimensions from '../../../hooks/useDimensions';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';

export type Props = {
  onClick: (profileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>) => void,
  profileVersionSnap: QueryDocumentSnapshot<ProfileVersionDoc>;
} & Omit<BoxProps, 'onClick'>;

export function ProfileVersionCardMain({
  onClick,
  profileVersionSnap,
  ...boxProps
}: Props) {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      onClick(profileVersionSnap);
    },
    [onClick, profileVersionSnap],
  );

  const profileDoc = useMemo(() => profileVersionSnap.data(), [profileVersionSnap]);

  const { snap: originSnap } = useDocumentSnapshot(profileDoc.originRef);

  const originDoc = useMemo(
    () => originSnap?.data(),
    [originSnap],
  );

  const [ref, { height: h, width: w }] = useDimensions();

  const age = useMemo(() => {
    const birthDate = moment.utc(profileDoc.birthDate).startOf('day');

    return Math.floor(
      moment.duration(
        moment().diff(birthDate),
      ).asYears(),
    );
  }, [profileDoc.birthDate]);

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <PictureCell
        bottom={0}
        height={h ?? 1600}
        left={0}
        pictureRef={profileDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        top={0}
        width={w ?? 900}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="sm"
              fontWeight="bold"
              textAlign="center"
            >
              {profileDoc.name}
              {', '}
              {age}
            </Heading>
          </Container>

          {originDoc ? (
            <Text
              fontSize="xs"
              opacity={0.75}
              textAlign="center"
            >
              {compact([
                originDoc.name,
                originDoc.countryName,
              ]).join(', ')}
            </Text>
          ) : null}
        </VStack>
      </VStack>
    </Box>
  );
}

export function ProfileVersionCardLoading({ ...boxProps }: BoxProps) {
  return (
    <Skeleton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
    />
  );
}

export default function ProfileVersionCard({
  onClick,
  profileVersionSnap,
  ...boxProps
}: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <ProfileVersionCardLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <ProfileVersionCardMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          onClick={onClick}
          profileVersionSnap={profileVersionSnap}
        />
      </Suspense>
    </Catch>
  );
}
