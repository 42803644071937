import {
  Box,
  Center,
  CircularProgress,
  Container,
  Heading,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  UnorderedList,
  VStack,
} from '@chakra-ui/react';
import _ from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Suspense, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LuPen, LuUser } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

import Gender from '../../common/Gender';
import Catch from '../../components/Catch';
import { useInsets } from '../../components/InsetsProvider';
import LogoIcon from '../../components/LogoIcon';
import PictureAvatar from '../../components/PictureAvatar';
import {
  useMyProfileHiddenSnap,
} from '../../components/snapProviders/MyProfileHiddenSnapProvider';
import { useMyProfileSnap } from '../../components/snapProviders/MyProfileSnapProvider';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import TripGrid from './TripGrid';

export function MyProfileScreenMain() {
  const insets = useInsets();

  const { t } = useTranslation('MyProfileScreen');

  const myProfileSnap = useMyProfileSnap();
  const myProfileDoc = useMemo(() => myProfileSnap.data(), [myProfileSnap]);

  const myProfileHiddenSnap = useMyProfileHiddenSnap();
  const myProfileHiddenDoc = useMemo(() => myProfileHiddenSnap.data(), [myProfileHiddenSnap]);

  const colorScheme = useMemo(
    () => {
      if (myProfileDoc.gender === Gender.FEMALE) {
        return 'deepOrange';
      }

      if (myProfileDoc.gender === Gender.MALE) {
        return 'teal';
      }

      return 'gray';
    },
    [myProfileDoc.gender],
  );

  const navigate = useNavigate();
  const handleUpdateClick = useCallback(
    () => {
      navigate('update');
      mixpanel.track('My Profile Update Clicked');
    },
    [navigate],
  );
  const handlePreviewClick = useCallback(
    () => {
      navigate('/update');
      mixpanel.track('My Profile Preview Clicked');
    },
    [navigate],
  );

  return (
    <VStack
      alignItems="stretch"
      gap={0}
      h="100%"
      pl={insets.left}
      pr={insets.right}
    >
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <Heading fontSize="2xl">
            Profile
          </Heading>
        </HStack>
      </Container>

      <Container
        flex={1}
        maxW="lg"
        overflowY="auto"
        py={2}
      >
        <VStack alignItems="stretch" gap={8}>
          <VStack alignItems="center" gap={4}>
            <Box boxSize={64} position="relative">
              <CircularProgress
                capIsRound
                color={`${colorScheme}.400`}
                isIndeterminate={typeof myProfileDoc.score !== 'number'}
                position="absolute"
                size={64}
                thickness={4}
                trackColor={`rgb(from var(--chakra-colors-${colorScheme}-400) r g b / 0.25)`}
                transform="rotate(180deg)"
                transformOrigin="center center"
                value={myProfileDoc.score}
                zIndex={-10}
              />

              <PictureAvatar
                boxSize={48}
                icon={<Icon as={LuUser} />}
                loading="lazy"
                margin={8}
                onClick={handlePreviewClick}
                pictureRef={myProfileDoc.pictureRefs[0]}
                position="absolute"
                size="2xl"
              />

              <Popover>
                <PopoverTrigger>
                  <HStack
                    bottom={0}
                    cursor="pointer"
                    justifyContent="center"
                    left={0}
                    position="absolute"
                    right={0}
                  >
                    <Box
                      background={`${colorScheme}.400`}
                      borderRadius="xl"
                      px={4}
                      py={2}
                    >
                      {typeof myProfileDoc.score === 'number' ? (
                        <Text color="var(--chakra-colors-gray-800)" fontSize="xl" fontWeight="bold">
                          {myProfileDoc.score}
                          %
                          {' '}
                          {myProfileDoc.gender === Gender.FEMALE ? 'HOT' : ''}
                          {myProfileDoc.gender === Gender.MALE ? 'RICH' : ''}
                        </Text>
                      ) : (
                        <Text color="var(--chakra-colors-gray-800)" fontSize="xl" fontWeight="bold">
                          Analyzing...
                        </Text>
                      )}
                    </Box>
                  </HStack>
                </PopoverTrigger>

                <PopoverContent>
                  <PopoverArrow />
                  <PopoverBody>
                    {myProfileHiddenDoc?.profileSuggestions ? (
                      <UnorderedList flex={1} fontSize="sm">
                        {myProfileHiddenDoc.profileSuggestions.map((s, i) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <ListItem key={i}>{s}</ListItem>
                        ))}
                      </UnorderedList>
                    ) : null}
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              <IconButton
                aria-label={t('updateProfileButton.default')}
                borderRadius="full"
                colorScheme={colorScheme}
                icon={<Icon as={LuPen} />}
                onClick={handleUpdateClick}
                position="absolute"
                right={6}
                size="lg"
                top={6}
              />
            </Box>

            <HStack gap={4}>
              <Text fontSize="3xl" fontWeight="bold" lineHeight={1}>
                {_.compact([myProfileDoc.name, myProfileDoc.age]).join(', ')}
              </Text>
            </HStack>
          </VStack>

          <TripGrid />
        </VStack>
      </Container>
    </VStack>
  );
}

export default function MyProfileScreen() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <MyProfileScreenMain />
      </Suspense>
    </Catch>
  );
}
