import {
  Box,
  Center,
  HStack,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import Catch from '../../../components/Catch';
import InsetsProvider, { useInsets } from '../../../components/InsetsProvider';
import LogoIcon from '../../../components/LogoIcon';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import ProfileButton from '../../../screens/MainLayout/ProfileButton';
import InterviewsButton from './InterviewsButton';
import ProfilesButton from './ProfilesButton';
import ProfileVersionsButton from './ProfileVersionsButton';
import TelegramChatsButton from './TelegramChatsButton';
import TripsButton from './TripsButton';

export function AdminLayoutMain() {
  const insets = useInsets();

  return (
    <HStack alignItems="stretch" gap={0} h="100%">
      <VStack gap={2} justifyContent="space-between">
        <TelegramChatsButton />
        <ProfilesButton />
        <ProfileVersionsButton />
        <TripsButton />
        <InterviewsButton />

        <Spacer />

        <ProfileButton />
      </VStack>

      <Box flex={1} minH={0} overflow="hidden">
        <InsetsProvider
          bottom="var(--chakra-space-2)"
          left={insets.left}
          right={insets.right}
          top={insets.top}
        >
          <Outlet />
        </InsetsProvider>
      </Box>
    </HStack>
  );
}

export default function AdminLayout() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <AdminLayoutMain />
      </Suspense>
    </Catch>
  );
}
