import {
  AspectRatio,
  Box,
  BoxProps,
  Button,
  Center,
  Container,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Link,
  ListItem,
  Text,
  UnorderedList,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { animated, useScroll, useSpring } from '@react-spring/web';
import {
  arrayUnion,
  doc,
  DocumentReference,
  getCountFromServer,
  onSnapshot,
  query,
  QueryDocumentSnapshot,
  refEqual,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { clamp, compact } from 'lodash';
import mixpanel from 'mixpanel-browser';
import {
  CSSProperties,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LuHeart, LuMapPin, LuSend } from 'react-icons/lu';
import Markdown, { Components } from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import remarkGfm from 'remark-gfm';

import AppLanguage from '../../../common/AppLanguage';
import { AllowanceDoc } from '../../../common/collections/Allowances';
import {
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../../common/collections/Applications';
import { getProfileHiddenRef, ProfileDoc } from '../../../common/collections/Profiles';
import ensureWriteAccess from '../../../common/ensureWriteAccess';
import { useInsets } from '../../../components/InsetsProvider';
import LogoFull from '../../../components/LogoFull';
import LogoIcon from '../../../components/LogoIcon';
import PictureCell from '../../../components/PictureCell';
import PictureImage from '../../../components/PictureImage';
import PreferencesTable from '../../../components/PreferencesTable';
import { useMyProfileRef } from '../../../components/refProviders/MyProfileRefProvider';
import { TelegramMainButton } from '../../../components/TelegramMainButton';
import useTelegramPrepareAllowanceMessage from '../../../functions/useTelegramPrepareAllowanceMessage';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';
import useShowError from '../../../hooks/useShowError';
import useWindowDimensions from '../../../hooks/useWindowDimensions';

/* eslint-disable react/jsx-props-no-spreading */
const components: Components = {
  a: ({ node, ...props }) => (<Link isExternal {...props} />),
  h1: ({ node, ...props }) => (<Heading as="h1" {...props} />),
  h2: ({ node, ...props }) => (<Heading as="h2" {...props} />),
  h3: ({ node, ...props }) => (<Heading as="h3" {...props} />),
  li: ({ node, ...props }) => (<ListItem {...props} />),
  p: ({ node, ...props }) => (<Text as="p" {...props} />),
  strong: ({ node, ...props }) => (<Text as="strong" {...props} />),
  ul: ({ node, ...props }) => (<UnorderedList {...props} />),
};
/* eslint-enable react/jsx-props-no-spreading */

export type Props = {
  allowanceSnap: QueryDocumentSnapshot<AllowanceDoc>;
} & BoxProps;

function useApplicationStatus(
  allowanceRef: DocumentReference<AllowanceDoc>,
  profileRef: DocumentReference<ProfileDoc> | undefined,
): boolean {
  const [
    currentVentureRef,
    setCurrentVentureRef,
  ] = useState<DocumentReference<AllowanceDoc>>(allowanceRef);

  const [
    currentProfileRef,
    setCurrentProfileRef,
  ] = useState<DocumentReference<ProfileDoc> | undefined>(profileRef);

  useEffect(
    () => {
      if (!refEqual(allowanceRef, currentVentureRef)) {
        setCurrentVentureRef(allowanceRef);
      }
    },
    [currentVentureRef, allowanceRef],
  );

  useEffect(
    () => {
      if (!profileRef) {
        setCurrentProfileRef(undefined);
      } else if (!currentProfileRef) {
        setCurrentProfileRef(profileRef);
      } else if (!refEqual(profileRef, currentProfileRef)) {
        setCurrentProfileRef(profileRef);
      }
    },
    [currentProfileRef, profileRef],
  );

  const [applicationExists, setApplicationExists] = useState<boolean>(false);

  const applicationsCollectionRef = useApplicationsCollectionRef();

  useEffect(
    () => {
      if (currentProfileRef) {
        return onSnapshot(
          query(
            applicationsCollectionRef,
            where('applicantRef', '==', currentProfileRef),
            where('subjectRef', '==', currentVentureRef),
          ),
          (snap) => {
            setApplicationExists(snap.docs.length > 0);
          },
        );
      }

      setApplicationExists(false);
      return () => { };
    },
    [applicationsCollectionRef, currentProfileRef, currentVentureRef],
  );

  return applicationExists;
}

declare global {
  interface Window {
    Telegram: {
      WebApp: {
        contentSafeAreaInset: {
          top: number;
        };
        safeAreaInset: {
          top: number;
        };
        shareMessage: (msg_id: string, callback?: (success: boolean) => void) => void;
      };
    };
  }
}

export function AllowanceRowMain({
  allowanceSnap, ...boxProps
}: Props) {
  const { i18n, t } = useTranslation('AllowanceScreen', { keyPrefix: 'Allowance' });

  useEffect(
    () => {
      window.Telegram.WebApp.disableVerticalSwipes();

      return () => {
        window.Telegram.WebApp.enableVerticalSwipes();
      };
    },
    [],
  );

  const myProfileRef = useMyProfileRef();

  const { snap: myProfileSnap } = useDocumentSnapshot(myProfileRef);

  const applicationsCollectionRef = useApplicationsCollectionRef();

  const [applicationsCount, setApplicationsCount] = useState<number>(0);
  useEffect(
    () => {
      getCountFromServer(
        query(
          applicationsCollectionRef,
          where('subjectRef', '==', allowanceSnap.ref),
        ),
      )
        .then((data) => setApplicationsCount(data.data().count))
        .catch(() => { });
    },
    [applicationsCollectionRef, allowanceSnap.ref],
  );

  const showError = useShowError();
  const navigate = useNavigate();
  const toast = useToast();
  const [isApplying, setIsApplying] = useState<boolean>(false);
  const onApplyClick = useCallback(
    () => {
      window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');

      setIsApplying(true);

      (async () => {
        mixpanel.track('Allowance Apply Clicked');

        if (!myProfileSnap?.exists()) {
          try {
            await setDoc(getProfileHiddenRef(myProfileRef), {
              nextSubjectRefs: arrayUnion(allowanceSnap.ref),
            }, { merge: true });
          } catch (err) {
            /* do nothing */
          }

          navigate('/welcome');
          return;
        }

        try {
          await ensureWriteAccess();
        } catch (err) {
          /* do nothing */
        }

        const allowanceDoc = allowanceSnap.data();

        const applicationRef = doc(applicationsCollectionRef);

        await setDoc(
          applicationRef,
          {
            _v: 1,
            applicantRef: myProfileRef,
            organizerRef: allowanceDoc.organizerRef,
            sentAt: Timestamp.now(),
            status: ApplicationStatus.SENT,
            subjectRef: allowanceSnap.ref,
          },
        );

        mixpanel.track('Allowance Application Sent', {
          allowanceId: allowanceSnap.id,
          applicantId: myProfileRef.id,
          applicationId: applicationRef.id,
          organizerId: allowanceDoc.organizerRef.id,
        });

        toast({
          isClosable: true,
          status: 'success',
          title: t('applyButton.success'),
        });
      })()
        .finally(() => setIsApplying(false))
        .catch(showError);
    },
    [
      applicationsCollectionRef,
      myProfileRef,
      myProfileSnap,
      navigate,
      showError,
      t,
      toast,
      allowanceSnap,
    ],
  );

  const applicationExists = useApplicationStatus(allowanceSnap.ref, myProfileRef);

  const allowanceDoc = useMemo(() => allowanceSnap.data(), [allowanceSnap]);

  const { snap: citySnap } = useDocumentSnapshot(allowanceDoc.cityRef);
  const cityDoc = useMemo(() => citySnap?.data(), [citySnap]);

  const insets = useInsets();

  const { height: wh, width: ww } = useWindowDimensions();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const containerRef = useRef<HTMLDivElement>(null!);

  const [coverStyles, coverApi] = useSpring(() => ({
    y: 0,
  }));

  const [notchStyles, notchApi] = useSpring(() => ({
    height: 0,
  }));

  useScroll({
    container: containerRef,
    default: {
      immediate: true,
    },
    onChange: ({ value: { scrollY } }) => {
      coverApi.start({
        config: {
          clamp: true,
          friction: 20,
          tension: 300,
        },
        y: -(clamp(scrollY as number, 0, window.Telegram.WebApp.viewportHeight)) / 2,
      });

      notchApi.start({
        config: {
          clamp: true,
          friction: 20,
          tension: 300,
        },
        height: clamp(
          scrollY as number,
          window.Telegram.WebApp.viewportHeight / 4,
          window.Telegram.WebApp.viewportHeight / 4
          + Math.max(window.Telegram.WebApp.safeAreaInset.top, 8)
          + 48,
        ) - window.Telegram.WebApp.viewportHeight / 4,
      });
    },
  });

  const prepareAllowanceMessage = useTelegramPrepareAllowanceMessage();
  const [preparingAllowanceMessage, setPreparingAllowanceMessage] = useState<boolean>(false);
  const handleShare = useCallback(
    () => {
      if (!window.Telegram.WebApp.initDataUnsafe.user) {
        return;
      }

      setPreparingAllowanceMessage(true);
      prepareAllowanceMessage({
        allowanceId: allowanceSnap.id,
        language: i18n.language as AppLanguage,
        telegramUserId: window.Telegram.WebApp.initDataUnsafe.user.id,
      })
        .finally(() => { setPreparingAllowanceMessage(false); })
        .then(({ data: { id } }) => { window.Telegram.WebApp.shareMessage(id); })
        .catch(showError);
    },
    [i18n.language, prepareAllowanceMessage, showError, allowanceSnap.id],
  );

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      h="100%"
      position="relative"
    >
      <HStack
        h={12}
        justifyContent="center"
        left={insets.left}
        position="absolute"
        right={insets.right}
        top={`max(${insets.top}, var(--chakra-space-2))`}
        zIndex={100}
      >
        <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
      </HStack>

      <Box
        as={animated.div}
        backgroundColor="var(--chakra-colors-chakra-body-bg)"
        left={0}
        position="absolute"
        right={0}
        style={notchStyles as unknown as CSSProperties}
        top={0}
        zIndex={90}
      />

      <PictureCell
        as={animated.div}
        height={wh}
        pictureRef={allowanceDoc.pictureRefs[0]}
        position="absolute"
        style={coverStyles as unknown as CSSProperties}
        width={ww}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        gap={0}
        h="100%"
        overflowY="auto"
        ref={containerRef}
      >
        <VStack
          alignItems="stretch"
          flexGrow={0}
          flexShrink={0}
          h="calc(var(--tg-viewport-height) - 100px)"
          justifyContent="end"
        >
          <VStack
            alignItems="center"
            background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
            gap={2}
            py={10}
          >
            <Container maxW="lg">
              <Heading
                as="h1"
                fontSize="3xl"
                fontWeight="bold"
                textAlign="center"
              >
                {/* eslint-disable-next-line max-len */}
                {(allowanceDoc.translations && allowanceDoc.translations[i18n.language as AppLanguage]?.name) ?? allowanceDoc.name}
              </Heading>
            </Container>

            {cityDoc ? (
              <Text
                opacity={0.75}
              >
                {compact([
                  cityDoc.name,
                  cityDoc.countryName,
                ]).join(', ')}
              </Text>
            ) : null}

            <Button
              isLoading={preparingAllowanceMessage}
              leftIcon={<Icon as={LuSend} />}
              loadingText={t('shareButton.loading')}
              onClick={handleShare}
              size="sm"
              variant="outline"
            >
              {t('shareButton.default')}
            </Button>
          </VStack>
        </VStack>

        <Box
          backgroundColor="var(--chakra-colors-chakra-body-bg)"
          flexGrow={0}
          flexShrink={0}
          pb={`max(${insets.bottom}, var(--chakra-space-2))`}
        >
          <Container maxW="lg">
            <VStack
              alignItems="stretch"
              gap={4}
            >
              <Markdown components={components} remarkPlugins={[remarkGfm]}>
                {/* eslint-disable-next-line max-len */}
                {(allowanceDoc.translations && allowanceDoc.translations[i18n.language as AppLanguage]?.description) ?? allowanceDoc.description}
              </Markdown>

              <Grid gap={1} templateColumns="repeat(2, auto)">
                <HStack gap={1} justifySelf="start" maxW="100%" minW={0} opacity={0.75}>
                  <Icon as={LuMapPin} boxSize={3} />
                  <Text
                    fontSize="sm"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {compact([cityDoc?.name, cityDoc?.countryName]).join(', ')}
                  </Text>
                </HStack>

                <HStack gap={1} justifySelf="end" opacity={0.75}>
                  <Icon as={LuHeart} boxSize={3} />
                  <Text
                    fontSize="sm"
                    whiteSpace="nowrap"
                  >
                    {t('applicationsCount', { count: applicationsCount * 7 })}
                  </Text>
                </HStack>
              </Grid>

              {allowanceDoc.pictureRefs.length ? (
                <Grid
                  autoRows="1fr"
                  gap={2}
                  templateColumns="repeat(3, 1fr)"
                >
                  {allowanceDoc.pictureRefs.map((pictureRef) => (
                    <GridItem key={pictureRef.id}>
                      <AspectRatio ratio={9 / 16}>
                        <PictureImage
                          borderRadius="md"
                          h="100%"
                          objectFit="cover"
                          pictureRef={pictureRef}
                        />
                      </AspectRatio>
                    </GridItem>
                  ))}

                  {new Array(
                    Math.ceil(
                      allowanceDoc.pictureRefs.length / 3,
                    ) * 3 - allowanceDoc.pictureRefs.length,
                  )
                    .fill(null)
                    .map((_, i) => (
                      <Box
                        _dark={{
                          backgroundColor: 'rgba(255, 255, 255, 0.05)',
                        }}
                        _light={{
                          backgroundColor: 'rgba(0, 0, 0, 0.05)',
                        }}
                        borderRadius="md"
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                      />
                    ))}
                </Grid>
              ) : null}

              {allowanceDoc.preferences ? (
                <PreferencesTable preferences={allowanceDoc.preferences} />
              ) : null}

              {!applicationExists ? (
                <TelegramMainButton
                  color="#E91E63"
                  hasShineEffect
                  isLoading={isApplying}
                  onClick={onApplyClick}
                  text={t('applyButton.default')}
                  textColor="#FFFFFF"
                />
              ) : null}
            </VStack>
          </Container>
        </Box>
      </VStack>
    </Box>
  );
}

export default function AllowanceRow({ allowanceSnap, ...boxProps }: Props) {
  return (
    <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
      <AllowanceRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
        {...boxProps}
        allowanceSnap={allowanceSnap}
      />
    </Suspense>
  );
}
