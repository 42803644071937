import { AspectRatio, Grid } from '@chakra-ui/react';
import {
  orderBy,
  query,
  QueryDocumentSnapshot,
  where,
} from 'firebase/firestore';
import { Suspense, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { TripDoc, TripStatus, useTripsCollectionRef } from '../../../common/collections/Trips';
import Catch from '../../../components/Catch';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import TripCard, { TripCardLoading } from './TripCard';

export function TripGridLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" h="100%" templateColumns="repeat(2, 1fr)">
      <TripCardLoading />
      <TripCardLoading />
      <TripCardLoading />
      <TripCardLoading />
    </Grid>
  );
}

export function TripGridMain() {
  const tripsCollectionRef = useTripsCollectionRef();

  const { snap: tripsSnap } = useQuerySnapshot(
    query(
      tripsCollectionRef,
      where('status', '==', TripStatus.PUBLISHED),
      orderBy('startsAt', 'asc'),
    ),
  );

  const navigate = useNavigate();
  const handleClick = useCallback(
    (tripSnap: QueryDocumentSnapshot<TripDoc>) => {
      navigate(`/admin/trips/${tripSnap.id}`);
    },
    [navigate],
  );

  if (tripsSnap?.docs.length) {
    return (
      <Grid gap={2} gridAutoRows="1fr" p={2} templateColumns="repeat(3, 1fr)">
        {tripsSnap.docs.map((tripSnap) => (
          <AspectRatio key={tripSnap.id} ratio={9 / 16}>
            <TripCard
              onClick={handleClick}
              tripSnap={tripSnap}
            />
          </AspectRatio>
        ))}
      </Grid>
    );
  }

  return (
    <TripGridLoading />
  );
}

export default function TripGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<TripGridLoading />}>
        <TripGridMain />
      </Suspense>
    </Catch>
  );
}
