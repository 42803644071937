import {
  AvatarGroup,
  Box,
  BoxProps,
  Container,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react';
import { QueryDocumentSnapshot } from 'firebase/firestore';
import mixpanel from 'mixpanel-browser';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AppLanguage from '../../../common/AppLanguage';
import { KeyDoc } from '../../../common/collections/Keys';
import Catch from '../../../components/Catch';
import PictureCell from '../../../components/PictureCell';
import ProfileAvatar from '../../../components/ProfileAvatar';
import useDimensions from '../../../hooks/useDimensions';
import i18n from '../../../i18n';

export type Props = {
  keySnap: QueryDocumentSnapshot<KeyDoc>;
} & BoxProps;

export function KeyRowMain({
  keySnap, ...boxProps
}: Props) {
  const { t } = useTranslation('TheirKeysScreen', { keyPrefix: 'Key' });

  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      mixpanel.track('Key Clicked');

      navigate(`/keys/${keySnap.id}`);
    },
    [keySnap.id, navigate],
  );

  const keyDoc = useMemo(() => keySnap.data(), [keySnap]);

  const [ref, { height: h, width: w }] = useDimensions();

  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
      borderRadius="lg"
      h="100%"
      onClick={handleClick}
      overflow="hidden"
      position="relative"
      ref={ref}
    >
      <PictureCell
        bottom={0}
        height={h ?? 1600}
        left={0}
        pictureRef={keyDoc.pictureRefs[0]}
        position="absolute"
        right={0}
        top={0}
        width={w ?? 900}
        zIndex={-10}
      />

      <VStack
        alignItems="stretch"
        bottom={0}
        flexGrow={0}
        flexShrink={0}
        justifyContent="end"
        left={0}
        position="absolute"
        right={0}
        top={0}
      >
        <VStack
          alignItems="center"
          background="linear-gradient(0deg, var(--chakra-colors-chakra-body-bg) 0%, rgba(0, 0, 0, 0) 100%);"
          gap={1}
          py={5}
        >
          <AvatarGroup max={6} size="xs">
            {keyDoc.femaleParticipants.map((participantRef) => (
              <ProfileAvatar key={participantRef.id} profileRef={participantRef} />
            ))}

            {keyDoc.maleParticipants.map((participantRef) => (
              <ProfileAvatar key={participantRef.id} profileRef={participantRef} />
            ))}
          </AvatarGroup>

          <Container maxW="lg">
            <Heading
              as="h1"
              fontSize="xl"
              fontWeight="bold"
              textAlign="center"
            >
              {/* eslint-disable-next-line max-len */}
              {(keyDoc.translations && keyDoc.translations[i18n.language as AppLanguage]?.name) ?? keyDoc.name}
            </Heading>
          </Container>

          <Text
            fontSize="sm"
            opacity={0.75}
          >
            {t('dates.value', {
              endsAt: keyDoc.endsAt.toDate(),
              formatParams: {
                endsAt: { day: 'numeric', month: 'short', year: 'numeric' },
                startsAt: { day: 'numeric', month: 'short' },
              },
              startsAt: keyDoc.startsAt.toDate(),
            })}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}

export function KeyRowLoading({ ...boxProps }: BoxProps) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...boxProps}
    >
      Loading...
    </Box>
  );
}

export default function KeyRow({ keySnap, ...boxProps }: Props) {
  return (
    <Catch fallback={null}>
      <Suspense fallback={(
        <KeyRowLoading
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
        />
      )}
      >
        <KeyRowMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...boxProps}
          keySnap={keySnap}
        />
      </Suspense>
    </Catch>
  );
}
