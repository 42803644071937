import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import type Preferences from '../Preferences';

import { useFirestore } from '../../components/FirestoreProvider';
import AppLanguage from '../AppLanguage';
import { type CityDoc } from './Cities';
import { type PictureDoc } from './Picture';
import { type ProfileDoc } from './Profiles';

export enum AllowanceStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}

export interface AllowanceDoc {
  _v: 1;
  cityRef: DocumentReference<CityDoc>;
  createdAt: Timestamp;
  description: string;
  name: string;
  organizerRef: DocumentReference<ProfileDoc>;
  pictureRefs: DocumentReference<PictureDoc>[];
  preferences?: {
    females?: Preferences;
    males?: Preferences;
  };
  publishedAt?: Timestamp;
  status: AllowanceStatus;
  translations?: Partial<Record<AppLanguage, Partial<Pick<AllowanceDoc, 'description' | 'name'>>>>;
  unpublishedAt?: Timestamp;
  updatedAt: Timestamp;
}

export const isAllowanceDoc = (
  data?: DocumentData,
): data is AllowanceDoc => true;

export const isAllowanceRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<AllowanceDoc> => ref.parent.id === 'allowances';

function isAllowanceSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<AllowanceDoc>;
function isAllowanceSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<AllowanceDoc>;
function isAllowanceSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<AllowanceDoc> | QueryDocumentSnapshot<AllowanceDoc> {
  return isAllowanceRef(snap.ref);
}

export { isAllowanceSnap };

export const getAllowancesCollectionRef = (firestore: Firestore) => collection(firestore, 'allowances') as CollectionReference<AllowanceDoc>;

export const useAllowancesCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getAllowancesCollectionRef(firestore), [firestore]);
};
