import { Card, CardBody, Text } from '@chakra-ui/react';
import { Message } from '@grammyjs/types';

export type Props = {
  telegramMessage: Message.WriteAccessAllowedMessage;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function WriteAccessAllowedMessageRow({ telegramMessage }: Props) {
  return (
    <Card alignSelf="center" fontSize="sm" maxW="sm" variant="elevated">
      <CardBody p={2}>
        <Text>
          Write access allowed
        </Text>
      </CardBody>
    </Card>
  );
}
