import { Box, Center } from '@chakra-ui/react';
import { Suspense } from 'react';

import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';

export type Props = {
  instagramTag: string;
};

export function InstagramMain({ instagramTag }: Props) {
  return (
    <Box as="iframe" h="100%" src={`https://www.instagram.com/${instagramTag}/embed`} w="100%" />
  );
}

export default function Instagram(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <InstagramMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
