import { Container, HStack, VStack } from '@chakra-ui/react';
import { doc } from 'firebase/firestore';
import { Formik } from 'formik';
import { compact } from 'lodash';
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { usePicturesCollectionRef } from '../../common/collections/Picture';
import { ProfileVersionDoc } from '../../common/collections/Profiles';
import { useInsets } from '../../components/InsetsProvider';
import LogoFull from '../../components/LogoFull';
import PicturesFormControl from '../../components/PicturesFormControl';
import { TelegramMainButton } from '../../components/TelegramMainButton';

export type Props = {
  data: Pick<ProfileVersionDoc, 'pictureRefs'> | undefined;
  onComplete: (data: Pick<ProfileVersionDoc, 'pictureRefs'>) => void;
};

export function PicturesFormMain({ data, onComplete }: Props) {
  const { t } = useTranslation('WelcomeScreen', { keyPrefix: 'ProfileForm' });

  const schema = useMemo(
    () => yup.object().shape({
      pictureIds: yup
        .array()
        .label(t('pictureIds.label'))
        .compact()
        .min(3)
        .max(9)
        .required()
        .of(
          yup
            .string()
            .label(t('pictureIds.item.label'))
            .required(),
        ),
    }),
    [t],
  );

  const initialValues = useMemo<typeof schema['__outputType']>(
    () => ({
      pictureIds: (data?.pictureRefs ?? []).map((ref) => ref.id),
    }),
    [data],
  );

  const picturesCollectionRef = usePicturesCollectionRef();

  const handleFormSubmit = useCallback(
    (values: typeof schema['__outputType']) => {
      onComplete(
        {
          pictureRefs: compact(values.pictureIds).map((id) => doc(picturesCollectionRef, id)),
        },
      );
    },
    [onComplete, picturesCollectionRef],
  );

  const insets = useInsets();

  const [validateAll, setValidateAll] = useState<boolean>(false);

  return (
    <VStack alignItems="stretch" gap={0} h="100%">
      <Container
        maxW="lg"
        pb={2}
        pt={`max(${insets.top}, var(--chakra-space-2))`}
      >
        <HStack h={12} justifyContent="center">
          <LogoFull h="36px" mr="-32px" mt="-12px" w="108px" />
        </HStack>
      </Container>

      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validateOnBlur={validateAll}
        validateOnChange={validateAll}
        validationSchema={schema}
      >
        {({
          handleSubmit,
          isSubmitting,
          isValid,
          isValidating,
        }) => (
          <VStack
            alignItems="stretch"
            as="form"
            flex={1}
            gap={0}
            minH={0}
            noValidate
            onSubmit={(e) => {
              setValidateAll(true);
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Container
              flex={1}
              maxW="lg"
              overflowY="auto"
              py={2}
            >
              <VStack alignItems="stretch" flex={1} gap={8} overflow="auto">
                <PicturesFormControl
                  helperText={t('pictureIds.helperText')}
                  label={t('pictureIds.label')}
                  name="pictureIds"
                />
              </VStack>
            </Container>

            <TelegramMainButton
              color={isValid ? '#424242' : '#F44336'}
              isLoading={isValidating || isSubmitting}
              onClick={() => {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
                setValidateAll(true);
                handleSubmit();
              }}
              text={isValid ? t('nextButton.default') : t('nextButton.invalid')}
              textColor="#FFFFFF"
            />
          </VStack>
        )}
      </Formik>
    </VStack>
  );
}

export default function PicturesForm(props: Props) {
  return (
    <Suspense fallback={null}>
      <PicturesFormMain
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </Suspense>
  );
}
