import { Avatar, AvatarProps, Icon } from '@chakra-ui/react';
import { doc, DocumentReference, QueryDocumentSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import {
  MouseEvent,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LuUser } from 'react-icons/lu';
import { useNavigate } from 'react-router-dom';

import { useProfilesCollectionRef } from '../../../common/collections/Profiles';
import { TelegramUserDoc } from '../../../common/collections/TelegramUsers';
import Catch from '../../../components/Catch';
import PictureAvatar from '../../../components/PictureAvatar';
import { useStorage } from '../../../components/StorageProvider';
import useDocumentSnapshot from '../../../hooks/useDocumentSnapshot';

export type MainProps = {
  telegramUserSnap: QueryDocumentSnapshot<TelegramUserDoc>;
} & AvatarProps;

export function ProfileMain({
  telegramUserSnap, ...avatarProps
}: MainProps) {
  const navigate = useNavigate();
  const handleClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      navigate(`telegramUsers/${telegramUserSnap.id}`);
    },
    [navigate, telegramUserSnap.id],
  );

  const telegramUserDoc = useMemo(() => telegramUserSnap.data(), [telegramUserSnap]);

  const storage = useStorage();
  const [pictureUrl, setPictureUrl] = useState<string>();
  useEffect(
    () => {
      if (telegramUserDoc.pictureStorageUri) {
        getDownloadURL(ref(storage, telegramUserDoc.pictureStorageUri))
          .then(setPictureUrl)
          .catch(() => { });
      }
    },
    [storage, telegramUserDoc.pictureStorageUri],
  );

  const profilesCollectionRef = useProfilesCollectionRef();
  const profileRef = useMemo(
    () => (telegramUserDoc?.uid ? doc(profilesCollectionRef, telegramUserDoc.uid) : undefined),
    [profilesCollectionRef, telegramUserDoc?.uid],
  );

  const { snap: profileSnap } = useDocumentSnapshot(profileRef);

  const profileDoc = useMemo(() => profileSnap?.data(), [profileSnap]);

  if (profileDoc) {
    return (
      <PictureAvatar
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...avatarProps}
        icon={<Icon as={LuUser} />}
        loading="lazy"
        onClick={handleClick}
        pictureRef={profileDoc.pictureRefs[0]}
      />
    );
  }

  return (
    <Avatar
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...avatarProps}
      icon={<Icon as={LuUser} />}
      onClick={handleClick}
      src={pictureUrl}
    />
  );
}

export type Props = {
  telegramUserRef: DocumentReference<TelegramUserDoc>;
} & AvatarProps;

export default function Profile({ telegramUserRef, ...avatarProps }: Props) {
  const { snap: telegramUserSnap } = useDocumentSnapshot(telegramUserRef);

  if (!telegramUserSnap?.exists()) {
    return null;
  }

  return (
    <Catch fallback={null}>
      <Suspense fallback={null}>
        <ProfileMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...avatarProps}
          telegramUserSnap={telegramUserSnap}
        />
      </Suspense>
    </Catch>
  );
}
