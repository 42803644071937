import { Chat, ChatMember } from '@grammyjs/types';
import {
  collection,
  CollectionReference,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { useMemo } from 'react';

import { useFirestore } from '../../components/FirestoreProvider';

export enum TelegramChatStatus {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
}

export type TelegramChatDoc = {
  _v: 1;
  createdAt: Timestamp;
  lastMessageAt: Timestamp;
  myChatMember?: ChatMember;
  pictureStorageUri?: string;
  status: TelegramChatStatus;
  telegramChat?: Chat;
  unreadMessagesCount: number;
  updatedAt: Timestamp;
};

export const isTelegramChatDoc = (
  doc?: DocumentData,
): doc is TelegramChatDoc => true;

export const isTelegramChatRef = (
  ref: DocumentReference<DocumentData>,
): ref is DocumentReference<TelegramChatDoc> => ref.parent.id === 'telegramChats';

function isTelegramChatSnap(
  snap: QueryDocumentSnapshot
): snap is QueryDocumentSnapshot<TelegramChatDoc>;
function isTelegramChatSnap(
  snap: DocumentSnapshot,
): snap is DocumentSnapshot<TelegramChatDoc>;
function isTelegramChatSnap(
  snap: DocumentSnapshot | QueryDocumentSnapshot,
): snap is DocumentSnapshot<TelegramChatDoc> | QueryDocumentSnapshot<TelegramChatDoc> {
  return isTelegramChatRef(snap.ref);
}

export { isTelegramChatSnap };

export const getTelegramChatsCollectionRef = (firestore: Firestore): CollectionReference<TelegramChatDoc> => collection(firestore, 'telegramChats') as CollectionReference<TelegramChatDoc>;

export const useTelegramChatsCollectionRef = () => {
  const firestore = useFirestore();
  return useMemo(() => getTelegramChatsCollectionRef(firestore), [firestore]);
};
