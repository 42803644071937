import {
  AspectRatio,
  Card,
  Center,
  CircularProgress,
  Icon,
  IconButton,
  Image,
  Input,
} from '@chakra-ui/react';
import { doc, onSnapshot } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { useField } from 'formik';
import mixpanel from 'mixpanel-browser';
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import { LuImagePlus, LuTrash } from 'react-icons/lu';

import { usePicturesCollectionRef } from '../../common/collections/Picture';
import getCroppedImage from '../../common/getCroppedImage';
import loadImage from '../../common/loadImage';
import useShowError from '../../hooks/useShowError';
import PictureImage from '../PictureImage';
import { useMyProfileRef } from '../refProviders/MyProfileRefProvider';
import { useStorage } from '../StorageProvider';

interface Props {
  name: string;
}

export default function PictureInput({ name }: Props) {
  const myProfileRef = useMyProfileRef();

  const showError = useShowError();

  const [input, , helper] = useField<string | undefined>(name);

  const inputFile = useRef<HTMLInputElement>(null);

  const storage = useStorage();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [imageDataUri, setImageDataUri] = useState<string | undefined>(undefined);

  const picturesCollectionRef = usePicturesCollectionRef();

  const handleImageChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files?.length) {
        return;
      }

      const file: File = e.target.files[0];

      setIsProcessing(true);

      const imageData = URL.createObjectURL(file);
      setImageDataUri(imageData);

      const originalImage = await loadImage(imageData);
      const { blob: data } = await getCroppedImage(originalImage);

      const pictureRef = doc(picturesCollectionRef);
      const dest = ref(storage, `assets/${myProfileRef.id}/${pictureRef.id}.webp`);

      await uploadBytes(dest, data, { contentType: 'image/webp' });

      const stop = onSnapshot(pictureRef, (snap) => {
        if (snap.exists()) {
          stop();
          helper
            .setValue(pictureRef.id)
            .then(() => {
              mixpanel.track('Picture Uploaded');
              setIsProcessing(false);
            })
            .catch(showError);
        }
      });
    },
    [helper, myProfileRef.id, picturesCollectionRef, showError, storage],
  );

  const handleUploadButtonClick = useCallback(() => {
    if (inputFile.current === null) {
      return;
    }

    inputFile.current.click();
  }, [inputFile]);

  const handleRemoveButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      helper.setValue(undefined).catch(showError);
      e.stopPropagation();
    },
    [helper, showError],
  );

  return (
    <AspectRatio ratio={9 / 16} w="100%">
      <Card
        cursor="pointer"
        h={320}
        onClick={handleUploadButtonClick}
        overflow="hidden"
        position="relative"
        w={180}
      >
        {isProcessing ? (
          <>
            {imageDataUri ? (
              <Image
                alt=""
                h="100%"
                objectFit="cover"
                position="absolute"
                src={imageDataUri}
                w="100%"
              />
            ) : null}

            <Center
              h="100%"
              position="absolute"
              w="100%"
              zIndex={10}
            >
              <CircularProgress isIndeterminate />
            </Center>
          </>
        ) : null}

        {!isProcessing && input.value ? (
          <>
            <PictureImage
              h="100%"
              objectFit="cover"
              pictureRef={doc(picturesCollectionRef, input.value)}
              w="100%"
            />

            <IconButton
              aria-label="Remove"
              bottom={3}
              icon={<Icon as={LuTrash} />}
              onClick={handleRemoveButtonClick}
              position="absolute"
              right={3}
              zIndex={10}
            />
          </>
        ) : null}

        {!isProcessing && !input.value ? (
          <Center h="100%">
            <Icon as={LuImagePlus} boxSize={6} />
          </Center>
        ) : null}

        <Input
          accept="image/*"
          id="file"
          onChange={(v) => { handleImageChange(v).catch(showError); }}
          ref={inputFile}
          style={{ display: 'none' }}
          type="file"
        />
      </Card>
    </AspectRatio>
  );
}
