import {
  AspectRatio,
  Box,
  Center,
  Container,
  Grid,
  Text,
} from '@chakra-ui/react';
import {
  limit,
  orderBy,
  query,
  where,
} from 'firebase/firestore';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { AllowanceStatus, useAllowancesCollectionRef } from '../../common/collections/Allowances';
import Catch from '../../components/Catch';
import useQuerySnapshot from '../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../ErrorFallbackScreen';
import Allowance, { AllowanceLoading } from './Allowance';

export function AllowanceGridLoading() {
  return (
    <Grid gap={4} gridAutoRows="1fr" h="100%" templateColumns="repeat(2, 1fr)">
      <AllowanceLoading />
      <AllowanceLoading />
      <AllowanceLoading />
      <AllowanceLoading />
    </Grid>
  );
}

export function AllowanceGridMain() {
  const { t } = useTranslation('TheirAllowancesScreen', { keyPrefix: 'AllowanceList' });

  const allowancesCollectionRef = useAllowancesCollectionRef();
  const { snap: allowancesSnap } = useQuerySnapshot(
    query(
      allowancesCollectionRef,
      where('status', '==', AllowanceStatus.PUBLISHED),
      orderBy('createdAt', 'asc'),
      limit(100),
    ),
  );

  if (!allowancesSnap) {
    return (
      <AllowanceGridLoading />
    );
  }

  if (allowancesSnap.docs.length) {
    return (
      <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
        {allowancesSnap.docs.map((allowanceSnap) => (
          <AspectRatio
            key={allowanceSnap.id}
            ratio={9 / 16}
          >
            <Allowance
              allowanceSnap={allowanceSnap}
              scrollSnapAlign="center"
            />
          </AspectRatio>
        ))}

        {new Array(Math.ceil(allowancesSnap.docs.length / 2) * 2 - allowancesSnap.docs.length)
          .fill(null)
          .map((_, i) => (
            <Box
              _dark={{
                backgroundColor: 'rgba(255, 255, 255, 0.05)',
              }}
              _light={{
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              }}
              borderRadius="md"
              // eslint-disable-next-line react/no-array-index-key
              key={i}
            />
          ))}
      </Grid>
    );
  }

  return (
    <Container height="100%" maxW="lg">
      <Center height="100%">
        <Text textAlign="center">
          {t('emptyList.body')}
        </Text>
      </Center>
    </Container>
  );
}

export default function AllowanceGrid() {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<AllowanceGridLoading />}>
        <AllowanceGridMain />
      </Suspense>
    </Catch>
  );
}
