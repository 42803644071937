import {
  AspectRatio,
  Box,
  Button,
  Center,
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import {
  doc,
  DocumentReference,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';
import { Suspense, useCallback } from 'react';

import {
  ApplicationDoc,
  ApplicationStatus,
  useApplicationsCollectionRef,
} from '../../../common/collections/Applications';
import { ProfileDoc } from '../../../common/collections/Profiles';
import {
  isTripRef,
  TripDoc,
  TripStatus,
  useTripsCollectionRef,
} from '../../../common/collections/Trips';
import { isVentureRef } from '../../../common/collections/Ventures';
import Catch from '../../../components/Catch';
import LogoIcon from '../../../components/LogoIcon';
import useQuerySnapshot from '../../../hooks/useQuerySnapshot';
import ErrorFallbackScreen from '../../../screens/ErrorFallbackScreen';
import TripItem from '../TelegramChat/Trip';
import Trip from './Trip';
import Venture from './Venture';

export type Props = {
  applicationsSnap: QuerySnapshot<ApplicationDoc>;
  profileRef: DocumentReference<ProfileDoc>;
};

export function ApplicationsMain({ applicationsSnap, profileRef }: Props) {
  const { isOpen: isTripsOpen, onClose: onTripsClose, onOpen: onTripsOpen } = useDisclosure();

  const tripsCollectionRef = useTripsCollectionRef();
  const { snap: tripsSnap } = useQuerySnapshot(
    query(
      tripsCollectionRef,
      where('status', '==', TripStatus.PUBLISHED),
    ),
  );

  const applicationsCollectionRef = useApplicationsCollectionRef();
  const handleTripClick = useCallback(
    async (tripSnap: QueryDocumentSnapshot<TripDoc>) => {
      const tripDoc = tripSnap.data();

      await setDoc(
        doc(applicationsCollectionRef),
        {
          _v: 1,
          applicantRef: profileRef,
          organizerRef: tripDoc.organizerRef,
          sentAt: Timestamp.now(),
          status: ApplicationStatus.SENT,
          subjectRef: tripSnap.ref,
        },
      );
    },
    [applicationsCollectionRef, profileRef],
  );

  return (
    <>
      <VStack alignItems="stretch">
        <Box overflow="auto" p={2}>
          <Grid autoRows="1fr" gap={2} templateColumns="repeat(3, 1fr)">
            {applicationsSnap.docs.map((applicationSnap) => {
              const applicationDoc = applicationSnap.data();

              if (isTripRef(applicationDoc.subjectRef)) {
                return (
                  <AspectRatio
                    key={applicationSnap.id}
                    ratio={9 / 16}
                  >
                    <Trip tripRef={applicationDoc.subjectRef} />
                  </AspectRatio>
                );
              }

              if (isVentureRef(applicationDoc.subjectRef)) {
                return (
                  <AspectRatio
                    key={applicationSnap.id}
                    ratio={9 / 16}
                  >
                    <Venture ventureRef={applicationDoc.subjectRef} />
                  </AspectRatio>
                );
              }

              return null;
            })}
          </Grid>
        </Box>

        <Button onClick={onTripsOpen}>
          Add Trip
        </Button>
      </VStack>

      <Modal isOpen={isTripsOpen} onClose={onTripsClose} scrollBehavior="inside">
        <ModalOverlay />

        <ModalContent
          mx={4}
        >
          <ModalCloseButton />

          <ModalHeader>
            Pick Trip
          </ModalHeader>

          <ModalBody>
            <Grid autoRows="1fr" gap={2} templateColumns="repeat(2, 1fr)">
              {(tripsSnap?.docs ?? []).map((tripSnap) => (
                <AspectRatio
                  key={tripSnap.id}
                  ratio={9 / 16}
                >
                  <TripItem
                    onClick={handleTripClick}
                    tripSnap={tripSnap}
                  />
                </AspectRatio>
              ))}
            </Grid>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onTripsClose} variant="ghost">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default function Applications(props: Props) {
  return (
    <Catch fallback={<ErrorFallbackScreen />}>
      <Suspense fallback={<Center h="100%"><LogoIcon boxSize={16} /></Center>}>
        <ApplicationsMain
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </Suspense>
    </Catch>
  );
}
